import * as React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  makeStyles,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import sizeof from "object-sizeof";
import { logSizeInKilobytes } from "../shared/helpers/getSizeInKB";
import { OperationVariables, QueryResult } from "@apollo/client";
import { Skeleton } from "@mui/material";
import { transactionTypeEnum } from "../shared/enum";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& a": {
      color: "#3498db",
    },
  },
}));

function createData(
  txId: string,
  type: string,
  timestamp: string,
  hash: string,
  size: string
) {
  return { txId, type, timestamp, hash, size };
}

const rows = [
  createData(
    "23a09a4",
    "Issue Notes",
    "2021-09-21 06:31:47",
    "00000000000000000ed96b4221348006821dbb3aaf46e819ec039083a23a09a4",
    "7.74 MB"
  ),
  createData(
    "7c1bbdf",
    "Emergency Issue",
    "2021-09-21 06:28:50",
    "00000000000000000b0279e991410242dd2106a3c134ecd3f357b5f1e7c1bbdf",
    "50.74 MB"
  ),
  createData(
    "0fe2a4c",
    "Gate Pass",
    "2021-09-21 06:20:50",
    "00000000000000000d522e0f0bbda8cf159e3eb0bf7ddd1849f760fa80fe2a4c",
    "10.74 MB"
  ),
];

type Props = {
  getEverythingResp: QueryResult<any, OperationVariables>;
};

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any, comparator: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

export default function MainTableDetail(props: any) {
  const classes = useStyles();
  const { getEverythingResp } = props;
  // const data = getEverythingResp;
  const skeletonArray = Array(5).fill("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const history = useHistory();
  React.useEffect(() => {
    if (getEverythingResp === undefined || getEverythingResp.length === 0) {
      history.push("/");
    }
  }, [getEverythingResp]);
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      {/* <Typography variant="h6" style={{ padding: "1% 0 1% 0" }}>{getEverythingResp[0].type}</Typography> */}

      <TableContainer className={classes.root} component={Paper}>
        <Table style={{ minWidth: 650 }} aria-label="simple table">
          {/* <TableHead>
          <TableRow>
            <TableCell>Latest Transactions</TableCell>
          </TableRow>
        </TableHead> */}
          <TableHead>
            <TableRow>
              <TableCell>Tx Id</TableCell>
              <TableCell>Tx Type</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Invoice ID</TableCell>
              <TableCell>Email / Mobile</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getEverythingResp, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => (
                <TableRow key={index}>
                  <TableCell size="small" component="td" scope="row">
                    {/* <Tooltip title={row.TxnID} arrow> */}
                    <Link
                      to={`/transaction/${
                        row.type === transactionTypeEnum.InvoiceFinance
                          ? row.financeId
                          : row.mongoId
                      }/${row.type}/${row.invoiceId}`}
                    >
                      {row.type === transactionTypeEnum.InvoiceFinance
                        ? row.financeId
                        : row.mongoId}
                    </Link>
                    {/* </Tooltip> */}
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  {/* <TableCell>{new Date(parseInt(row.TimeStamp)).toDateString()}</TableCell> */}
                  <TableCell>
                    {moment(row.timeStamp).format("DD/MM/YYYY, h:mm:ss A")}
                  </TableCell>
                  <TableCell>{row.invoiceId}</TableCell>
                  <TableCell>
                    {row.vendorEmail}
                    {row.vendorMobileNumber}
                    {row.email}
                  </TableCell>
                  <TableCell>{row.txnHash}</TableCell>
                  <TableCell>{logSizeInKilobytes(row)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={getEverythingResp.length}
          // count={2}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
