import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { logSizeInKilobytes } from "../shared/helpers/getSizeInKB";
import QRCode from "react-qr-code";
import { Skeleton } from "@mui/material";
import moment from "moment";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: "0px",
    "& MuiBox-root": {
      padding: "0px",
    },
  },
  grid: {
    padding: theme.spacing(3),
  },
  qrCodeGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  qrCode: {
    marginRight: -theme.spacing(2),
  },
}));

type Props = {
  data: any;
  type: string;
};

const TransactionSummary = (props: Props) => {
  const { data, type } = props;
  const [invoiceState, setInvoiceState] = useState({
    TxnID: "",
    type: "",
    txnHash: "",
    TxnHash: "",
    Cost: "",
    creationDate: "",
    netAmt: "",
    currency: {
      code: "",
    },
    cost: "",
    NetAmt: "",
    CreationDate: "",
    Currency: {
      code: "",
    },
  });
  const [financeState, setFinanceState] = useState({
    TxnID: "",
    FinanceAmount: "",
    TimeStamp: "",
    type: "",
    txnHash: "",
    TxnHash: "",
    Cost: "",
    creationDate: "",
    netAmt: "",
    currency: {
      code: "",
    },
    cost: "",
    NetAmt: "",
    CreationDate: "",
    Currency: {
      code: "",
    },
  });
  const [paymentState, setPaymentState] = useState({
    TxnID: "",
    type: "",
    TxnHash: "",
    txnHash: "",
    date: "",
    paidAmount: "",
    Cost: "",
    currencyCode: "",
    cost: "",
    NetAmtFC: "",
    CreationDate: "",
    TimeStamp: "",
    CurrencyCode: "",
  });
  const [isStateSet, setIsStateSet] = useState(false);
  const classes = useStyles();
  const lableList = [
    "Transaction Id",
    "Type",
    "Timestamp",
    "Hash",
    "Size",
    "Cost",
  ];

  useEffect(() => {
    debugger;
    if (data) {
      if (type == "Invoice") {
        setInvoiceState(data);
      } else if (type == "InvoicePayment") {
        setPaymentState(data);
      } else {
        setFinanceState(data);
      }
      setIsStateSet(true);
    }
  }, [data]);
  console.log(paymentState, invoiceState, financeState);
  return (
    <>
      {isStateSet ? (
        <Paper className={classes.root} elevation={2}>
          <Grid container justify="center" className={classes.grid}>
            <Grid direction="row" item xs={12}>
              <h2>Summary</h2>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={9}>
                <List>
                  {/* <Grid container>
                                            <Grid item xs={3}>
                                                <ListItemText>
                                                    <b>Transaction Id</b>
                                                </ListItemText>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <ListItemText>
                                                    <Tooltip title={invoiceState?.TxnID} arrow>
                                                        <Typography style={{ wordWrap: "break-word" }}>
                                                            {invoiceState?.TxnID}
                                                        </Typography>
                                                    </Tooltip>
                                                </ListItemText>
                                            </Grid>
                                        </Grid> */}
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <ListItemText>
                        <b>Type</b>
                      </ListItemText>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <ListItemText>
                        <Typography>{type}</Typography>
                      </ListItemText>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <ListItemText>
                        <b>Timestamp</b>
                      </ListItemText>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <ListItemText>
                        <Typography>
                          {type == "Invoice" && invoiceState?.CreationDate
                            ? moment(invoiceState?.CreationDate).format(
                                "DD/MM/YYYY, h:mm:ss"
                              )
                            : ""}
                          {type == "InvoicePayment" && paymentState?.TimeStamp
                            ? moment(paymentState?.TimeStamp).format(
                                "DD/MM/YYYY, h:mm:ss"
                              )
                            : ""}
                          {type == "Finance" && financeState?.TimeStamp
                            ? moment(financeState?.TimeStamp).format(
                                "DD/MM/YYYY, h:mm:ss"
                              )
                            : ""}
                        </Typography>
                      </ListItemText>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <ListItemText>
                        <b>Hash</b>
                      </ListItemText>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <ListItemText>
                        {type == "Invoice" && (
                          <Tooltip title={invoiceState?.TxnHash} arrow>
                            <Typography style={{ wordWrap: "break-word" }}>
                              {invoiceState?.TxnHash}
                            </Typography>
                          </Tooltip>
                        )}
                        {type == "InvoicePayment" && (
                          <Tooltip title={paymentState?.TxnHash} arrow>
                            <Typography style={{ wordWrap: "break-word" }}>
                              {paymentState?.TxnHash}
                            </Typography>
                          </Tooltip>
                        )}
                        {type == "Finance" && (
                          <Tooltip title={financeState?.TxnHash} arrow>
                            <Typography style={{ wordWrap: "break-word" }}>
                              {financeState?.TxnHash}
                            </Typography>
                          </Tooltip>
                        )}
                      </ListItemText>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <ListItemText>
                        <b>Size</b>
                      </ListItemText>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <ListItemText>
                        <Typography>
                          {type == "Finance" &&
                            logSizeInKilobytes(financeState)}
                          {type == "Invoice" &&
                            logSizeInKilobytes(invoiceState)}
                          {type == "InvoicePayment" &&
                            logSizeInKilobytes(paymentState)}
                        </Typography>
                      </ListItemText>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <ListItemText>
                        <b>Amount</b>
                      </ListItemText>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <ListItemText>
                        <Typography>
                          <NumberFormat
                            defaultValue={"0.00"}
                            value={
                              type == "InvoicePayment"
                                ? paymentState?.NetAmtFC
                                : type == "Invoice"
                                ? invoiceState?.NetAmt
                                : financeState?.FinanceAmount
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            // prefix={type == "InvoicePayment" ? paymentState?.currencyCode + " " : invoiceState?.currency?.code + " "}
                            prefix={`${
                              type == "InvoicePayment"
                                ? paymentState?.CurrencyCode
                                : type == "Invoice"
                                ? invoiceState?.Currency?.code
                                : financeState?.Currency
                            } `} // <=== Ather bhai asked me to change
                          />
                        </Typography>
                      </ListItemText>
                    </Grid>
                  </Grid>
                </List>
              </Grid>
              <Grid
                direction="row"
                alignItems="center"
                justifyContent="center"
                className={classes.qrCodeGrid}
                item
                xs={12}
                md={3}
              >
                <QRCode
                  className={classes.qrCode}
                  value={`
                  Amount : ${
                    type == "InvoicePayment"
                      ? paymentState?.NetAmtFC
                      : type == "Invoice"
                      ? invoiceState?.NetAmt
                      : financeState?.FinanceAmount
                  },
                 
                  Hash : ${
                    type == "Invoice"
                      ? invoiceState?.TxnHash
                      : type == "InvoicePayment"
                      ? paymentState?.TxnHash
                      : financeState?.TxnHash
                  },
                 
                  Timestamp : ${
                    type == "Invoice"
                      ? invoiceState?.CreationDate
                      : type == "InvoicePayment"
                      ? paymentState?.TimeStamp
                      : financeState?.TimeStamp
                  },
                 
                  Url: https://explorer.invoicemate.net/?tab=transactions&transId=${
                    type == "Invoice"
                      ? invoiceState?.TxnID
                      : type == "InvoicePayment"
                      ? paymentState?.TxnID
                      : financeState?.TxnID
                  }`}
                  size={250}
                />
                {/* <QRCode className={classes.qrCode} value={`Type: ${type}, Timestamp: ${invoiceState?.creationDate}, Hash: ${invoiceState?.txnHash}, Cost: ${invoiceState?.netAmt}`} size={170} /> */}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Skeleton animation="wave" width="100%" height="300px" />
      )}
    </>
  );
};

export default TransactionSummary;
