import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRefechMethodForKPIs } from "../../redux/actions/TransactionAction";
import { Skeleton } from "@mui/material";
import { useQuery } from "@apollo/client";
import Swal from "sweetalert2";
import { KPI_QUERY } from "../../graphql/query/homePage.query";
import axios from "axios";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const useStyle = makeStyles((theme: any) => ({
  root: {
    [theme.breakpoints.up("xs")]: {},
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.up("xl")]: {},
  },
}));

interface KpiData {
  name: string;
  number: any;
}

const KPI = ({ fullDetail, fullWidth }: any) => {
  const classes = useStyle();
  // const kpi = useQuery(KPI_QUERY, {
  //     onError: (resp: any) => {
  //         Swal.fire({
  //             icon: "error",
  //             title: resp,
  //             showConfirmButton: false,
  //             timer: 1500,
  //         });
  //     }
  // });
  // const { data, loading, error, refetch } = kpi;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<KpiData[]>([]);
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      setLoading(false);
      const { data } = await axios.get<{
        totalTransaction: number;
        transactionSizeInKb: number;
        dailyTransaction: number;
        weeklyTransaction: number;
        monthlyTransaction: number;
        yearTransaction: number;
      }>(`https://hlf.invoicemate.net/search/explorer`);
      let kpi_data: KpiData[] = [
        {
          name: "Total Transaction ",
          number: "#" + " " + data?.totalTransaction,
        },
        {
          name: "Transaction Volume",
          number: data?.transactionSizeInKb + " " + "KB",
        },
        { name: "Transactions Today", number: data?.dailyTransaction },
        { name: "Last 7 Days", number: data?.weeklyTransaction },
        { name: "Last 30 Days", number: data?.monthlyTransaction },
        { name: "This Year", number: data?.yearTransaction },
      ];
      setData(kpi_data);
      console.log(data);
    } catch (error) {
      let kpi_data: KpiData[] = [
        { name: "Total Transaction ", number: "#" + " " + 0 },
        { name: "Transaction Volume", number: 0 + " " + "KB" },
        { name: "Transactions Today", number: 0 },
        { name: "Last 7 Days", number: 0 },
        { name: "Last 30 Days", number: 0 },
        { name: "This Year", number: 0 },
      ];
      setData(kpi_data);
    }
  };

  useEffect(() => {
    getData();
    // data &&
    //     dispatch(setRefechMethodForKPIs(refetch));
  }, []);
  console.log("data", data);
  const kpiData = [
    { name: "Total Transaction ", number: "#" + " " },
    { name: "Transaction Volume", number: " " + "KB" },
    { name: "Transactions Today", number: "" },
    { name: "Last 7 Days", number: "" },
    { name: "Last 30 Days", number: "" },
    { name: "This Year", number: "" },
  ];
  const fullKpiData = [
    { name: "Total Transaction ", number: "#" + " " },
    { name: "Transaction Volume", number: " " + "KB" },
    { name: "Transactions Today", number: " " + "KB" },
    { name: "Last 7 Days", number: " " + "KB" },
    { name: "Last 30 Days", number: " " + "KB" },
    { name: "This Year", number: " " + "KB" },
    { name: "Invoices", number: " " + "KB" },
    { name: "Payments", number: " " + "KB" },
  ];

  return (
    <Grid container spacing={1} className={classes.root}>
      {loading === true
        ? kpiData.map((t) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={fullWidth ? 2 : 4}
              lg={fullWidth ? 2 : 4}
              xl={fullWidth ? 2 : 4}
              alignItems="center"
              style={{
                height: "100%",
              }}
            >
              <Box sx={{ marginLeft: "5px", marginRight: "5px" }}>
                <Skeleton
                  animation="wave"
                  height="150px"
                  style={{ width: "100%", background: "#1d1d1d" }}
                />
              </Box>
            </Grid>
          ))
        : data.map((t) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={fullWidth ? 2 : 4}
              lg={fullWidth ? 2 : 4}
              xl={fullWidth ? 2 : 4}
              alignItems="center"
              style={{
                height: "100%",
              }}
            >
              <Box
                sx={{
                  padding: "2%",
                  minHeight: "120px",
                  margin: "auto",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Paper
                  style={{
                    padding: "5%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  elevation={3}
                >
                  <Typography
                    textAlign="left"
                    variant="h6"
                    sx={{ color: "#989e99" }}
                  >
                    {t.name}
                  </Typography>
                  <Typography textAlign="left" variant="h6">
                    {t.number}
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          ))}
    </Grid>
  );
};
export default KPI;
