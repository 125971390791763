const getSizeInBytes = (obj: any) => {
    let str = null;
    if (typeof obj === 'string') {
        // If obj is a string, then use it
        str = obj;
    } else {
        // Else, make obj into a string
        str = JSON.stringify(obj);
    }
    // Get the length of the Uint8Array
    const bytes = new TextEncoder().encode(str).length;
    return bytes;
};

export function logSizeInKilobytes (obj: any) {
    const bytes = getSizeInBytes(obj);
    const kb = (bytes / 1000).toFixed(2);
    return kb + " kB"
};

export function hashCode(str: any) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = ~~(((hash << 5) - hash) + str.charCodeAt(i));
    }
    return hash;
}