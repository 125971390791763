import { Snackbar, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        // top: theme.spacing(9)
    }
}))

const Notification = (props: any) => {
    const classes = useStyles();
    const { notify, setNotify } = props;
    const handleClose = (event: any, reason: any) => {
        if (reason == "clickaway") {
            return;
        }

        setNotify({
            ...notify,
            isOpen: false
        })
    }

    return (
        <Snackbar
            className={classes.root}
            open={notify.isOpen}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleClose}
        >
            <Alert severity={notify.type} onClose={() => handleClose}>
                {notify.message}
            </Alert>
        </Snackbar>
    );
}

export default Notification;
