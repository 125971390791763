import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { logSizeInKilobytes } from "../shared/helpers/getSizeInKB";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { defaultTheme, darkTheme } from "../shared/theme/theme";
import { IAppState } from "../redux/store/store";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& a": {
      color: "#3498db",
    },
  },
}));
interface User {
  MongoId: any;
  Type: any;
  TimeStamp: any;
  InvoiceId: any;
  VendorMobileNumber: any;
  VendorEmail: any;
  TxnHash: any;
  Email: any;
}

type Props = {
  data: any;
};
const CompleteVendorDetails = (props: Props) => {
  const classes = useStyles();
  const [users, setUsers] = useState<User[]>([]);
  const { data } = props;
  useEffect(() => {
    postData(data);
  }, []);

  const postData = async (data: any) => {
    await axios({
      method: "post",
      url: `https://hlf.invoicemate.net/search/get-invoices-based-vendor-email`,
      data: {
        email: data.VendorEmail || data.Email,
        number: data.VendorMobileNumber || data.MobileNumber,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response: any) => {
        let datasort = response.data;
        datasort.sort((x: any, y: any) => {
          return (
            new Date(y.TimeStamp).getTime() - new Date(x.TimeStamp).getTime()
          );
        });
        setUsers(datasort);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const theme = useSelector((state: IAppState) => state.configState.theme);

  return (
    <ThemeProvider theme={theme === "dark" ? defaultTheme : darkTheme}>
      <TableContainer component={Paper} className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Invoice Id</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Email / Mobile</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 ? (
              users.map((inv) => (
                <TableRow>
                  <TableCell>
                    {" "}
                    <Link
                      to={`/transaction/${inv.MongoId}/${inv.Type}/${inv.InvoiceId}`}
                    >
                      {inv.MongoId}
                    </Link>
                  </TableCell>
                  <TableCell>{inv?.Type}</TableCell>
                  <TableCell>{inv?.InvoiceId}</TableCell>
                  <TableCell>
                    {moment(inv.TimeStamp).format("DD/MM/YYYY, h:mm:ss A")}
                  </TableCell>
                  <TableCell>
                    {inv?.VendorEmail} {inv?.VendorMobileNumber} {inv?.Email}{" "}
                  </TableCell>
                  <TableCell>{inv?.TxnHash.slice(0, 30)} </TableCell>
                  <TableCell>{logSizeInKilobytes(inv)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default CompleteVendorDetails;
