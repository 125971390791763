import gql from "graphql-tag";

export const GET_MONTHLY_STAS = gql`
  mutation getMonthlyStats($month: Int) {
    getMonthlyStats(month: $month) {
      totalTxns
      totalData
      totalCost
      yearlyChart
      yearlyTxns {
        number
        data
        cost
      }
      monthlyTxns {
        number
        data
        cost
      }
      weeklyTxns {
        number
        data
        cost
      }
      dailyTxns {
        number
        data
        cost
      }
      models {
        Invoice {
          number
          data
        }
        InvoicePayment {
          number
          data
        }
        Finance {
          number
          data
        }
      }
    }
  }
`;
