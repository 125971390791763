import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress, colors } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { KPI_CHART2 } from "../../graphql/query/KPI.query copy";
import { IAppState } from "../../redux/store/store";
import {
  setChartData,
  setChartNumber,
} from "../../redux/actions/TransactionAction-other";
import "../Charts/dropdown.style.css";
import { GET_MONTH_STATICS } from "../../graphql/mutation/transactionDetailPage.mutation";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme: any) => ({
  root: {
    "& .MuiNativeSelect-select": {
      // height: "25px",
      padding: "2px 21px !important",
      "&:hover": {
        border: "none !important",
      },
    }
  },
  rootDark: {
    "& .MuiNativeSelect-select": {
      // height: "25px",
      padding: "2px 21px !important",
      "&:hover": {
        border: "none !important",
      },
    },
    "& button": {
      color: "#8ed4ff",
    },
  },
  formControl: {
    "& .MuiSelect-root": {
      padding: "0px",
      border: "none !important",
      marginRight: theme.spacing(1),
    },
    "& .MuiSelect-root:hover": {
      padding: "0px",
      border: "none !important",
    }
  },
  dailySelectLight: {
    // marginTop: theme.spacing(1),
    paddingTop: "2px !important",
    border: "none !important",
    "& select": {
      border: "1px solid #fff !important",
    },
    padding: "1px 10px !important",
  },
  dailySelectDark: {
    // margin: theme.spacing(1),
    paddingTop: "2px !important",
    color: "#0a0a0a !important",
    border: "none !important",
    backgroundColor: "#9d9d9d !important",
    "& select": {
      border: "1px solid #9d9d9d !important",
    },
    padding: "1px 10px !important",
  }
}));
const TransactionsChartMonthWise = () => {
  const configState = useSelector((state: any) => state.configState);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [getMonthStaticsMutation, monthlyStats]: any = useMutation(GET_MONTH_STATICS, {
    onCompleted: (resp) => {

      setData(resp.getMonthlyStats)

    },
    onError: (resp: any) => {
      Swal.fire({
        icon: "error",
        title: resp,
        showConfirmButton: false,
        timer: 1500,
      });
    },
  });

  const [onZoomed, setOnZoomed] = useState({
    min: "",
    max: "",
  });
  //#region useState
  const [data, setData]: any = useState(undefined)


  const [overAllDailyNumberForChart, setOverAllDailyNumberForChart]: any =
    useState([]);

  const [overAllDailyNumber, setOverAllDailyNumber]: any = useState([]);
  const [overAllDailyData, setOverAllDailyData]: any = useState([]);

  const [invoiceDaily, setInvoiceDaily]: any = useState([]);
  const [invoiceDailyData, setInvoiceDailyData]: any = useState([]);
  const [invoicePaymentDaily, setInvoicePaymentDaily]: any = useState([]);
  const [invoicePaymentDailyData, setInvoicePaymentDailyData]: any = useState([]);
  // 

  const [InvoiceFinanceDaily, setInvoiceFinanceDaily]: any = useState([]);
  const [InvoiceFinanceDailyData, setInvoiceFinanceDailyData]: any = useState([]);
  // Hourly.
  const [overAllHourlyNumber, setOverAllHourlyNumber]: any = useState([]);
  const [overAllHourlyData, setOverAllHourlyData]: any = useState([]);

  const [invoiceHourly, setInvoiceHourly]: any = useState([]);
  const [invoiceHourlyData, setInvoiceHourlyData]: any = useState(
    []
  );
  const [invoicePaymentHourly, setInvoicePaymentHourly]: any = useState([]);
  const [invoicePaymentHourlyData, setInvoicePaymentHourlyData]: any = useState(
    []
  );
  const [invoiceFinanceHourly, setInvoiceFinanceHourly]: any = useState([]);
  const [invoiceFinanceHourlyData, setInvoiceFinanceHourlyData]: any = useState([]);

  const [selectedData, setSelectedData]: any = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState("All");
  const [selectedModelWithDropdown, setSelectedModelWithDropdown] =
    useState("");
  //#endregion

  //#region Temprary Arrays
  //Monthly
  let OverAllMonthNumberArr: any = [];
  let OverAllMonthDataArr: any = [];

  let InvoiceMonthArr: any = [];
  let InvoicePaymentMonthArr: any = [];

  let InvoiceMonthDataArr: any = [];
  let InvoicePaymentMonthDataArr: any = [];

  // ***for daily***
  let overAllDailyDataArr: any = [];
  let overAllDailyNumberArr: any = [];

  let InvoiceArrDaily: any = [];
  let InvoicePaymentArrDaily: any = [];
  let InvoiceFinanceDataArrDaily: any = [];

  let InvoiceDataArrDaily: any = [];
  let InvoicePaymentDataArrDaily: any = [];
  let InvoiceFinanceDailyDataArr: any = [];

  // ***for Hourly***
  let overAllHourlyDataArr: any = [];
  let overAllHourlyNumberArr: any = [];

  let InvoiceArrHourly: any = [];
  let InvoicePaymentArrHourly: any = [];
  let InvoiceFinanceArrHourley: any = [];

  let InvoiceDataArrHourly: any = [];
  let InvoicePaymentDataArrHourly: any = [];
  let InvoiceFinanceDailyArrDataHourly: any = [];
  //#endregion

  //#region  Set data in chart series
  useEffect(() => {
    setChartLoading(false);

    if (selectedModel === "All") {
      chartData["series"] = overAllDailyNumber;
      setState(chartData);
    } else if (selectedModel === "Invoice") {

      chartData["series"] = invoiceDaily;
      setState(chartData);
    } else if (selectedModel === "InvoicePayment") {

      chartData["series"] = invoicePaymentDaily;
      setState(chartData);
    } else {
      chartData["series"] = InvoiceFinanceDaily;
      setState(chartData);

    }

  }, [selectedData]);

  //#endregion
  useEffect(() => {
    if (onZoomed.min !== "") {
      const selMin = parseInt(onZoomed.min);
      const selMax = parseInt(onZoomed.max);
      // if (selMin >= 1609441200000) {
      const minTime = converter(selMin, "Daily");
      const maxTime = converter(selMax, "Daily");
      const days = daysCalculator(minTime, maxTime);
      //   const daysInMonth =(month: any) =>{
      //     return new Date(2021, month, 0).getDate();
      // }
      const daysInMonth = new Date(2021, parseInt(selectedMonth), 0).getDate();
      // if (days <= daysInMonth) {
      zoomedHandler(selMin, selMax);
      // }
    }
    // 1632142611436  1609441200000
  }, [onZoomed]);

  useEffect(() => {
    if (overAllDailyNumberForChart.length > 0) {
      chartData["series"] = overAllDailyNumberForChart;
      // chartData["options"] = dailyOption;
      setState(chartData);
    }
  }, [overAllDailyNumberForChart]);

  const remove_hr_from_ts = (ts: any, time: any) => {
    const year = new Date(ts).getFullYear();
    const month = new Date(ts).getMonth();
    const day = new Date(ts).getDate();
    const hour = new Date(ts).getHours();
    if (time === "Hourly") {
      const hourlyTs = new Date(year, month, day, hour, 0, 0).valueOf();
      return hourlyTs;
    } else if (time === "Daily") {
      const dailyTs = new Date(year, month, day, 0, 0, 0).valueOf();
      return dailyTs;
    } else if (time === "Monthly") {
      const dailyTs = new Date(year, month, 1, 0, 0, 0).valueOf();
      return dailyTs;
    }
  };
  const converter = (timestamp: any, time: any) => {
    const newTs = remove_hr_from_ts(timestamp, time);
    return newTs;
  };
  const daysCalculator = (minTime: any, maxTime: any) => {
    var d: any = (maxTime - minTime) / (24 * 3600 * 1000);
    return parseInt(d);
  };

  const dayHandler = (min: any, max: any) => {
    const minTime = converter(min, "Daily");
    const maxTime = converter(max, "Daily");
    let minIndex: any;
    let maxIndex: any;
    let dailyNumber: any;
    let dailyData: any;
    if (selectedModel === "All") {
      dailyNumber = overAllDailyNumber;
      dailyData = overAllDailyData;

      overAllDailyNumber[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Daily");
        if (xTime === minTime) {
          minIndex = index;
          return index;
        }
      });
      overAllDailyNumber[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Daily");
        if (xTime === maxTime) {
          maxIndex = index;
          return index;
        }
      });
    } else if (selectedModel === "Invoice") {
      dailyNumber = invoiceDaily;
      dailyData = invoiceDailyData
      invoiceDaily[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Daily");
        if (xTime === minTime) {
          minIndex = index;
          return index;
        }
      });
      invoiceDaily[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Daily");
        if (xTime === maxTime) {
          maxIndex = index;
          return index;
        }
      });
    } else if (selectedModel === "InvoicePayment") {
      dailyNumber = invoicePaymentDaily;
      dailyData = invoicePaymentDailyData
      invoicePaymentDaily[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Daily");
        if (xTime === minTime) {
          minIndex = index;
          return index;
        }
      });
      invoicePaymentDaily[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Daily");
        if (xTime === maxTime) {
          maxIndex = index;
          return index;
        }
      });
    } else {
      dailyNumber = InvoiceFinanceDaily;
      dailyData = InvoiceFinanceDailyData
      invoicePaymentDaily[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Daily");
        if (xTime === minTime) {
          minIndex = index;
          return index;
        }
      });
      invoicePaymentDaily[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Daily");
        if (xTime === maxTime) {
          maxIndex = index;
          return index;
        }
      });

    }

    if (maxIndex === undefined || minIndex === undefined) {

      const selected: any = [];

      dailyData[0].data.map((x: any) => {
        selected.push(x.y)
      })
      setOverAllDailyNumberForChart([
        {
          // ...overAllDailyNumberForChart,
          name: selectedModel,
          data: dailyNumber[0].data,
        },
      ]);

      setSelectedData(selected);
    } else {
      let chartData: any = [];
      let selected: any = [];
      dailyNumber[0].data.map((x: any, index: any) => {
        if (minIndex <= index && maxIndex >= index) {
          chartData.push(x);
        }
      });

      dailyData[0].data.map((x: any, index: any) => {
        if (minIndex <= index && maxIndex >= index) {
          selected.push(x.y);
        }
      });

      setOverAllDailyNumberForChart([
        {
          // ...overAllDailyNumberForChart,
          name: selectedModel,
          data: chartData,
        },
      ]);

      setSelectedData(selected);
    }
  };
  const hourlyHandler = (min: any, max: any) => {
    const minTime = converter(min, "Hourly");
    const maxTime = converter(max, "Hourly");
    let hourlyNumber: any;
    let hourlyData: any;

    let minIndex: any;
    let maxIndex: any;

    if (selectedModel === "All") {
      hourlyNumber = overAllHourlyNumber;
      hourlyData = overAllHourlyData
      overAllHourlyNumber[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Hourly");
        if (xTime === minTime) {
          minIndex = index;
          return index;
        }
      });
      overAllHourlyNumber[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Hourly");
        if (xTime === maxTime) {
          maxIndex = index;
          return index;
        }
      });
    } else if (selectedModel === "Invoice") {
      hourlyNumber = invoiceHourly;
      hourlyData = invoiceHourlyData;
      invoiceHourly[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Hourly");
        if (xTime === minTime) {
          minIndex = index;
          return index;
        }
      });
      invoiceHourly[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Hourly");
        if (xTime === maxTime) {
          maxIndex = index;
          return index;
        }
      });
    } else if (selectedModel === "InvoicePayment") {
      hourlyNumber = invoicePaymentHourly;
      hourlyData = invoicePaymentHourlyData;
      invoicePaymentHourly[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Hourly");
        if (xTime === minTime) {
          minIndex = index;
          return index;
        }
      });
      invoicePaymentHourly[0].data.find((x: any, index: any) => {
        const xTime = converter(x.x, "Hourly");
        if (xTime === maxTime) {
          maxIndex = index;
          return index;
        }
      });
    }

    let chartData: any = [];
    let selected: any = [];
    hourlyNumber[0].data.map((x: any, index: any) => {
      if (minIndex <= index && maxIndex >= index) {
        chartData.push(x);
      }
    });

    hourlyData[0].data.map((x: any, index: any) => {
      if (minIndex <= index && maxIndex >= index) {
        selected.push(x.y);
      }
    });
    setOverAllDailyNumberForChart([
      {
        // ...overAllDailyNumberForChart,
        name: selectedModel,
        data: chartData,
      },
    ]);
    setSelectedData(selected);
  };

  const zoomedHandler = (min: any, max: any) => {
    const days = daysCalculator(min, max);
    if (days > 2) {
      dayHandler(min, max);
    } else if (days <= 2) {
      hourlyHandler(min, max);
    }
  };

  //#region yearly , month, week wise Filters
  const dispatch = useDispatch();
  let d: any = new Date().getMonth();
  d = (d + 1).toString();
  const [selectedMonth, setMonth] = useState(d);



  useEffect(() => {
    async function fetchData() {
      // You can await here
      const month = parseInt(selectedMonth);
      await getMonthStaticsMutation({
        variables: {
          month
        }
      })
      // ...
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      const month = parseInt(selectedMonth);
      debugger

      let daily = data.yearlyChart[month].dailyData;

      // Filter for Daily.
      // "<<<<===========Days===========>>>>"
      for (let dIndex = 0; dIndex < Object.keys(daily).length; dIndex++) {
        const date: any = Object.keys(daily)[dIndex];
        //-------------------------------For Number--------------------------------------------//
        const OverAllDailyNumber = {
          x: new Date(
            `${new Date().getFullYear()}-${month}-${dIndex + 1}`
          ).getTime(),
          y: daily[date].number,
        };
        const InvoiceDailyNumber = {
          x: new Date(
            `${new Date().getFullYear()}-${month}-${dIndex + 1}`
          ).getTime(),
          y: daily[date].models.Invoice.number,
        };
        const InvoicePaymentDailyNumber = {
          x: new Date(
            `${new Date().getFullYear()}-${month}-${dIndex + 1}`
          ).getTime(),
          y: daily[date].models.InvoicePayment.number,
        };
        const InvoiceFinanceDailyNumber = {
          x: new Date(
            `${new Date().getFullYear()}-${month}-${dIndex + 1}`
          ).getTime(),
          y: daily[date].models.Finance.number,
        };

        overAllDailyNumberArr.push(OverAllDailyNumber);
        InvoiceArrDaily.push(InvoiceDailyNumber);
        InvoicePaymentArrDaily.push(InvoicePaymentDailyNumber);
        InvoiceFinanceDataArrDaily.push(InvoiceFinanceDailyNumber);
        //-----------------------For-Data--------------------------------//
        const OverAllDailyData = {
          x: new Date(
            `${new Date().getFullYear()}-${month}-${dIndex + 1}`
          ).getTime(),
          y: daily[date].data,
        };
        const InvoiceDailyData = {
          x: new Date(
            `${new Date().getFullYear()}-${month}-${dIndex + 1}`
          ).getTime(),
          y: daily[date].models.Invoice.data,
        };
        const InvoicePaymentDailyData = {
          x: new Date(
            `${new Date().getFullYear()}-${month}-${dIndex + 1}`
          ).getTime(),
          y: daily[date].models.InvoicePayment.data,
        };
        const InvoiceFinanceDailyData = {
          x: new Date(
            `${new Date().getFullYear()}-${month}-${dIndex + 1}`
          ).getTime(),
          y: daily[date].models.Finance.data,
        };

        overAllDailyDataArr.push(OverAllDailyData);
        InvoiceDataArrDaily.push(InvoiceDailyData);
        InvoicePaymentDataArrDaily.push(InvoicePaymentDailyData);
        InvoiceFinanceDailyDataArr.push(InvoiceFinanceDailyData);

        for (
          let hIndex = 0;
          hIndex < Object.keys(daily[date].hourly).length;
          hIndex++
        ) {
          const hour: any = Object.keys(daily[date].hourly)[hIndex];
          const dataByHour = daily[date].hourly[hour];

          //all
          //<<<===========For TrxNumber==============>>>
          const OAHNumber = {
            x: new Date(
              `${new Date().getFullYear()}-${month}-${dIndex + 1} ${hIndex + 1
              }:00:00 `
            ).getTime(),
            y: dataByHour.number,
          };

          const OAHData = {
            x: new Date(
              `${new Date().getFullYear()}-${month}-${dIndex + 1} ${hIndex + 1
              }:00:00 `
            ).getTime(),
            y: dataByHour.data,
          };

          const InvoiceNumberHourly = {
            x: new Date(
              `${new Date().getFullYear()}-${month}-${dIndex + 1} ${hIndex + 1
              }:00:00`
            ).getTime(),
            y: dataByHour.models.Invoice.number,
          };
          const InvoicePaymentNumberHourly = {
            x: new Date(
              `${new Date().getFullYear()}-${month}-${dIndex + 1} ${hIndex + 1
              }:00:00`
            ).getTime(),
            y: dataByHour.models.InvoicePayment.number,
          };
          const InvoiceFinanceNumberHourly = {
            x: new Date(
              `${new Date().getFullYear()}-${month}-${dIndex + 1} ${hIndex + 1
              }:00:00`
            ).getTime(),
            y: dataByHour.models.Finance.number,
          };

          overAllHourlyNumberArr.push(OAHNumber);
          overAllHourlyDataArr.push(OAHData);

          InvoiceArrHourly.push(InvoiceNumberHourly);
          InvoicePaymentArrHourly.push(InvoicePaymentNumberHourly);
          InvoiceFinanceArrHourley.push(InvoiceFinanceNumberHourly);

          //<<<===========For Data==================>>>
          const InvoiceDataHourly = {
            x: new Date(
              `${new Date().getFullYear()}-${month}-${dIndex + 1} ${hIndex + 1
              }:00:00`
            ).getTime(),
            y: dataByHour.models.Invoice.data,
          };
          const InvoicePaymentDataHourly = {
            x: new Date(
              `${new Date().getFullYear()}-${month}-${dIndex + 1} ${hIndex + 1
              }:00:00`
            ).getTime(),
            y: dataByHour.models.InvoicePayment.data,
          };
          const InvoiceFinanceDataHourly = {
            x: new Date(
              `${new Date().getFullYear()}-${month}-${dIndex + 1} ${hIndex + 1
              }:00:00`
            ).getTime(),
            y: dataByHour.models.Finance.data,
          };

          InvoiceDataArrHourly.push(InvoiceDataHourly);
          InvoicePaymentDataArrHourly.push(InvoicePaymentDataHourly);
          InvoiceFinanceDailyArrDataHourly.push(InvoiceFinanceDataHourly);
        }
      }
      // set all array in usestate


      // **** setState For Daily *****
      setOverAllDailyNumber([{ name: "All", data: overAllDailyNumberArr }]);
      setOverAllDailyData([{ name: "All", data: overAllDailyDataArr }]);

      setInvoiceDaily([
        { name: "Invoice", data: InvoiceArrDaily },
      ]);
      setInvoicePaymentDaily([
        { name: "Invoice Payment", data: InvoicePaymentArrDaily },
      ]);
      setInvoiceFinanceDaily([
        { name: "Finance", data: InvoiceFinanceDataArrDaily },
      ]);


      // 
      setInvoiceDailyData([{ name: "Invoice", data: InvoiceDataArrDaily }])
      setInvoicePaymentDailyData([{ name: "InvoicePayment", data: InvoicePaymentDataArrDaily }])
      setInvoiceFinanceDailyData([{ name: "Finance", data: InvoiceFinanceDailyDataArr }])

      // **** setState For Hourly *****

      setOverAllHourlyData([{ name: "All", data: overAllHourlyDataArr }]);

      setOverAllHourlyNumber([{ name: "All", data: overAllHourlyNumberArr }]);

      setInvoiceHourly([
        { name: "Invoice", data: InvoiceArrHourly },
      ]);
      setInvoicePaymentHourly([
        { name: "InvoicePayment", data: InvoicePaymentArrHourly },
      ]);
      setInvoiceFinanceHourly([
        { name: "Finance", data: InvoiceFinanceArrHourley },
      ]);

      setInvoiceHourlyData([{ name: "Invoice", data: InvoiceDataArrHourly }]);
      setInvoicePaymentHourlyData([{ name: "InvoicePayment", data: InvoicePaymentDataArrHourly }]);
      setInvoiceFinanceHourlyData([{ name: "Finance", data: InvoiceFinanceDailyArrDataHourly }]);


      if (overAllDailyDataArr) {
        const select = overAllDailyDataArr.map((x: any) => {
          return x.y;
        });
        setSelectedData(select);
      }
      if (
        overAllDailyNumberArr &&
        InvoiceArrHourly &&
        InvoicePaymentArrHourly
      ) {
        dispatch(
          setChartNumber(
            overAllHourlyNumberArr,
            InvoiceArrHourly,
            InvoicePaymentArrHourly,
          )
        );
      }

      if (
        overAllHourlyDataArr &&
        InvoiceDataArrHourly &&
        InvoicePaymentDataArrHourly
      ) {
        dispatch(
          setChartData(
            overAllHourlyDataArr,
            InvoiceDataArrHourly,
            InvoicePaymentDataArrHourly
          )
        );
      }
    }
  }, [data]);
  //#endregion

  // #region  Scheema
  const chartData = {
    series: overAllDailyNumber,
    options: {
      theme: {
        mode: "light",
      },
      chart: {
        animations: {
          enabled: false,
        },
        events: {
          selection: function (chartContext: any, { xaxis, yaxis }: any) {
            // console.log("xaxis", xaxis);
            // console.log("yaxis", yaxis);
          },
          zoomed: function (chartContext: any, { xaxis, yaxis }: any) {
            setOnZoomed({
              ...onZoomed,
              min: xaxis.min,
              max: xaxis.max,
            });
          },
          beforeResetZoom: function (chartContext: any, { xaxis, yaxis }: any) {
            updateData(selectedModel);
          }
        },
        type: "area",
        stacked: false,
        height: 700,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
      },
      plotOptions: {
        area: {
          dataLabels: {
            position: "center",
            orientation: "vertical",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any, opts: any) {

          if (val === 0) {
            return "";
          } else {
            return val
          }
        },
      },
      markers: {
        size: 0,
      },
      // fill: {
      //   opacity: 1,
      //   colors: ["#3b74f7"],
      // },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      legend: {
        labels: {
          colors: configState.theme === "dark" ? "#000" : "#cbcaca",
        },
      },
      yaxis: {
        title: {
          text: "# of Transactions",
          style: {
            color: configState.theme === "dark" ? "#000" : "#cbcaca",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-title",
          },
        },
        labels: {
          style: {
            colors: configState.theme === "dark" ? ["#000"] : ["#cbcaca"],
          },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeFormatter: {
            month: "MMM",
            day: "dd",
          },
          style: {
            colors: configState.theme === "dark" ? "#000" : "#cbcaca",
          },
        },
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
          // 
          const monthIndex = parseInt(selectedMonth);
          const n = parseInt(seriesIndex) + 1;
          const date = dataPointIndex + 1
          // if (series.length > 26) {
          return (
            '<div style="width:200px;">' +
            '<div style="background: #505663;color:black; padding: 5px;">' +
            '<span style="padding: 10px">' +
            months[monthIndex - 1] +
            "</span>" +
            "</div>" +
            '<div style="background:#9ea6b8; color:white">' +

            "<div>" +
            "<span>" +
            `<b>Transaction:  </b>` +
            "</span>" +
            series[seriesIndex][dataPointIndex] +
            "</div>" +
            "<div>" +
            "<span>" +
            `<b>Data:  </b>` +
            "</span>" +
            selectedData[dataPointIndex].toFixed(2) + " " + "KB" +
            "</div>" +
            "</div>" +
            "</div>"
          );
          // }
        },
      },
      // tooltip: {
      //   theme: true,
      //   // shared: true,
      //   y: {
      //     formatter: function (val: any) {
      //       return +val;
      //     },
      //   },
      // },
    },

    // options: {
    //   chart: {
    //     type: "bar",
    //     height: 350,
    //     toolbar: {
    //       show: true,
    //     },
    //     zoom: {
    //       enabled: true,
    //     },
    //   },
    //   // plotOptions: {
    //   //   bar: {
    //   //     dataLabels: {
    //   //       position: "center",
    //   //       orientation: "vertical",

    //   //     },
    //   //   },
    //   // },
    //   // dataLabels: {
    //   //   enabled: true,
    //   //   formatter: function (val: any) {
    //   //     return val + "%";
    //   //   },
    //   //   offsetY: -20,
    //   //   style: {
    //   //     fontSize: '12px',
    //   //     colors: ["#304758"]
    //   //   }
    //   // },
    //   legend: {
    //     show: false,
    //   },
    //   yaxis: [
    //     {
    //       axisBorder: {
    //         show: false,
    //         color: "#008FFB",
    //       },
    //       labels: {
    //         show: true,
    //         style: {
    //           colors: "#b7b9bd",
    //         },
    //       },
    //       title: {
    //         text: "# of Transactions",
    //         style: {
    //           color: undefined,
    //           fontSize: "12px",
    //           fontFamily: "Helvetica, Arial, sans-serif",
    //           fontWeight: 200,
    //         },
    //       },
    //     },
    //   ],
    //   // dataLabels: {
    //   //   enabled: true,
    //   // },
    //   // dataLabels: {
    //   //   enabled: true,
    //   //   enabledOnSeries: true,
    //   //   formatter: (val: any, opt: any) => {
    //   //     if (opt.w.globals.series.length === 4 && val !== 0) {
    //   //       
    //   //       let n = parseInt(opt.seriesIndex);
    //   //       n = n + 1;
    //   //       return "Week" + " " + n;
    //   //     }
    //   //     else {
    //   //       return "";
    //   //     }
    //   //   },
    //   //   offsetX: 0,
    //   //   style: {
    //   //     fontSize: "12px",
    //   //     colors: ["white"],
    //   //   },
    //   // },

    //   xaxis: {
    //     type: "datetime",
    //     labels: {
    //       datetimeFormatter: {
    //         month: "MMM",
    //         // day: 'dd',
    //         // hour:'HH'
    //       },
    //       style: {
    //         colors: configState.theme === "dark" ? "#000" : "#cbcaca"
    //       },
    //     },
    //   },

    //   tooltip: {
    //     custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
    //       const n = parseInt(seriesIndex) + 1;
    //       if (series.length > 26) {
    //         return (
    //           '<div style="width:200px;">' +
    //           '<div style="background: #505663;color:black; padding: 5px;">' +
    //           '<span style="padding: 10px">' +
    //           months[dataPointIndex] +
    //           "</span>" +
    //           "</div>" +
    //           '<div style="background:#9ea6b8; color:white">' +
    //           "<div >" +
    //           "<span>" +
    //           `<b>Day:  </b>` +
    //           "</span>" +
    //           n +
    //           "</div>" +
    //           "<div>" +
    //           "<span>" +
    //           `<b>Transaction:  </b>` +
    //           "</span>" +
    //           // series[seriesIndex][dataPointIndex] +
    //           "</div>" +
    //           "<div>" +
    //           "<span>" +
    //           `<b>Data:  </b>` +
    //           "</span>" +
    //           // selectedData[seriesIndex][dataPointIndex] +
    //           "</div>" +
    //           "</div>" +
    //           "</div>"
    //         );
    //       }
    //     },
    //   },
    //   fill: {
    //     opacity: 1,
    //     colors: ["#3b74f7"],
    //   },
    // },
  };
  //#endregion

  const [state, setState] = useState(chartData);
  // const [model, setModel] = useState("All");

  useEffect(() => {
    chartData["series"] = overAllDailyNumber;
    setState(chartData);
  }, [overAllDailyNumber]);

  useEffect(() => {
    updateData(selectedModel);
  }, [selectedModel]);

  //   useEffect(() => {
  // setChartLoading(false);
  //   }, [chartLoading])
  //==========Methods============
  //#region  Update Data
  const updateData = (model: any) => {
    setChartLoading(true);
    let selected: any = []
    // setSelectedModel(selectedModel);
    //      Invoice
    if (selectedModel === "All" && overAllDailyData.length) {
      overAllDailyData[0].data.map((x: any, index: any) => {
        selected.push(x.y);
      });
      setSelectedData(selected)
    } else if (selectedModel === "Invoice") {
      invoiceDailyData[0].data.map((x: any, index: any) => {
        selected.push(x.y);
      });
      setSelectedData(selected)
    }
    //      InvoicePayment
    if (selectedModel === "All" && overAllDailyData.length) {
      overAllDailyData[0].data.map((x: any, index: any) => {
        selected.push(x.y);
      });
      setSelectedData(selected)
    } else if (selectedModel === "InvoicePayment") {
      invoicePaymentDailyData[0].data.map((x: any, index: any) => {
        selected.push(x.y);
      });
      setSelectedData(selected)
    }
    //      InvoiceFinance
    if (selectedModel === "All" && overAllDailyData.length) {
      overAllDailyData[0].data.map((x: any, index: any) => {
        selected.push(x.y);
      });
      setSelectedData(selected)
    } else if (selectedModel === "Finance") {
      InvoiceFinanceDailyData[0].data.map((x: any, index: any) => {
        selected.push(x.y);
      });
      setSelectedData(selected)
    }

    // setTimeout(() => {
    //     setChartLoading(false);
    // }, 1);
  };
  //#endregion

  const classes = useStyles();
  const theme = useSelector((state: IAppState) => state.configState.theme);


  const changeOption = (option: any) => {
    const month = parseInt(option.target.value);

    getMonthStaticsMutation({
      variables: {
        month
      }
    })
    setMonth(month);
  };
  if (monthlyStats.error) return <div>Error !!! </div>;
  // if (monthlyStats.loading) return <div>loading !!!</div>;
  // console.log("error");
  return (
    <div
      className={theme == "dark" ? classes.root : classes.rootDark}
      id="chart"
    >
      <div className="toolbar">
        <FormControl className={classes.formControl}>
          {/* <InputLabel id="demo-simple-select-label">Search By</InputLabel> */}
          <Select
            native
            className={
              theme == "dark"
                ? classes.dailySelectLight
                : classes.dailySelectDark
            }
            onChange={changeOption}
          >
            <option value="1" selected={selectedMonth === "1" ? true : false}>Jan</option>
            <option value="2" selected={selectedMonth === "2" ? true : false}>Feb</option>
            <option value="3" selected={selectedMonth === "3" ? true : false}>Mar</option>
            <option value="4" selected={selectedMonth === "4" ? true : false}>Apr</option>
            <option value="5" selected={selectedMonth === "5" ? true : false}>May</option>
            <option value="6" selected={selectedMonth === "6" ? true : false}>Jun</option>
            <option value="7" selected={selectedMonth === "7" ? true : false}>Jul</option>
            <option value="8" selected={selectedMonth === "8" ? true : false}>Aug</option>
            <option value="9" selected={selectedMonth === "9" ? true : false}>Sep</option>
            <option value="10" selected={selectedMonth === "10" ? true : false}>Oct</option>
            <option value="11" selected={selectedMonth === "11" ? true : false}>Nov</option>
            <option value="12" selected={selectedMonth === "12" ? true : false}>Dec</option>
          </Select>
        </FormControl>
        <Button
          color="primary"
          variant={selectedModel === "All" ? "contained" : "outlined"}
          size="small"
          onClick={() => setSelectedModel("All")}
        >
          ALL
        </Button>
        &nbsp;
        <Button
          color="primary"
          variant={
            selectedModel === "Invoice" ? "contained" : "outlined"
          }
          size="small"
          onClick={() => setSelectedModel("Invoice")}
        >
          Invoice
        </Button>
        &nbsp;
        <Button
          color="primary"
          variant={
            selectedModel === "InvoicePayment" ? "contained" : "outlined"
          }
          size="small"
          onClick={() => setSelectedModel("InvoicePayment")}
        >
          Invoice Payment
        </Button>
        &nbsp;
        <Button
          color="primary"
          variant={
            selectedModel === "Finance" ? "contained" : "outlined"
          }
          size="small"
          onClick={() => setSelectedModel("Finance")}
        >
          Finance
        </Button>
        &nbsp;
      </div>
      <div>
        {chartLoading || monthlyStats.loading ? (
          <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "350px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <ReactApexChart
            series={state.series}
            type="area"
            height={350}
            options={state.options as object}
          />
        )}
      </div>
    </div>
  );
};
export default TransactionsChartMonthWise;
