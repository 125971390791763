import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import logo from "../Images/MateExplorer.png";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(24),
        height: theme.spacing(4),
    },
    tradeMark: {
        marginTop: theme.spacing(4),
        color: "#b9b9b9"
    },
    dividerBoxVertical: {
        borderLeft: "1px solid #707070",
        height: "170px",
        margin: "auto",
        marginLeft: `${theme.spacing(2)}px !important`,
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
        [theme.breakpoints.up('xl')]: {
            display: 'block',
        },
    },
    dividerBoxHorizontal: {
        borderBottom: "1px solid #707070",
        height: "10px",
        width: "90%",
        margin: "auto",
        marginLeft: `${theme.spacing(2)}px !important`,
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none',
        },
    }
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <footer style={{ marginTop: "32px" }}>
            <Box
                px={{ xs: 2, sm: 2 }}
                pt={{ xs: 4, sm: 4 }}
                pb={{ xs: 2, sm: 2 }}
                bgcolor="#000"
                color="white"
            >
                <Container maxWidth="lg">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={9}>
                            <Grid item xs={12}>
                                <img src={logo} className={classes.logo} />
                            </Grid>
                            <br />
                            <Grid item xs={12}>
                                MateExplorer offers a comprehensive overview of the blockchain ledger. It allows viewing of the transaction in block to verify it. The real-time transaction view allows verification of the transaction details. The powerful AI-enabled analytics provide detailed insight helping conclusive decisions based on trustable real-time accurate data. The complete transaction details are accessible along with the chronological history of the related transactions.
                            </Grid>
                        </Grid>
                        <Grid item className={classes.dividerBoxVertical} sm></Grid>
                        <Grid item className={classes.dividerBoxHorizontal} sm></Grid>
                        <Grid item xs={12} sm={12} md={2}>
                            <Box pb={{ xs: 3, sm: 3 }}><Typography variant="h5" component="div"><b>Links</b></Typography></Box>
                            <Box pb={{ xs: 2, sm: 2 }} sx={{ color: "rgb(215 215 215)", fontSize: "16px" }}>
                                <Link href="/lite/mate-explorer" color="inherit">
                                    Home
                                </Link>
                            </Box>
                            <Box pb={{ xs: 2, sm: 2 }} sx={{ color: "rgb(215 215 215)", fontSize: "16px" }}>
                                <Link href="/lite/mate-explorer/analytics" color="inherit">
                                    Analytics
                                </Link>
                            </Box>
                            <Box pb={{ xs: 2, sm: 2 }} sx={{ color: "rgb(215 215 215)", fontSize: "16px" }}>
                                <Link href="/lite/mate-explorer/transactions" color="inherit">
                                    Transactions
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* <Box textAlign="center" className={classes.tradeMark}> */}
                    {/* MateExplorer &reg; {new Date().getFullYear()} */}
                    {/* </Box> */}
                </Container>
            </Box>
        </footer>
    );
}