import { useMutation } from "@apollo/react-hooks";
import { Box, Grid, makeStyles, MenuItem, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FormControl, InputLabel, Select } from "@mui/material";
import { debug } from "console";
import SearchBar from "material-ui-search-bar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { SearchMutation } from "../graphql/mutation/Search.mutation";
import { setTransaction } from "../redux/actions/TransactionAction";
import { transactionTypeEnum } from "../shared/enum";
import TransactionList from "./transactionList";
import "../CSS/SearchBar.style.css";
import { ClassNames } from "@emotion/react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import moment from "moment";
import { logSizeInKilobytes } from "../shared/helpers/getSizeInKB";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiNativeSelect-select": {
      height: "42px",
      padding: "3.0px 21px !important",
      backgroundColor: "#e0e0e0",
      color: "#636363",
      fontWeight: 600,
      boxShadow:
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      border: "none !important",
      "&:hover": {
        border: "none !important",
      },
    },
  },
  searchIconButton: {
    searchIconButton: "true",
  },
  option: {
    fontWeight: 500,
    color: theme.palette.primary.light,
  },
  hover: {
    "&:hover": {
      border: "1px solid black",
    },
    "&:focus": {
      border: "2px solid blue",
    },
    // backgroundColor:theme.palette.secondary.light
  },
  selectFormControl: {
    maxWidth: "230px",
    // "& option": {
    //     background: theme.palette.background.paper
    // },
    // "& legend": {
    //     color: "##424242"
    // }
  },
  select: {
    width: "100%",
    "&:hover": {
      border: "1px solid black",
    },
    "&:focus": {
      border: "2px solid blue",
    },
  },
  selectDark: {
    "& select": {
      backgroundColor: "#000 !important",
      color: "#999999",
    },
    "& .MuiSvgIcon-root": {
      color: "rgb(175 175 175)",
    },
  },
  labelLight: {
    color: "#636363 !important",
  },
  labelDark: {
    color: "#fff !important",
  },
}));
const SearchComponent = ({ MainPage }: any) => {
  const history = useHistory();
  const [key, setKey] = useState("");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("Invoice");
  const [arr, setArr] = useState<any[]>([]);
  const dispatch = useDispatch();
  const configState = useSelector((state: any) => state.configState);
  const [search, searchResp] = useMutation(SearchMutation, {
    onCompleted: (resp) => {
      debugger;
      setShowLoader(false);
      if (resp.search.length >= 2) {
        console.log("resp.search", resp.search);
        dispatch(setTransaction(resp.search));
        history.push("/transactionList");
      } else {
        let link = "";
        switch (resp.search[0]?.type) {
          case transactionTypeEnum.Invoice:
            link =
              "/transaction/" +
              resp.search[0].mongoId +
              "/" +
              resp.search[0].type +
              "/" +
              resp.search[0].invoiceId;
            break;
          case transactionTypeEnum.Payment:
            link =
              "/transaction/" +
              resp.search[0].mongoId +
              "/" +
              resp.search[0].type +
              "/" +
              resp.search[0].invoiceId;
            break;
          case transactionTypeEnum.InvoiceFinance:
            link =
              "/transaction/" +
              resp.search[0].financeId +
              "/" +
              resp.search[0].type +
              "/" +
              resp.search[0].invoiceId;
            break;

          default:
            history.push("/");
            break;
        }
        history.push(link);
      }
    },
    onError: (resp: any) => {
      Swal.fire({
        icon: "error",
        title: resp,
        showConfirmButton: false,
        timer: 1500,
      });
      setShowLoader(false);
    },
  });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // getData()
  }, []);
  const getData = async () => {
    setShowLoader(true);
    await axios({
      method: "get",
      url: `https://hlf.invoicemate.net/search/everything`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response: any) => {
        setShowLoader(false);
        setArr(response.data);
      })
      .catch((err: any) => {
        setShowLoader(false);
        console.log(err.data);
      });
  };
  const doSomethingWith = () => {
    setShowLoader(true);
    if (type === "InvoiceID" || type === "TxnHash" || type === "TimeStamp") {
      let keyname = key;
      if (type === "InvoiceID") {
        keyname = key.toUpperCase();
      }
      search({
        variables: {
          key: keyname,
          type,
          subType: "",
        },
      });
    } else {
      const subType = type;
      search({
        variables: {
          key,
          type: "Model",
          subType,
        },
      });
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const classes = useStyles();
  const searchHandler = (e: any) => {
    setKey(e);
  };

  const onSelector = (e: any) => {
    setType(e.target.value);
  };
  return (
    <>
      {MainPage && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Grid
            className={classes.root}
            container
            justify="center"
            style={{ padding: "3% 1%", paddingTop: "10px", height: "auto" }}
          >
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                <FormControl
                  fullWidth={true}
                  className={classes.selectFormControl}
                >
                  {configState.theme === "dark" && (
                    <InputLabel id="deFmo-simple-select-label">
                      Search By
                    </InputLabel>
                  )}
                  <Select
                    onChange={onSelector}
                    native
                    label="Grouping"
                    className={
                      configState.theme !== "dark" ? classes.selectDark : ""
                    }
                  >
                    <optgroup label="Model">
                      <option value="Invoice" selected>
                        Invoice
                      </option>
                      <option value="Payment">Invoice Payment</option>
                      <option value="Finance">Finance</option>
                    </optgroup>

                    <option className={classes.option} value="InvoiceID">
                      Invoice ID
                    </option>
                    <option className={classes.option} value="TxnHash">
                      Transaction Hash
                    </option>
                    <option className={classes.option} value="TimeStamp">
                      Time Stamp
                    </option>
                  </Select>
                </FormControl>
                <SearchBar
                  className={classes.select}
                  value={key}
                  onChange={searchHandler}
                  placeholder="Search on Model, Transaction, Time Stamp"
                  onRequestSearch={doSomethingWith}
                  searchIcon={<Search />}
                />
              </Box>
            </Grid>
          </Grid>
          {/* <Grid className={classes.root} container justify="center" style={{ padding: "3% 3%", paddingTop: "50px" }}>
                        <Grid item xs={2} >
                            <FormControl fullWidth={true}>
                                {
                                    configState.theme === "light" &&
                                    <InputLabel id="deFmo-simple-select-label">Search By</InputLabel>
                                }

                                <Select onChange={onSelector} native label="Grouping" style={{ backgroundColor: "" }} className={classes.select}>
                                    <optgroup label="Model">
                                        <option value="IssueNote" >Issue Notes</option>
                                        <option value="IssueNotesReturn" >Issue Notes Return</option>
                                        <option value="EmergencyIssue">Emergency Issue</option>
                                        <option value="GatePass">Gate Pass</option>
                                        <option value="Stock">Stock</option>
                                        <option value="Inventory">Inventory</option>
                                    </optgroup>
                                    <option className={classes.option} value="TxnID" selected>Transaction ID</option>
                                    <option className={classes.option} value="TxnHash">Transaction Hash</option>
                                    <option className={classes.option} value="TimeStamp">Time Stamp</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10}>
                            <SearchBar
                                className={classes.hover}
                                value={key}
                                onChange={searchHandler}
                                placeholder="Search on Model, transaction, Time Stamp"
                                onRequestSearch={doSomethingWith}
                                searchIcon={<Search />}
                            />
                        </Grid>
                    </Grid> */}
        </>
      )}
    </>
  );
};
export default SearchComponent;
