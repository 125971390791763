import React from 'react';
import { Paper } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { IAppState } from "../../redux/store/store";

const KPIsModelsChart = ({ kpi }: any) => {
  const theme = useSelector((state: IAppState) => state.configState.theme)
  const { data, loading, error, refetch } = kpi;
  console.log("🚀 ~ KPIsModelsChart ~ kpi:", kpi)

  if (loading) return <div>Loading ...</div>;
  if (error) return <div>Error ...</div>;
  const Invoice = data.kpi.models.Invoice.number;
  const InvoicePayment = data.kpi.models.InvoicePayment.number;
  const InvoiceFinance = data.kpi.models.Finance.number;

  const chartData: any = {
    series: [
      Invoice,
      InvoicePayment,
      InvoiceFinance
    ],
    options: {
      chart: {
        width: 10,
        type: "pie",
      },

      labels: [
        "Invoice",
        "InvoicePayment",
        "Finance"
      ],
      legend: {
        labels: {
          colors: theme === "dark" ? ["#000"] : ["#cbcaca"],
          useSeriesColors: false
        },
      },
      title: {
        text: "Transaction Distribution",
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: theme === "dark" ? "#000" : "#cbcaca",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <Paper style={{ padding: "5%", height: "100%" }} elevation={3}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
      />
    </Paper>
  );
};
export default KPIsModelsChart;
