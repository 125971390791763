import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import { red, lightBlue, yellow, green } from "@material-ui/core/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
import clsx from "clsx";

const warningColor = yellow[200];

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "inline-block"
  },
  badge: {
    position: "absolute",
    // right: 10,
    // top: 10,
    display: "flex",
    flexBasis: "1 0 100px",
    direction: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  dangerBadge: {
    color: red[500]
  },
  warningBadge: {
    color: warningColor
  },
  infoBadge: {
    color: lightBlue[500]
  },
  successBadge: {
    color: green[500]
  },
  badgeBorder: {
    borderRadius: theme.shape.borderRadius + 15
  },
  dangerBadgeBorder: {
    border: `1px solid ${red[500]}`
  },
  warningBadgeBorder: {
    border: `1px solid ${warningColor}`
  },
  infoBadgeBorder: {
    border: `1px solid ${lightBlue[500]}`
  },
  successBadgeBorder: {
    border: `1px solid ${green[500]}`
  },
  text: {
    marginRight: 5
  },
  circle: {
    margin: 5,
    width: 15,
    height: 15,
    borderRadius: "50%"
  },
  warningCircle: {
    backgroundColor: warningColor,
    boxShadow: `0 0 0 ${fade(warningColor, 0.5)}`,
    animation: `$pulsing-warning 1500ms ${theme.transitions.easing.easeOut
      } infinite`
  },
  dangerCircle: {
    backgroundColor: red[500],
    boxShadow: `0 0 0 ${fade(red[500], 0.5)}`,
    animation: `$pulsing-danger 1500ms ${theme.transitions.easing.easeOut
      } infinite`
  },
  infoCircle: {
    backgroundColor: lightBlue[500],
    boxShadow: `0 0 0 ${fade(lightBlue[500], 0.5)}`,
    animation: `$pulsing-info 1500ms ${theme.transitions.easing.easeOut
      } infinite`
  },
  successCircle: {
    backgroundColor: green[500],
    boxShadow: `0 0 0 ${fade(green[500], 0.5)}`,
    animation: `$pulsing-success 1500ms ${theme.transitions.easing.easeOut
      } infinite`
  },

  "@keyframes pulsing-danger": {
    "0%": {
      boxShadow: `0 0 0 0 ${fade(red[500], 0.5)}`
    },
    "70%": {
      boxShadow: `0 0 0 8px ${fade(red[500], 0.0)}`
    },
    "100%": {
      boxShadow: `0 0 0 0 ${fade(red[500], 0.0)}`
    }
  },
  "@keyframes pulsing-warning": {
    "0%": {
      boxShadow: `0 0 0 0 ${fade(warningColor, 0.5)}`
    },
    "70%": {
      boxShadow: `0 0 0 8px ${fade(warningColor, 0.0)}`
    },
    "100%": {
      boxShadow: `0 0 0 0 ${fade(warningColor, 0.0)}`
    }
  },

  "@keyframes pulsing-info": {
    "0%": {
      boxShadow: `0 0 0 0 ${fade(lightBlue[500], 0.5)}`
    },
    "70%": {
      boxShadow: `0 0 0 8px ${fade(lightBlue[500], 0.0)}`
    },
    "100%": {
      boxShadow: `0 0 0 0 ${fade(lightBlue[500], 0.0)}`
    }
  },
  "@keyframes pulsing-success": {
    "0%": {
      boxShadow: `0 0 0 0 ${fade(green[500], 0.5)}`
    },
    "70%": {
      boxShadow: `0 0 0 8px ${fade(green[500], 0.0)}`
    },
    "100%": {
      boxShadow: `0 0 0 0 ${fade(green[500], 0.0)}`
    }
  }
}));

const PulsingDot = ({
  withBorder = false,
  badgeLabel = "",
  variant = "info"
}) => {
  const classes = useStyles();

  return (
    <span className={classes.container}>
      <div
        className={clsx(classes.badge, classes[variant + "Badge"], {
          [classes[variant + "BadgeBorder"]]: withBorder,
          [classes.badgeBorder]: withBorder
        })}
      >
        <div className={clsx(classes.circle, classes[variant + "Circle"])} />
        {/* <Typography
          variant="caption"
          className={clsx({ [classes.text]: badgeLabel.length > 0 })}
        >
          {badgeLabel}
        </Typography> */}
      </div>
      {/* {children} */}
    </span>
  );
};

export default PulsingDot;
