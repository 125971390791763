import {
  SET_REFECH_METHOD,
  SET_TRANSACTION,
  SET_TRANSACTION_DATA,
  SET_TRANSACTION_NUMBER,
} from "../types";

export const setTransaction = (transaction: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_TRANSACTION,
      payload: transaction,
    });
  };
};

export const setRefechMethodForKPIs = (refetch: () => {}) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_REFECH_METHOD,
      payload: refetch,
    });
  };
};

export const setChartNumber = (
  overAllDailyNumberArr: any,
  InvoiceArr: any,
  InvoicePaymentArr: any,
  OverAllMonthNumberArr: any,

  InvoiceMonthArr: any,
  InvoicePaymentMonthArr: any,

  OverAllWeekNumber: any,

  InvoiceWeeky: any,
  InvoicePaymentWeeky: any,
) => {
  const overAllDailyNum = overAllDailyNumberArr.map((x: any) => {
    return x.data;
  });
  const InvoiceNum = InvoiceArr.map((x: any) => {
    return x.data;
  });
  const InvoicePaymentNum = InvoicePaymentArr.map((x: any) => {
    return x.data;
  });

  const OverAllWeekNum = OverAllWeekNumber.map((x: any) => {
    return x.data;
  });
  const InvoiceWeeklyNum = InvoiceWeeky.map((x: any) => {
    return x.data;
  });
  const InvoicePaymentWeeklyNum = InvoicePaymentWeeky.map((x: any) => {
    return x.data;
  });

  const OverAllMonthNumber = OverAllMonthNumberArr;
  const InvoiceMonthNum = InvoiceMonthArr;
  const InvoicePaymentMonthNum = InvoicePaymentMonthArr;

  const Number = {
    Invoice: [
      InvoiceMonthNum,
      InvoiceWeeklyNum,
      InvoiceNum,
    ],
    InvoicePayment: [
      InvoicePaymentMonthNum,
      InvoicePaymentWeeklyNum,
      InvoicePaymentNum,
    ],
    All: [OverAllMonthNumber, OverAllWeekNum, overAllDailyNum],
  };
  
  return (dispatch: any) => {
    dispatch({
      type: SET_TRANSACTION_NUMBER,
      payload: Number,
    });
  };
};

export const setChartData = (
  overAllDailyDataArr: any,
  InvoiceDataArr: any,
  InvoicePaymentDataArr: any,
  OverAllMonthDataArr: any,
  InvoiceMonthDataArr: any,
  InvoicePaymentMonthDataArr: any,
  OverAllWeekData: any,
  InvoiceWeekyData: any,
  InvoicePaymentWeekyData: any,
) => {
  const overAllMonthData = OverAllMonthDataArr;
  const invoicePaymentMonthData = InvoicePaymentMonthDataArr;
  const invoiceMonthData = InvoiceMonthDataArr;

  const overAllDailyData = overAllDailyDataArr.map((x: any) => {
    return x.data;
  });
  const invoiceData = InvoiceDataArr.map((x: any) => {
    return x.data;
  });
  const invoicePaymentData = InvoicePaymentDataArr.map((x: any) => {
    return x.data;
  });
  
  const overAllWeeklyData = OverAllWeekData.map((x: any) => {
    return x.data;
  });
  const invoiceWeeklyData = InvoiceWeekyData.map((x: any) => {
    return x.data;
  });
  const invoicePaymentWeeklyData = InvoicePaymentWeekyData.map((x: any) => {
    return x.data;
  });

  const Data = {
    InvoiceIssue: [
      invoiceMonthData,
      invoiceWeeklyData,
      invoiceData,
    ],
    InvoicePaymentIssue: [
      invoicePaymentMonthData,
      invoicePaymentWeeklyData,
      invoicePaymentData,
    ],
    All: [overAllMonthData, overAllWeeklyData, overAllDailyData],
  };
  return (dispatch: any) => {
    dispatch({
      type: SET_TRANSACTION_DATA,
      payload: Data,
    });
  };
};
