import { ActionType } from "../../shared/globalTypes";
import { SET_TRANSACTION_DATA, SET_TRANSACTION_NUMBER } from "../types";

export interface IChartState {
  Number: {};
  Data: {}
}

const initialState: IChartState = {
  Number: {},
  Data: {},
};

export const chartReducer = (state = initialState, action: ActionType) => {
  console.log("action.payload: ", action.payload);

  switch (action.type) {
    case SET_TRANSACTION_NUMBER: {
      ;
      return {
        ...state,
        Number: action.payload,
      };
    }
    case SET_TRANSACTION_DATA:
      return {
        ...state,
        Data: action.payload,
      };
    default:
      return state;
  }
};
