import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import { transactionTypeEnum } from "../shared/enum";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  invoiceLableList,
  invoiceFieldList,
} from "../shared/config/TransactionDetailDataConfig";
import crypto from "crypto";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { getRandom } from "../shared/helpers/getRandom";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: "0px",
    "& MuiBox-root": {
      padding: "0px",
    },
  },
  grid: {
    padding: "20px",
  },
  visibilityIcon: {
    cursor: "pointer",
  },
  tab: {
    borderBottom: `solid 2px ${theme.palette.secondary.light}`,
  },
  linesRoot: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type Props = {
  data: any;
  type: string;
  isVisible: boolean;
};

const TransactionDetailedData = (props: Props) => {
  const { data, type, isVisible } = props;
  console.log("data: ", data);
  const [state, setState] = useState({
    clientEmail: "",
    clientName: "",
    creationDate: "",
    currency: {
      _id: "",
      type: "",
      code: "",
      symbol: "",
    },
    draft: "",
    fundReception: {
      acName: "",
      acNumber: "",
      bankName: "",
      type: "",
    },
    invoiceId: "",
    lines: [],
    RemainingAmount: [{ Amount: "", CurrencyCode: "" }],
    netAmt: "",
    paid: "",
    rejected: "",
    txnHash: "",
    type: "",
    vendorEmail: "",
    vendorName: "",
    voided: "",
  });
  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setState(data);
    }
  }, [data]);

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <Tab
            label="Transaction Data"
            selected={true}
            className={classes.tab}
          />
        </Grid>
      </Grid>

      <Paper className={classes.root} elevation={2}>
        <Grid container justify="center" className={classes.grid}>
          {type === transactionTypeEnum.Invoice && (
            <InvoiceData state={state} isVisible={isVisible} />
          )}
          {type === transactionTypeEnum.Payment && (
            <InvoicePaymentData state={state} isVisible={isVisible} />
          )}
          {type === transactionTypeEnum.InvoiceFinance && (
            <InvoiceFinanceData state={state} isVisible={isVisible} />
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default TransactionDetailedData;

type InvoiceDataProps = {
  state: any;
  isVisible: any;
};

function InvoiceData({ state, isVisible }: InvoiceDataProps) {
  useEffect(() => {
    console.log("state: ", state);
  }, []);
  return (
    <>
      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Vendor Details</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.VendorEmail}
              </Typography>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.VendorName}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Client Details</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.ClientEmail}
              </Typography>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.ClientName}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Currency</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.Currency?.code}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Draft</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.Draft ? "Yes" : "No"}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Paid</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.Paid ? "Yes" : "No"}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Rejected</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.Rejected ? "Yes" : "No"}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Voided</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.Voided ? "Yes" : "No"}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Payment Instrument</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.FundReception?.type}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Net Amount</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <NumberFormat
                defaultValue={"0.00"}
                value={state?.NetAmt}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={state?.Currency?.code + " "}
              />
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Payee Account</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.FundReception?.bankName}
              </Typography>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.FundReception?.acNumber}
              </Typography>
              <Typography style={{ wordWrap: "break-word" }}>
                {state?.FundReception?.acName}
              </Typography>
            </>
          ) : (
            <>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
              <Typography style={{ wordWrap: "break-word" }}>
                {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
              </Typography>
            </>
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <List>
          <ListItemText>
            <b>Lines Details</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={8}
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        {isVisible ? (
          <BlockLinesAccordion state={state?.Lines} />
        ) : (
          <Typography style={{ wordWrap: "break-word" }}>
            {crypto.randomBytes(getRandom(8, 14)).toString("hex")}
          </Typography>
        )}
      </Grid>
    </>
  );
}

type InvoicePaymentDataProps = {
  state: any;
  isVisible: any;
};
type InvoiceFinanceDataProps = {
  state: any;
  isVisible: any;
};

function InvoiceFinanceData({ state, isVisible }: InvoiceFinanceDataProps) {
  return (
    <>
      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>RequestBy</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.RequestBy}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Email / Mobile Number</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.Email || state?.MobileNumber}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Finance Cost</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              <NumberFormat
                defaultValue={"0.00"}
                value={state?.FinanceAmount}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={state?.Currency + " "}
              />
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Financing Score</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {typeof state?.FinancingScore === "number"
                ? state?.FinancingScore
                : state?.FinancingScore?.totalCreditScore}
              {/* {state?.FinancingScore?.totalCreditScore} */}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Remaining Amount</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              <NumberFormat
                defaultValue={"0.00"}
                // value={""}
                value={
                  state?.RemainingAmount?.length > 0
                    ? state?.RemainingAmount[0].Amount
                    : ""
                }
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={state?.Currency}
              />
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Credit Limit</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              <NumberFormat
                defaultValue={"0.00"}
                value={state?.CreditLimit}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={state?.Currency}
              />
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Bank For Financing</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.BankForFinancing?.bankName}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Invoice Amount</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <NumberFormat
              defaultValue={"0.00"}
              value={state?.InvoiceAmount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={state?.Currency}
            />
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Request Duration</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {`${state?.RequestedDuration} Weeks`}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>
    </>
  );
}

//
function InvoicePaymentData({ state, isVisible }: InvoicePaymentDataProps) {
  console.log({ state });
  return (
    <>
      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Payment ID</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.PaymentId}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Invoice ID</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.InvoiceId}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Currency Type</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.PaymentGateway}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Payment Instrument</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.PaymentType}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Vendor Id</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.VendorId}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Receipt Url</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.ReceiptUrl == "none" ? "" : state?.ReceiptUrl}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Currency</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <Typography style={{ wordWrap: "break-word" }}>
              {state?.CurrencyCode}
            </Typography>
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <ListItemText>
          <b>Paid Amount</b>
        </ListItemText>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ListItemText>
          {isVisible ? (
            <NumberFormat
              defaultValue={"0.00"}
              value={state.PaidAmount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={state.CurrencyCode + " "}
            />
          ) : (
            crypto.randomBytes(getRandom(8, 14)).toString("hex")
          )}
        </ListItemText>
      </Grid>
    </>
  );
}

function BlockLinesAccordion({ state }: any) {
  const classes = useStyles();

  return (
    <>
      {state?.map((line: any, index: any) => (
        <div className={classes.linesRoot}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{line.item}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <b>Unit Price</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <Typography noWrap>
                        <NumberFormat
                          defaultValue={"0.00"}
                          value={line.unitPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </Typography>
                    </ListItemText>
                  </List>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <b>Quantity</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <Typography noWrap>{line.quantity}</Typography>
                    </ListItemText>
                  </List>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <b>Discount</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <Typography noWrap>
                        <NumberFormat
                          defaultValue={"0.00"}
                          value={line.discount}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </Typography>
                    </ListItemText>
                  </List>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <b>Amount</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <Typography noWrap>
                        <NumberFormat
                          defaultValue={"0.00"}
                          value={line.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      </Typography>
                    </ListItemText>
                  </List>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <List>
                    <ListItemText>
                      <b>Additional Details</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <List>
                    <ListItemText>
                      <Typography>{line.additionalDetails}</Typography>
                    </ListItemText>
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </>
  );
}
