import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { invoiceJoinedList } from "../../shared/config/TransactionDetailDataConfig";
import { transactionTypeEnum } from "../../shared/enum";
import ValidationTile from "./ValidationTile";
import {
  faCheckCircle,
  faTimesCircle,
  faExchangeAlt,
  faDiceD6,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidationChildInvoice from "./ValidationChildInvoice";
import ValidationChildInvoicePayment from "./ValidationChildInvoicePayment";
import moment from "moment";
import ValidationChildFinance from "./ValidationChildFinance";

type Props = {
  data: any;
  isVisible: boolean;
  type: string;
};

const useStyles = makeStyles((theme: any) => ({
  "@keyframes rotateFull": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  root: {
    "& .monaco-editor": {
      paddingTop: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    textAlign: "center",
  },
  validationIconFaExchange: {
    backgroundColor: "#bdbdbd",
  },
  validationIcon: {
    width: "30px",
    height: "30px",
    margin: "auto",
    borderRadius: "16px",
  },
  altExchage: {
    fontSize: "1.25rem",
    margin: "auto",
    height: "100%",
    color: "#413f3f",
    animation: "1.2s ease-out 0s 1 rotateFull",
  },
  vertical: {
    borderRight: "1px solid #cdc8c8",
    height: "30px",
    position: "relative",
    width: "50%",
    marginTop: "2px",
  },
  blur: {
    "-webkit-filter": "blur(3px)",
    "-moz-filter": "blur(3px)",
    "-o-filter": "blur(3px)",
    "-ms-filter": "blur(3px)",
    filter: "blur(3px)",
    width: "100%",
    height: "100%",
    backgroundColor: "#424242",
    pointerEvents: "none",
  },
}));

const Validation = (props: Props) => {
  const { data, isVisible, type } = props;
  const [leftInvoiceState, setLeftInvoiceState] = useState({
    clientEmail: "",
    clientName: "",
    creationDate: "",
    currency: {
      code: "",
      symbol: "",
      type: "",
      _id: "",
    },
    draft: "",
    fundReception: {
      acName: "",
      acNumber: "",
      bankName: "",
      type: "",
    },
    invoiceId: "",
    lines: [],
    netAmt: "",
    paid: "",
    rejected: "",
    txnHash: "",
    type: "",
    vendorEmail: "",
    vendorName: "",
    voided: "",
  });
  const [rightInvoiceSideDataState, setRightInvoiceSideDataState] = useState({
    ack: "",
    clientEmail: "",
    clientName: "",
    creationDate: "",
    currency: {
      code: "",
      symbol: "",
      type: "",
      _id: "",
    },
    draft: "",
    fundReception: {
      acName: "",
      acNumber: "",
      bankName: "",
      type: "",
    },
    invoiceId: "",
    lines: [],
    netAmt: "",
    paid: "",
    rejected: "",
    txnHash: "",
    type: "",
    vendorEmail: "",
    vendorName: "",
    voided: "",
  });
  const [leftInvoicePaymentState, setLeftInvoicePaymentState] = useState({
    invoiceId: "",
    paymentID: "",
    vendorId: "",
    currencyCode: "",
    paidAmount: "",
    paymentType: "",
    receiptUrl: "",
    date: "",
    paymentGateway: "",
  });
  const [
    rightInvoicePaymentSideDataState,
    setRightInvoicePaymentSideDataState,
  ] = useState({
    invoiceId: "",
    paymentID: "",
    vendorId: "",
    currencyCode: "",
    paidAmount: "",
    paymentType: "",
    receiptUrl: "",
    date: "",
    paymentGateway: "",
  });

  //
  const [leftInvoiceFinanceState, setLeftInvoiceFinanceState] = useState({
    invoiceId: "",
    paymentID: "",
    vendorId: "",
    currencyCode: "",
    paidAmount: "",
    paymentType: "",
    receiptUrl: "",
    date: "",
    paymentGateway: "",
  });
  const [
    rightInvoiceFinanceSideDataState,
    setRightInvoiceFinanceSideDataState,
  ] = useState({
    invoiceId: "",
    paymentID: "",
    vendorId: "",
    currencyCode: "",
    paidAmount: "",
    paymentType: "",
    receiptUrl: "",
    date: "",
    paymentGateway: "",
  });
  useEffect(() => {
    if (data) {
      let temp = data;
      delete temp.__typename;
      debugger;
      if (type == "Invoice") {
        setLeftInvoiceState(temp);
      } else if (type == "InvoicePayment") {
        setLeftInvoicePaymentState(temp);
      } else {
        setLeftInvoiceFinanceState(temp);
      }
      prepareRightData(temp.MongoId);
    }
  }, [data]);

  const prepareRightData = (recId: any) => {
    let url = "https://app.invoicemate.net/lite";
    // let url = "";
    let invocieApiUrl = `${url}/api/invoices/get/`;
    let paymentApiUrl = `${url}/api/payments/getPayment/`;
    let financeApiUrl = `${url}/api/invoices/get/`;
    let token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDEwMmJiYjUwZTEyODgyNTNmMzE1NTEiLCJtb2JpbGVOdW1iZXIiOiIrOTIzMTE3MDk5MTI3IiwibW9iaWxlTnVtYmVySGFzaCI6ImMxODExYmE5MmIwM2M3ZmFiMDk4MTU1NzYzMGI2YjAzZjdkMzgyMGI4NjhkNDk0ZTM0NDVmZjU2MGVjYWRlODUiLCJlbWFpbCI6ImVzc2Fyb29tYW42MjNAZ21haWwuY29tIiwiZW1haWxIYXNoIjoiODYyMmNkNDFmMDhlNjY1YjQ5MmY0ZWRiNzlkOTU5ZmU5OTVlMWVlNWFiZDVjN2VhOTQ5NDk0NmYxZDQxNDk1NCIsIm5hbWUiOiJlc3NhIHJvb21hbiIsImlzQWRtaW4iOmZhbHNlLCJpZCI6IjY0MTAyYmJiNTBlMTI4ODI1M2YzMTU1MSIsInVzZXJuYW1lIjoiZXNzYSIsImlzTG9naW4iOmZhbHNlLCJzdGF0dXMiOiJBY3RpdmUiLCJsb2dpblR5cGUiOiJtb2JpbGVOdW1iZXIiLCJjdXJyZW5jeUNvZGUiOiJQS1IiLCJzdXBlckFkbWluIjpmYWxzZSwicGVybWlzc2lvbnMiOnsibWF0ZUV4cGxvcmVyIjpmYWxzZSwiZmluYW5jZSI6ZmFsc2UsInN1YnNjcmliZXIiOmZhbHNlLCJkYXNoYm9hcmQiOmZhbHNlLCJjb25maWd1cmF0aW9ucyI6ZmFsc2UsImVtYWlsQ29uZmlndXJhdGlvbnMiOmZhbHNlLCJyZWZlcnJhbENvbmZpZ3VyYXRpb25zIjpmYWxzZSwibWVzc2FnZUNvbmZpZ3VyYXRpb25zIjpmYWxzZSwiY291bnRyaWVzQ29uZmlndXJhdGlvbnMiOmZhbHNlLCJjdXJyZW5jeUNvbmZpZ3VyYXRpb25zIjpmYWxzZSwicGF5bWVudENoYW5uZWxzQ29uZmlndXJhdGlvbnMiOmZhbHNlLCJhcHBWZXJzaW9uQ29uZmlndXJhdGlvbnMiOmZhbHNlLCJiYW5rQ29uZmlndXJhdGlvbnMiOmZhbHNlLCJibG9ja0NoYWluQ29uZmlndXJhdGlvbnMiOmZhbHNlLCJwYXltZW50cyI6ZmFsc2UsIm1lc3NhZ2VCcm9hZENhc3QiOmZhbHNlLCJzdXBwb3J0IjpmYWxzZSwiYWRtaW4iOmZhbHNlLCJsb2dzIjpmYWxzZSwiZXJyb3JMb2dzIjpmYWxzZSwiZmluYW5jaW5nUmVxIjpmYWxzZSwiY3JlZGl0U2NvcmVDcml0ZXJpYSI6ZmFsc2UsImZpbmFuY2luZ0xpbWl0IjpmYWxzZX0sImFkZGl0aW9uYWxDb250YWN0VmVyaWZpZWQiOnRydWUsImV4cGlyZXkiOiIyMDIzLTA5LTI2VDEzOjIxOjQ4LjQ4OVoiLCJpYXQiOjE2OTU2NDgxMDh9.ZArkNqgYiYXLRqQYVW90OPNHseD9Ic2Ij9r1-ArRFK0";
    let rightSideData: any = {};
    switch (type) {
      case transactionTypeEnum.Invoice:
        url = invocieApiUrl + recId;
        rightSideData = {
          clientEmail: "",
          clientName: "",
          creationDate: "",
          currency: {},
          draft: "",
          fundReception: {},
          invoiceId: "",
          lines: [],
          netAmt: "",
          paid: "",
          rejected: "",
          txnHash: "",
          type: "",
          vendorEmail: "",
          vendorName: "",
          voided: "",
          ack: "",
        };
        fetch(url, {
          headers: {
            authorization: token,
          },
        })
          .then((data) => data.json())
          .then((res: any) => {
            setRightInvoiceSideDataState(res.invoice);
          })
          .catch((err) => console.log(err));
        break;
      case transactionTypeEnum.Payment:
        url = paymentApiUrl + recId;

        rightSideData = {
          invoiceId: "",
          paymentID: "",
          vendorId: "",
          currencyCode: "",
          paidAmount: "",
          paymentType: "",
          receiptUrl: "",
          date: "",
          paymentGateway: "",
        };

        fetch(url, {
          headers: {
            authorization: token,
          },
        })
          .then((data) => data.json())
          .then((res: any) => {
            setRightInvoicePaymentSideDataState(res[0]);
          })
          .catch((err) => console.log(err));
        break;
      case transactionTypeEnum.InvoiceFinance:
        url = financeApiUrl + recId;

        rightSideData = {
          invoiceId: "",
          paymentID: "",
          vendorId: "",
          currencyCode: "",
          paidAmount: "",
          paymentType: "",
          receiptUrl: "",
          date: "",
          paymentGateway: "",
        };

        fetch(url, {
          headers: {
            authorization: token,
          },
        })
          .then((data) => data.json())
          .then((res: any) => {
            setRightInvoiceFinanceSideDataState(res.invoice);
          })
          .catch((err) => console.log(err));
        break;
      default:
        return "";
    }
  };

  return (
    <>
      {type == "Invoice" ? (
        <ValidationChildInvoice
          leftData={leftInvoiceState}
          rightSideData={rightInvoiceSideDataState}
          type={type}
          isVisible={isVisible}
        />
      ) : type == "InvoicePayment" ? (
        <ValidationChildInvoicePayment
          leftData={leftInvoicePaymentState}
          rightSideData={rightInvoicePaymentSideDataState}
          type={type}
          isVisible={isVisible}
        />
      ) : (
        <ValidationChildFinance
          leftData={leftInvoiceFinanceState}
          rightSideData={rightInvoiceFinanceSideDataState}
          type={type}
          isVisible={isVisible}
        />
      )}
    </>
  );
};

export default Validation;
