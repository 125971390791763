import React, { useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import SearchComponent from '../components/SearchBar';
import pageTemplate from '../components/PageHoc';
import { useMutation, useQuery, useSubscription, QueryResult } from '@apollo/client';
import { GET_EVERYTHING, GET_EVERYTHING_WITH_PAGINATION, GET_TOTAL_NO_OF_TXNS, KPI_QUERY, KPIS } from '../graphql/query/homePage.query';
import { NEW_INVOICE_SUBSCRIPTION, NEW_PAYMENT_SUBSCRIPTION } from '../graphql/subscriptions/transactionDetailPage.subscription';
import MainTable from '../components/MainTable';
import Notification from '../components/Notification';
import Swal from 'sweetalert2';
import KPIs from '../components/KPIs';
import KPI from '../features/Card/card';
import { LineChart } from '../features/Chart/Multilinechart';
import PulsingDot from '../components/PulsingDot';
import KPIsModelsChart from '../components/Charts/KPIsModelChart';
import { OperationVariables } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  ringContainer: {
    position: 'relative',
  },
  ringRing: {
    width: '15px',
    height: '15px',
    backgroundColor: '#62bd19',
    borderRadius: '50%',
    position: 'absolute',
    top: '23px',
    left: '23px',
  },
  circle: {
    border: '3px solid #62bd19',
    borderRadius: '30px',
    height: '25px',
    width: '25px',
    position: 'absolute',
    left: '15px',
    top: '15px',
    animation: 'pulsate 1s ease-out',
    animationIterationCount: 'infinite',
    opacity: 0.0,
  },
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(0.1, 0.1)',
      opacity: 0.0,
    },
    '50%': {
      opacity: 1.0,
    },
    '100%': {
      transform: 'scale(1.2, 1.2)',
      opacity: 0.0,
    },
  },
  containerMaxHeight: {
    [theme.breakpoints.up('lg')]: {
      maxHeight: '300px',
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: '300px',
    },
  },
}));

const Home = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [newNotification, setNewNotification] = useState(false);
  const getEverythingResp: any = useQuery(GET_EVERYTHING_WITH_PAGINATION, {
    notifyOnNetworkStatusChange: true,
    variables: {
      offset: offset,
      limit: limit,
    },
    onCompleted: () => {
      if (newNotification) {
        setNewNotification(false);
      }
    },
    onError: (resp) => {
      // Swal.fire({
      //   icon: 'error',
      //   title: resp,
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
    },
  });
  const getTotalNoOfTxnsResp = useQuery(GET_TOTAL_NO_OF_TXNS, {
    onError: (resp) => {
      // Swal.fire({
      //   icon: 'error',
      //   title: resp,
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
    },
  });
  const newInvoiceSubscription = useSubscription(NEW_INVOICE_SUBSCRIPTION, {
    onSubscriptionData: () => {
      getEverythingResp.refetch();
      setNewNotification(true);
      setNotify({
        isOpen: true,
        message: 'New Invoice Added',
        type: 'success',
      });
    },
  });
  const newPaymentSubscription = useSubscription(NEW_PAYMENT_SUBSCRIPTION, {
    onSubscriptionData: () => {
      getEverythingResp.refetch();
      setNewNotification(true);
      setNotify({
        isOpen: true,
        message: 'New Payment Added',
        type: 'success',
      });
    },
  });

  const kpi = useQuery(KPIS, {
    onError: (resp) => {
      // Swal.fire({
      //   icon: 'error',
      //   title: resp,
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
    },
  });

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Grid direction="row" item xs={10}>
          <Typography variant="h6" style={{ padding: '1% 0 1% 0' }}>
            Transaction Analytics
          </Typography>

          <Grid container style={{ marginBottom: '32px' }}>
            <Grid item xs={12}>
              <SearchComponent MainPage={true} />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.containerMaxHeight}>
                <Grid item xs={12} sm={12} md={8} lg={8} style={{ height: '100%', paddingRight: '8px' }}>
                  {kpi && <KPI kpi={kpi} fullDetail={false} fullWidth={false} />}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  {kpi && <KPIsModelsChart kpi={kpi} />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* this is our old component  <KPIs /> */}

          <Box display="flex" flexDirection="row" justifyContent="flex-start" marginBottom="8px">
            <PulsingDot variant="success" withBorder={false} />
            <Typography style={{ marginLeft: '32px' }}>Latest Transactions</Typography>
          </Box>

          <MainTable
            getEverythingResp={getEverythingResp}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            fetchMore={getEverythingResp.fetchMore}
            totalNoOfRec={getTotalNoOfTxnsResp.data?.kpi.totalTxns}
            newNotification={newNotification}
            setNewNotification={setNewNotification}
          />

          {/* <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ paddingTop: "2%" }}>
              <Grid item xs={12} md={12} lg={12}>
                  <Paper elevation={3}>
                      <LineChart />
                  </Paper>
              </Grid>
          </Grid> */}
        </Grid>
        <Notification notify={notify} setNotify={setNotify} />
      </Grid>
    </>
  );
};

export default pageTemplate(Home);
