import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: any) => ({
  linesRoot: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function FinanceHistoryBlockItem(props: any) {
  const { data } = props;

  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Invoice Id</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>{data.InvoiceId}</Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Creation Date</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>
              {moment(data.CreationDate).format("DD/MM/YYYY")}
            </Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Due Date</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>
              {data.FinanceDueDate
                ? moment(data.FinanceDueDate).format("DD/MM/YYYY")
                : "Not Assigned"}
            </Typography>
          </ListItemText>
        </List>
      </Grid>

      {/*  */}
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Finance Rate</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>{data.FinanceRate}</Typography>
          </ListItemText>
        </List>
      </Grid>

      {/*  */}
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Approved Amount</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>
              {data.ApprovedAmount !== "Not Assigned"
                ? data?.Currency + " "
                : ""}{" "}
              {data.ApprovedAmount}
            </Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Disbursed Amount</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>
              {data.DisbursedAmount !== "Not Assigned"
                ? data?.Currency + " "
                : ""}{" "}
              {data.DisbursedAmount}
            </Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Repayment Amount</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>
              {" "}
              {data.RepaymentAmount !== "Not Assigned"
                ? data?.Currency + " "
                : ""}{" "}
              {data.RepaymentAmount}
            </Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Requester Detail</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>{data?.Email}</Typography>
          </ListItemText>
          <ListItemText>
            <Typography noWrap>{data?.MobileNumber}</Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Processing Fee</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          {/* <ListItemText>
            <Typography noWrap>{data?.ProcessingFee?.amount} </Typography>
          </ListItemText> */}
          <ListItemText>
            <Typography noWrap>
              {data?.ProcessingFee.percentage
                ? `${data?.ProcessingFee?.percentage}%`
                : "0%"}
            </Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Financing Score</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>
              <NumberFormat
                defaultValue={"0.00"}
                value={
                  typeof data?.FinancingScore === "number"
                    ? data?.FinancingScore
                    : data?.FinancingScore?.totalCreditScore
                }
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                // prefix={data?.Currency?.code + " "}
              />
            </Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <b>Currency</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <List>
          <ListItemText>
            <Typography noWrap>{data?.Currency}</Typography>
          </ListItemText>
        </List>
      </Grid>
      {/*
      <Grid item xs={12} md={12} lg={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <List>
          <ListItemText>
            <b>Payment Instrument</b>
          </ListItemText>
          <ListItemText>
            <Typography noWrap>{data?.FundReception?.type}</Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <List>
          <ListItemText>
            <b>Payee Account</b>
          </ListItemText>
          <ListItemText>
            <Typography noWrap>{data?.FundReception?.bankName}</Typography>
          </ListItemText>
          <ListItemText>
            <Typography noWrap>{data?.FundReception?.accountName}</Typography>
          </ListItemText>
          <ListItemText>
            <Typography noWrap>{data?.FundReception?.accountNumber}</Typography>
          </ListItemText>
        </List>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <List>
          <ListItemText>
            <b>Lines Details</b>
          </ListItemText>
        </List>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        style={{
          marginTop: "8px",
          marginBottom: "8px",
        }}
      >
        <BlockLinesAccordion state={data.Lines} />
      </Grid> */}

      <Grid item xs={12} md={12} lg={12}>
        <Divider />
      </Grid>
    </>
  );
}

function BlockLinesAccordion({ state }: any) {
  const classes = useStyles();

  return (
    <>
      {state?.map((line: any, index: any) => (
        <div className={classes.linesRoot}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>{line.item}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <b>Unit Price</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <Typography noWrap>
                        {/* <NumberFormat
                          defaultValue={"0.00"}
                          value={line?.unitPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        /> */}
                      </Typography>
                    </ListItemText>
                  </List>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <b>Quantity</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <Typography noWrap>{line.quantity}</Typography>
                    </ListItemText>
                  </List>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <b>Discount</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <Typography noWrap>
                        {/* <NumberFormat
                          defaultValue={"0.00"}
                          value={line?.discount}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        /> */}
                      </Typography>
                    </ListItemText>
                  </List>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <b>Amount</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <List>
                    <ListItemText>
                      <Typography noWrap>
                        {/* <NumberFormat
                          defaultValue={"0.00"}
                          value={line?.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        /> */}
                      </Typography>
                    </ListItemText>
                  </List>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <List>
                    <ListItemText>
                      <b>Additional Details</b>
                    </ListItemText>
                  </List>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <List>
                    <ListItemText>
                      <Typography>{line.additionalDetails}</Typography>
                    </ListItemText>
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </>
  );
}
