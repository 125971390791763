import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@mui/material';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { transactionTypeEnum } from '../shared/enum';
import { stableSort, getComparator } from '../shared/helpers/tableHelpers';
import { Link } from 'react-router-dom';
import SearchBar from "material-ui-search-bar";
import { useSelector } from 'react-redux';
import { IAppState } from "../redux/store/store";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { logSizeInKilobytes, hashCode } from '../shared/helpers/getSizeInKB';

type Props = {
    data: Array<Object>;
    columns: Array<object>;
    isLoading: boolean;
    getTransactionByType: any;
    tableState: any;
    offset: any;
    setOffset: any;
    limit: any;
    setLimit: any;
    dataLength: any;
    settableState: any;
}

const useStyles = makeStyles((theme: any) => ({
    root: {
        "&.MuiTable-root": {
            padding: theme.spacing(1)
        },
        "& a": {
            color: "#3498db"
        }
    },
    tableRoot: {
        "& a": {
            color: "#3498db"
        }
    },
    searchDark: {
        backgroundColor: "#484848"
    }
}))

const headCells = [
    { id: 'ID', label: 'Id' },
    // { id: 'TxnID', label: 'Tx Id' },
    { id: 'Type', label: 'Type' },
    { id: 'TimeStamp', label: 'Timestamp' },
    { id: 'ID', label: 'Invoice Id' },
    { id: 'ID', label: 'Email / Mobile' },
    { id: 'Hash', label: 'Hash' },
    { id: 'Size', label: 'Size' },
];

const TransactionTypeTable = (props: Props) => {
    const {
        data,
        columns,
        isLoading,
        getTransactionByType,
        tableState,
        offset,
        limit,
        setOffset,
        setLimit,
        dataLength,
        settableState
    } = props;
    const [rows, setRows] = useState<Object[]>([]);
    const tablelength = rows.length;
    useEffect(() => {
        if (data.length > 0) {
            setRows(data)
        }
    }, [data])

    const classes = useStyles();
    const config = {
        export: true,
        filtering: true
    };
    const skeletonArray = Array(5).fill('');
    const [order, setOrder] = React.useState<any>('asc');
    const [orderBy, setOrderBy] = React.useState('CreatedDate');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const theme = useSelector((state: IAppState) => state.configState.theme);
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const [searched, setSearched] = React.useState<string>("");
    const requestSearch = (searchedVal: any) => {
        console.log("searchedVal", searchedVal);
        const parsedString =
          typeof searchedVal === 'string' ? searchedVal.toLowerCase() : searchedVal.toString().toLowerCase();
        
        const filteredRows = data.filter((row: any) => {
          const parsedDateStr = moment(row.timeStamp).format('DD/MM/YYYY, h:mm:ss A').toString().toLowerCase();
          const mongoId = row.mongoId ? row.mongoId.toLowerCase() : ''; // Check if mongoId is defined
          const financeId = row.financeId ? row.financeId.toLowerCase() : ''; // Check if mongoId is defined
          const logSize = logSizeInKilobytes(row) ? logSizeInKilobytes(row).toLowerCase() : ''; // Check if logSizeInKilobytes(row) is defined
          const txnHash = row.txnHash ? row.txnHash.toLowerCase() : ''; // Check if txnHash is defined
          const invoiceId = row.invoiceId ? row.invoiceId.toLowerCase() : ''; // Check if invoiceId is defined
          const type = row.type ? row.type.toLowerCase() : ''; // Check if type is defined
          const vendordetail = row.vendorDetail ? row.vendorDetail.toLowerCase() : ''; // Check if vendordetail is defined
      
          return mongoId.includes(parsedString) ||
            logSize.includes(parsedString) ||
            financeId.includes(parsedString) ||
            txnHash.includes(parsedString) ||
            invoiceId.includes(parsedString) ||
            type.includes(parsedString) ||
            parsedDateStr.includes(parsedString) ||
            vendordetail.includes(parsedString);
        });
      
        debugger;
        setRows(filteredRows);
      };
    const cancelSearch = () => {
        setSearched("");
        requestSearch("");
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
        // getTransactionByType({
        //     variables: {
        //         type: tableState.type,
        //         offset: (offset + rowsPerPage) * newPage,
        //         limit: rowsPerPage * (newPage + 1)
        //     }
        // }).then((res: any) => {
        //     settableState({
        //         ...tableState,
        //         data: res.data.getTxByType
        //     })
        // })
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getTransactionByType({
            variables: {
                type: tableState.type,
                offset: (offset + parseInt(event.target.value, 10)) * 0,
                limit: parseInt(event.target.value, 10) * (0 + 1)
            }
        }).then((res: any) => {
            settableState({
                ...tableState,
                data: res.data.getTxByType
            })
        })
    };

    const handleSortRequest = (cellId: any) => {
        const isAsc = orderBy === cellId && order === "asc";
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(cellId)
    }

    const [filterFn, setFilterFn] = useState({ fn: (items: any) => { return items; } })
    const recordsAfterPagingAndSorting = () => {

        return stableSort(filterFn.fn(rows), getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    return (
        <Paper className={classes.root} elevation={0}>
            <Grid container justifyContent="flex-end" style={{ padding: "8px" }}>
                <Grid item>
                    <SearchBar
                        value={searched}
                        onChange={(searchVal) => requestSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                        className={theme === "dark" ? "" : classes.searchDark}
                        style={{
                            maxWidth: "320px"
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer className={classes.root} component={Paper}>
                <Table style={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell sortDirection={orderBy === headCell.id ? order : false}>Id</TableCell>
                            <TableCell>Tx Id</TableCell>
                            <TableCell>Timestamp</TableCell>
                            <TableCell>Size</TableCell> */}

                            {
                                headCells.map(headCell => (
                                    <TableCell key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}>
                                        {/* <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={() => { handleSortRequest(headCell.id) }}>
                                        </TableSortLabel> */}
                                        {headCell.label}
                                    </TableCell>))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isLoading ?
                                skeletonArray.map((row: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                        <TableCell><Skeleton animation="wave" /></TableCell>
                                    </TableRow>
                                ))
                                :
                                rows.length > 0 ?
                                    recordsAfterPagingAndSorting().map((row: any, index: number) => {

                                        return (
                                            <TableRow key={index}>
                                                <TableCell size="small" component="td" scope="row">
                                                    {/* <Tooltip title={row.TxnID} arrow> */}
                                                    <Link to={`/transaction/${row.type === transactionTypeEnum.InvoiceFinance ? row.financeId : row.mongoId}/${row.type}/${row.invoiceId}`} >{row.type === transactionTypeEnum.InvoiceFinance ? row.financeId : row.mongoId}</Link>
                                                    {/* </Tooltip> */}
                                                </TableCell>
                                                <TableCell size="small" component="td" scope="row">
                                                    {/* <Tooltip title={row.TxnID} arrow> */}
                                                    {row.type}
                                                    {/* </Tooltip> */}
                                                </TableCell>
                                                {/* <TableCell size="small" component="td" scope="row">
                                                <Tooltip title={row.TxnID} arrow>
                                                    <Link to={`/transaction/${row.ID}/${row.Type}`} >{row.TxnID}</Link>
                                                </Tooltip>
                                            </TableCell> */}
                                                {/* <TableCell>{moment.unix(row.TimeStamp / 1000).format('MM/DD/YYYY, h:mm:ss')}</TableCell> */}
                                                <TableCell>
                                                    {
                                                       moment(row.timeStamp).format('DD/MM/YYYY, h:mm:ss A')
                                                    }
                                                </TableCell>
                                                <TableCell>{row.invoiceId}</TableCell>
                                                <TableCell>{row.vendorDetail}</TableCell>
                                                <TableCell>{row.txnHash.slice(0, 30)}</TableCell>
                                                <TableCell>{logSizeInKilobytes(row)}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow>
                                        <TableCell align="center" component="td" scope="col" colSpan={4}>
                                            No data
                                        </TableCell>
                                    </TableRow>
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={tablelength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Paper>
    );
}

export default TransactionTypeTable;
