import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Skeleton } from '@mui/material';

const CostKPI = (props: any) => {
    const {data, loading} = props;
    const [kpiData, setKpiData] = useState([
        { name: "Total Cost", value: "" },
        { name: "Cost Today", value: "" },
        { name: "Last 7 Days", value: "" },
        { name: "Last 30 Days", value: "" },
        { name: "This Year", value: "" },
    ]);
    useEffect(() => {
        if (data) {
            setKpiData([
                { name: "Total Cost ($)", value: parseFloat(data.totalCost).toFixed(5) },
                { name: "Cost Today ($)", value: parseFloat(data.costToday).toFixed(5) },
                { name: "Last 7 Days ($)", value: parseFloat(data.lastSevenDays).toFixed(5) },
                { name: "Last 30 Days ($)", value: parseFloat(data.lastMonth).toFixed(5) },
                { name: "This Year ($)", value: parseFloat(data.thisYear).toFixed(5) },
            ])
        }
    }, [data])

    return (
        <Grid container spacing={0}>
            {
                loading ?
                    kpiData.map((t) => (
                        <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                            <Box sx={{ maxWidth: 250, marginLeft: "8px", marginRight: "8px" }}>
                                <Skeleton animation='wave' height='150px' style={{width: "100%"}} />
                            </Box>
                        </Grid>
                    ))
                    :
                kpiData.map((t) => (
                    <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                        <Box sx={{ maxWidth: 250, padding: "2%" }}>
                            <Paper style={{ padding: "5%" }} elevation={3}>
                                <Typography
                                    textAlign='left'
                                    variant='inherit'
                                    sx={{ color: "#989e99" }}
                                >
                                    {t.name}
                                </Typography>
                                <Typography textAlign='left' variant='h6'>
                                    {t.value ? t.value : ""}
                                </Typography>
                            </Paper>
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    );
};
export default CostKPI;
