import * as React from "react";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  makeStyles,
  TablePagination,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import sizeof from "object-sizeof";
import { logSizeInKilobytes, hashCode } from "../shared/helpers/getSizeInKB";
import { OperationVariables, QueryResult } from "@apollo/client";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { transactionTypeEnum } from "../shared/enum";
import moment from "moment";
import { useSelector } from "react-redux";
import { debug } from "console";
import { getComparator, stableSort } from "../shared/helpers/tableHelpers";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    "& a": {
      color: "#3498db",
    },
  },
}));

function createData(
  txId: string,
  type: string,
  timestamp: string,
  hash: string,
  size: string
) {
  return { txId, type, timestamp, hash, size };
}

type TimestampList = Array<{
  index: number;
  showTs: boolean;
}>;

type Props = {
  getEverythingResp: QueryResult<any, OperationVariables>;
  offset: any;
  setOffset: any;
  limit: any;
  setLimit: any;
  fetchMore: any;
  totalNoOfRec: any;
  newNotification: any;
  setNewNotification: any;
};

export default function MainTable(props: Props) {
  const classes = useStyles();
  const {
    getEverythingResp,
    offset,
    setOffset,
    limit,
    setLimit,
    fetchMore,
    totalNoOfRec,
    newNotification,
    setNewNotification,
  } = props;
  const [data, setData] = useState([] as Array<Object>);
  const skeletonArray = Array(5).fill("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const transactions = useSelector((state: any) => state.transactions);
  const [timeStampStateList, setTimeStampStateList] = useState<any>({});
  const [tableState, settableState] = useState({
    title: "Invoice",
    type: transactionTypeEnum.Invoice,
    data: [
      {
        txnID: "3242424",
        type: "Invoice",
        timeStamp: "234242",
        txnHash: "fasfafsafasfdsafasfasf",
        size: "23",
      },
    ],
  });

  interface Invoice {
    MobileNumber: string;
    TxnHash: string;
    Type: string;
    FinanceId: string;
    TimeStamp: string;
    PreviousInvoiceHash: string;
    InvoiceId: string;
    MongoId: string;
    creationDate: string;
    date: string;
    Date: string;
    CreationDate: string;
    vendorDetail: string;
    VendorEmail: string;
    VendorMobileNumber: string;
    Email: string;
    ClientEmail: string;
    ClientMobileNumber: string;
  }

  useEffect(() => {
    if (getEverythingResp.data) {
      let temp = getEverythingResp.data.getEverything;
      setData(temp);
    }
  }, [getEverythingResp]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    fetchMore({
      variables: {
        offset: (offset + rowsPerPage) * newPage,
        limit: rowsPerPage * (newPage + 1),
      },
      updateQuery: (prev: any, resp: any) => {
        setData(resp.fetchMoreResult.getEverything);
      },
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchMore({
      variables: {
        offset: (offset + parseInt(event.target.value, 10)) * 0,
        limit: parseInt(event.target.value, 10) * (0 + 1),
      },
      updateQuery: (prev: any, resp: any) => {
        setData(resp.fetchMoreResult.getEverything);
      },
    });
  };

  const handleTSState = (txnHash: number) => {
    setTimeStampStateList({
      ...timeStampStateList,
      [txnHash]: timeStampStateList[txnHash]
        ? !timeStampStateList[txnHash]
        : true,
    });
  };

  useEffect(() => {
    getTnxs();
  }, []);

  const getTnxs = async () => {
    try {
      setLoading(true);
      const { data: invoiceData } = await axios.get<Invoice[]>(
        "https://hlf.invoicemate.net/invoice/get-all"
      );
      const { data: paymentData } = await axios.get<Invoice[]>(
        "https://hlf.invoicemate.net/payment/get-all"
      );
      const { data: financeDate } = await axios.get<Invoice[]>(
        "https://hlf.invoicemate.net/finance/get-all"
      );
      let datasort = invoiceData.concat(paymentData, financeDate);
      datasort.sort((x, y) => {
        return (
          new Date(y.TimeStamp).getTime() - new Date(x.TimeStamp).getTime()
        );
      });
      let data1 = datasort.slice(0, 6).map((tnx) => {
        return {
          txnID: tnx?.TxnHash || "",
          type: tnx?.Type || "",
          financeId: tnx?.FinanceId || "",
          timeStamp: tnx?.TimeStamp || "",
          txnHash: tnx?.TxnHash || "",
          size: "",
          invoiceId: tnx?.InvoiceId || "",
          creationDate: tnx?.Type === "Invoice" ? tnx?.CreationDate : tnx?.Date,
          mongoId: tnx?.MongoId || "",
          date: tnx?.TimeStamp || "",
          vendorDetail: tnx?.InvoiceId.includes("BIL")
            ? tnx?.ClientEmail ||
              tnx?.ClientMobileNumber ||
              tnx?.Email ||
              tnx?.MobileNumber
            : tnx?.VendorEmail ||
              tnx?.VendorMobileNumber ||
              tnx?.Email ||
              tnx?.MobileNumber,
        };
      });
      debugger;
      settableState({
        ...tableState,
        data: data1,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Error in Getting Transactions",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table style={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Invoice ID</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell>Mobile / Email</TableCell>
            <TableCell>Hash</TableCell>
            <TableCell>Size</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getEverythingResp.loading && !newNotification
            ? skeletonArray.map((row: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))
            : stableSort(tableState.data, getComparator(order, orderBy))
                //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {
                        row.type === transactionTypeEnum.Invoice && (
                          // <Tooltip title={row.TxnID} arrow>
                          <Link
                            to={`/transaction/${row.mongoId}/${row.type}/${row.invoiceId}`}
                          >
                            {row.mongoId}
                          </Link>
                        )
                        // </Tooltip>
                      }
                      {
                        row.type === transactionTypeEnum.Payment && (
                          // <Tooltip title={row.TxnID} arrow>
                          <Link
                            to={`/transaction/${row.mongoId}/${row.type}/${row.invoiceId}`}
                          >
                            {row.mongoId}
                          </Link>
                        )
                        // </Tooltip>
                      }
                      {
                        row.type === transactionTypeEnum.InvoiceFinance && (
                          // <Tooltip title={row.TxnID} arrow>
                          <Link
                            to={`/transaction/${row.financeId}/${row.type}/${row.invoiceId}`}
                          >
                            {row.financeId}
                          </Link>
                        )
                        // </Tooltip>
                      }
                    </TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.invoiceId}</TableCell>
                    <TableCell>
                      {row.type === transactionTypeEnum.Invoice
                        ? moment(row.creationDate).format(
                            "DD/MM/YYYY, h:mm:ss A"
                          )
                        : row.type === transactionTypeEnum.Payment
                        ? moment(row.creationDate).format(
                            "DD/MM/YYYY, h:mm:ss A"
                          )
                        : moment(row.timeStamp).format("DD/MM/YYYY, h:mm:ss A")}
                      {/* <span style={{ cursor: "pointer" }} onClick={() => handleTSState(row.TxnID)}><SwapVertIcon style={{ position: "absolute" }} /></span> */}
                    </TableCell>
                    {/* <TableCell>{row.creationDate}</TableCell> */}
                    <TableCell>{row.vendorDetail || ""}</TableCell>
                    <TableCell>{row.txnHash.slice(0, 40)}</TableCell>
                    <TableCell>{logSizeInKilobytes(row)}</TableCell>
                  </TableRow>
                ))}
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalNoOfRec ? totalNoOfRec : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </TableContainer>
  );
}
