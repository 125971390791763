import React, { useEffect } from "react";
import {
    faDiceD6,
    faExchangeAlt,
    faServer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Grid,
    ListItemText,
    Paper,
    List,
    makeStyles,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core";
import { type } from "os";
import ValidationTile from "./ValidationTile";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme: any) => ({
    "@keyframes rotateFull": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
    root: {
        "& .monaco-editor": {
            paddingTop: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
        textAlign: "center",
    },
    validationIconFaExchange: {
        backgroundColor: "#bdbdbd",
    },
    validationIcon: {
        width: "30px",
        height: "30px",
        margin: "auto",
        borderRadius: "16px",
    },
    altExchage: {
        fontSize: "1.25rem",
        margin: "auto",
        height: "100%",
        color: theme.palette.primary.main,
        animation: "1.2s ease-out 0s 1 rotateFull",
    },
    vertical: {
        borderRight: "1px solid #cdc8c8",
        height: "30px",
        position: "relative",
        width: "50%",
        marginTop: "2px",
    },
    blur: {
        "-webkit-filter": "blur(3px)",
        "-moz-filter": "blur(3px)",
        "-o-filter": "blur(3px)",
        "-ms-filter": "blur(3px)",
        filter: "blur(3px)",
        width: "100%",
        height: "100%",
        backgroundColor: "#424242",
        pointerEvents: "none",
    },
    iconColor: {
        color: theme.palette.primary.main,
    },
    childPaper: {
        textAlign: "center",
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
}));

function ValidationChildInvoicePayment(props: any) {
    const { leftData, rightSideData, loading } = props;

    
    const classes = useStyles();

    return (
        <Paper elevation={1}>
            <Grid container>
                {/* Headers */}
                <Grid container>
                    <Grid item xs={12} sm={12} md={5}>
                        <Paper className={classes.paper} elevation={0}>
                            <ListItemText>
                                <h4>
                                    <FontAwesomeIcon
                                        // className={`${classes.iconColor}`}
                                        size="2x"
                                        icon={faDiceD6}
                                    />
                                </h4>
                                <b>ON-CHAIN</b>
                            </ListItemText>
                        </Paper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        style={{ margin: "auto", textAlign: "center" }}
                    >
                        <div
                            className={`${classes.validationIcon} ${classes.validationIconFaExchange}`}
                        >
                            <FontAwesomeIcon
                                className={`${classes.altExchage}`}
                                size="1x"
                                icon={faExchangeAlt}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <Paper className={classes.paper} elevation={0}>
                            <ListItemText>
                                <h4>
                                    <FontAwesomeIcon
                                        // className={`${classes.iconColor}`}
                                        size="2x"
                                        icon={faServer}
                                    />
                                </h4>
                                <b>OFF-CHAIN</b>
                            </ListItemText>
                        </Paper>
                    </Grid>
                </Grid>
                {/* Loading */}
                {loading ? (
                    <>
                        {[0, 1, 2, 3, 4, 5].map((item: any) => (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={5}
                                    style={{ paddingTop: "16px", paddingBottom: "16px" }}
                                >
                                    <Skeleton variant="rect" width={"100%"} height={100} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={2}
                                    style={{ margin: "auto", textAlign: "center" }}
                                >
                                    <CheckCircleIcon style={{ color: "green" }} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={5}
                                    style={{ paddingTop: "16px", paddingBottom: "16px" }}
                                >
                                    <Skeleton variant="rect" width={"100%"} height={100} />
                                </Grid>
                            </Grid>
                        ))}
                    </>
                ) : (
                    <>
                        {/* Payment Id */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Payment ID"}
                                        value={leftData?.PaymentId}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.paymentId == leftData?.paymentId ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Payment ID"}
                                        value={rightSideData?.paymentID}
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid>

                        {/* Invoice Id */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Invoice ID"}
                                        value={leftData.InvoiceId}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.invoiceId == leftData.InvoiceId ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Invoice ID"}
                                        value={rightSideData?.invoiceId}
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid>

                        {/* Vendor Id */}
                        {/* <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Vendor ID"}
                                        value={leftData.VendorId}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.vendorId == leftData.VendorId ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Vendor ID"}
                                        value={rightSideData?.vendorId}
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid> */}

                        {/* Paid Amount */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <Paper className={classes.childPaper} elevation={6}>
                                    <Typography
                                        style={{ wordWrap: "break-word", fontWeight: 600 }}
                                    >
                                        Paid Amount
                                    </Typography>
                                    <NumberFormat
                                        defaultValue={"0.00"}
                                        value={leftData.PaidAmount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={leftData.CurrencyCode + " "}
                                    />
                                </Paper>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.paidAmount == leftData.PaidAmount ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <Paper className={classes.childPaper} elevation={6}>
                                    <Typography
                                        style={{ wordWrap: "break-word", fontWeight: 600 }}
                                    >
                                        Paid Amount
                                    </Typography>
                                    <NumberFormat
                                        defaultValue={"0.00"}
                                        value={rightSideData?.paidAmount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        prefix={leftData.CurrencyCode + " "}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>

                        {/* Vendor Id */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Vendor ID"}
                                        value={leftData.VendorId}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.vendorId == leftData.VendorId ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Vendor ID"}
                                        value={rightSideData?.vendorId}
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid>

                        {/* Currency Type */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Currency Type"}
                                        value={leftData.PaymentType}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.paymentType == leftData.PaymentType ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Currency Type"}
                                        value={rightSideData?.paymentType}
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid>

                        {/* Payment Instrument */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Payment Instrument"}
                                        value={leftData.PaymentGateway}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.paymentGateway == leftData.PaymentGateway ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Payment Instrument"}
                                        value={rightSideData?.paymentGateway}
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid>

                        {/* Receipt Url */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Receipt Url"}
                                        value={leftData.ReceiptUrl === 'none' ? '': leftData.ReceiptUrl  }
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.receiptUrl == leftData.ReceiptUrl ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Receipt Url"}
                                        value={leftData.ReceiptUrl === 'none' ? '': leftData.ReceiptUrl  }
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid>

                        {/* Currency */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Currency"}
                                        value={leftData.CurrencyCode}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {rightSideData?.currencyCode == leftData.CurrencyCode ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title={"Currency"}
                                        value={rightSideData?.currencyCode}
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid>

                        {/* Due Date */}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title="Date"
                                        value={moment(leftData.Date).format("DD/MM/YYYY")}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                style={{ margin: "auto", textAlign: "center" }}
                            >
                                {moment(rightSideData?.date).format("DD/MM/YYYY") ==
                                    moment(leftData.Date).format("DD/MM/YYYY") ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CancelIcon style={{ color: "red" }} />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <ListItemText>
                                    <ValidationTile
                                        title="Date"
                                        value={moment(rightSideData?.date).format("DD/MM/YYYY")}
                                        showData={true}
                                    />
                                </ListItemText>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </Paper>
    );
}

export default ValidationChildInvoicePayment;

function BlockLinesAccordion({ state }: any) {
    const classes = useStyles();

    return (
        <>
            {state.map((line: any, index: any) => (
                <div>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{line.item}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} md={6} lg={6}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <b>Unit Price</b>
                                        </ListItemText>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <Typography noWrap>
                                                <NumberFormat
                                                    defaultValue={"0.00"}
                                                    value={line.unitPrice}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </Typography>
                                        </ListItemText>
                                    </List>
                                </Grid>

                                <Grid item xs={12} md={6} lg={6}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <b>Quantity</b>
                                        </ListItemText>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <Typography noWrap>{line.quantity}</Typography>
                                        </ListItemText>
                                    </List>
                                </Grid>

                                <Grid item xs={12} md={6} lg={6}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <b>Discount</b>
                                        </ListItemText>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <Typography noWrap>
                                                <NumberFormat
                                                    defaultValue={"0.00"}
                                                    value={line.discount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </Typography>
                                        </ListItemText>
                                    </List>
                                </Grid>

                                <Grid item xs={12} md={6} lg={6}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <b>Amount</b>
                                        </ListItemText>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <Typography noWrap>
                                                <NumberFormat
                                                    defaultValue={"0.00"}
                                                    value={line.amount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </Typography>
                                        </ListItemText>
                                    </List>
                                </Grid>

                                <Grid item xs={12} md={12} lg={12}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <b>Additional Details</b>
                                        </ListItemText>
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <List>
                                        <ListItemText style={{ textAlign: "left" }}>
                                            <Typography>{line.additionalDetails}</Typography>
                                        </ListItemText>
                                    </List>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
            ))}
        </>
    );
}
