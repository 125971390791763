import React, { useEffect } from "react";
import {
  faDiceD6,
  faExchangeAlt,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  ListItemText,
  Paper,
  List,
  makeStyles,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { type } from "os";
import ValidationTile from "./ValidationTile";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme: any) => ({
  "@keyframes rotateFull": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  root: {
    "& .monaco-editor": {
      paddingTop: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    textAlign: "center",
  },
  validationIconFaExchange: {
    backgroundColor: "#bdbdbd",
  },
  validationIcon: {
    width: "30px",
    height: "30px",
    margin: "auto",
    borderRadius: "16px",
  },
  altExchage: {
    fontSize: "1.25rem",
    margin: "auto",
    height: "100%",
    color: theme.palette.primary.main,
    animation: "1.2s ease-out 0s 1 rotateFull",
  },
  vertical: {
    borderRight: "1px solid #cdc8c8",
    height: "30px",
    position: "relative",
    width: "50%",
    marginTop: "2px",
  },
  blur: {
    "-webkit-filter": "blur(3px)",
    "-moz-filter": "blur(3px)",
    "-o-filter": "blur(3px)",
    "-ms-filter": "blur(3px)",
    filter: "blur(3px)",
    width: "100%",
    height: "100%",
    backgroundColor: "#424242",
    pointerEvents: "none",
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
  childPaper: {
    textAlign: "center",
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
}));

function ValidationChildInvoice(props: any) {
  const { leftData, rightSideData, loading } = props;

  useEffect(() => {
    console.log("leftData: ", leftData);
    console.log("rightSideData: ", rightSideData);
    debugger;
  }, [rightSideData, leftData]);
  const classes = useStyles();

  return (
    <Paper elevation={1}>
      <Grid container>
        {/* Headers */}
        <Grid container>
          <Grid item xs={12} sm={12} md={5}>
            <Paper className={classes.paper} elevation={0}>
              <ListItemText>
                <h4>
                  <FontAwesomeIcon
                    // className={`${classes.iconColor}`}
                    size="2x"
                    icon={faDiceD6}
                  />
                </h4>
                <b>ON-CHAIN</b>
              </ListItemText>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{ margin: "auto", textAlign: "center" }}
          >
            <div
              className={`${classes.validationIcon} ${classes.validationIconFaExchange}`}
            >
              <FontAwesomeIcon
                className={`${classes.altExchage}`}
                size="1x"
                icon={faExchangeAlt}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Paper className={classes.paper} elevation={0}>
              <ListItemText>
                <h4>
                  <FontAwesomeIcon
                    // className={`${classes.iconColor}`}
                    size="2x"
                    icon={faServer}
                  />
                </h4>
                <b>OFF-CHAIN</b>
              </ListItemText>
            </Paper>
          </Grid>
        </Grid>
        {/* Loading */}
        {loading ? (
          <>
            {[0, 1, 2, 3, 4, 5].map((item: any) => (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  style={{ paddingTop: "16px", paddingBottom: "16px" }}
                >
                  <Skeleton variant="rect" width={"100%"} height={100} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  style={{ margin: "auto", textAlign: "center" }}
                >
                  <CheckCircleIcon style={{ color: "green" }} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  style={{ paddingTop: "16px", paddingBottom: "16px" }}
                >
                  <Skeleton variant="rect" width={"100%"} height={100} />
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {/* Invoice Id */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title={"Invoice ID"}
                    value={leftData?.InvoiceId}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceId == leftData?.InvoiceId ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title={"Invoice ID"}
                    value={rightSideData?.invoiceId}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Due Date */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Due Date"
                    value={moment(leftData?.DueDate).format("DD/MM/YYYY")}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {moment(rightSideData?.dueDate).format("DD/MM/YYYY") ==
                moment(leftData?.DueDate).format("DD/MM/YYYY") ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Due Date"
                    value={moment(rightSideData?.dueDate).format("DD/MM/YYYY")}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>
            {rightSideData?.vendorEmail && (
              <Grid container>
                <Grid item xs={12} sm={12} md={5}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <ListItemText style={{ textAlign: "center" }}>
                      <Typography
                        style={{ wordWrap: "break-word", fontWeight: 600 }}
                      >
                        Vendor Email
                      </Typography>
                      <Typography style={{ wordWrap: "break-word" }}>
                        {leftData?.VendorEmail}
                      </Typography>
                    </ListItemText>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  style={{ margin: "auto", textAlign: "center" }}
                >
                  {rightSideData?.vendorEmail == leftData?.VendorEmail ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <CancelIcon style={{ color: "red" }} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <ListItemText style={{ textAlign: "center" }}>
                    <Paper className={classes.childPaper} elevation={6}>
                      <Typography
                        style={{ wordWrap: "break-word", fontWeight: 600 }}
                      >
                        Vendor Email
                      </Typography>
                      <Typography style={{ wordWrap: "break-word" }}>
                        {rightSideData?.vendorEmail}
                      </Typography>
                    </Paper>
                  </ListItemText>
                </Grid>
              </Grid>
            )}

            {rightSideData?.vendorMobileNumber && (
              <Grid container>
                <Grid item xs={12} sm={12} md={5}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <ListItemText style={{ textAlign: "center" }}>
                      <Typography
                        style={{ wordWrap: "break-word", fontWeight: 600 }}
                      >
                        Vendor Phone
                      </Typography>
                      <Typography style={{ wordWrap: "break-word" }}>
                        {leftData?.VendorMobileNumber}
                      </Typography>
                    </ListItemText>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  style={{ margin: "auto", textAlign: "center" }}
                >
                  {rightSideData?.vendorMobileNumber ==
                  leftData?.VendorMobileNumber ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <CancelIcon style={{ color: "red" }} />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <ListItemText style={{ textAlign: "center" }}>
                    <Paper className={classes.childPaper} elevation={6}>
                      <Typography
                        style={{ wordWrap: "break-word", fontWeight: 600 }}
                      >
                        Vendor Phone
                      </Typography>
                      <Typography style={{ wordWrap: "break-word" }}>
                        {rightSideData?.vendorMobileNumber}
                      </Typography>
                    </Paper>
                  </ListItemText>
                </Grid>
              </Grid>
            )}

            {/* Vendor Details */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <Paper className={classes.childPaper} elevation={6}>
                  <ListItemText style={{ textAlign: "center" }}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Vendor Name
                    </Typography>

                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.VendorName}
                    </Typography>
                  </ListItemText>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.vendorName == leftData?.VendorName ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Vendor Name
                    </Typography>

                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.vendorName}
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Client Details */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Client Details
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.ClientEmail}
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.ClientMobileNumber}
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.clientEmail == leftData?.ClientEmail &&
                rightSideData?.clientName == leftData?.ClientName ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Client Details
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.clientEmail}
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.clientMobileNumber}
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Draft */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Draft"
                    value={leftData?.Draft ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.draft.toString() ==
                leftData?.Draft?.toString() ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Draft"
                    value={rightSideData?.draft ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Acknowledged */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Acknowledged"
                    value={leftData?.Ack ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.ack.toString() == leftData?.Ack?.toString() ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Acknowledged"
                    value={rightSideData?.ack ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Rejected */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Rejected"
                    value={leftData?.Rejected ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.rejected.toString() ==
                leftData?.Rejected?.toString() ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Rejected"
                    value={rightSideData?.rejected ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Voided */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Voided"
                    value={leftData?.Voided ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.voided.toString() ==
                leftData?.Voided?.toString() ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Voided"
                    value={rightSideData?.voided ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Paid */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Paid"
                    value={leftData?.Paid ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.paid.toString() ==
                leftData?.Paid?.toString() ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Paid"
                    value={rightSideData?.paid ? "Yes" : "No"}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Net Amount */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Net Amount
                    </Typography>
                    <NumberFormat
                      defaultValue={"0.00"}
                      value={leftData?.NetAmt}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={leftData?.Currency?.code + " "}
                    />
                  </Paper>
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.netAmt == leftData?.NetAmt ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Net Amount
                    </Typography>
                    <NumberFormat
                      defaultValue={"0.00"}
                      value={rightSideData?.netAmt}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={rightSideData?.currency?.code + " "}
                    />
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Currency */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Currency"
                    value={leftData?.Currency?.code}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.currency?.code == leftData?.Currency?.code ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Currency"
                    value={rightSideData?.currency?.code}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Payment Instrument */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Payment Instrument"
                    value={leftData?.FundReception?.type}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.fundReception?.type ==
                leftData?.FundReception?.type ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Payment Instrument"
                    value={rightSideData?.fundReception?.type}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Payee Account */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Payee Account
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.FundReception?.bankName}
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.FundReception?.accountNumber}
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.FundReception?.acName}
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.fundReception?.bankName ==
                  leftData?.FundReception?.bankName &&
                rightSideData?.fundReception?.accountNumber ==
                  leftData?.FundReception?.accountNumber &&
                rightSideData?.fundReception?.acName ==
                  leftData?.FundReception?.acName ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Payee Account
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.fundReception?.bankName}
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.fundReception?.accountNumber}
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.fundReception?.acName}
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Creation Date */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Creation Date"
                    value={moment(leftData?.CreationDate).format("DD/MM/YYYY")}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {moment(rightSideData?.creationDate).format("DD/MM/YYYY") ==
                moment(leftData?.CreationDate).format("DD/MM/YYYY") ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Creation Date"
                    value={moment(rightSideData?.creationDate).format(
                      "DD/MM/YYYY"
                    )}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Lines Details */}
          </>
        )}
      </Grid>
    </Paper>
  );
}

export default ValidationChildInvoice;
