import React from 'react';
import Footer from './Footer';
import ResponsiveDrawer from './ResponsiveDrawer';

const pageTemplate = (WrapComponent: any) => {
    const componentWithPageTemplate = () => {
        return (
            <>
                <ResponsiveDrawer />
                <div style={{minHeight: "calc(100vh - 100px)", height: "100%"}}>
                    <WrapComponent />
                </div>
                <Footer />
            </>
        );
    };

    return componentWithPageTemplate;
};

export default pageTemplate;