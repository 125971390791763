import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import configureStore from "./redux/store/store";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
  split,
  HttpLink,
} from "@apollo/client/";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { defaultTheme } from "./shared/theme/theme";

const store = configureStore();
const httpLink = new HttpLink({
  uri: "https://hlf.invoicemate.net/me-api/",
  // uri: "http://localhost:5500/graphql",  //for localhost
});

const wsLink = new WebSocketLink({
  // uri: "ws://app.invoicemate.net/lite/me-api/",
  uri: "wss://hlf.invoicemate.net/me-api/",
  // uri: "ws://localhost:5500/graphql",
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={defaultTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  </Provider>,
  document.querySelector("#root")
);
