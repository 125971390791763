import { Reducer } from 'redux';
import { ActionType } from '../../shared/globalTypes';
import { SET_THEME } from '../types';

export interface IConfigState {
  theme: string;
}

const initialState: IConfigState = {
    theme: 'light'
};

export const configReducer = (
  state = initialState,
  action: ActionType
) => {  
  switch (action.type) {
    case SET_THEME: {
      return {
        ...state,
        theme: action.payload
      };
    }

    default:
      return state;
  }
};