import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { Brightness7, Brightness4 } from "@material-ui/icons";
import { connect } from "react-redux";
import { changeTheme } from "../redux/actions/configActions";
import { Link } from "react-router-dom";
import logo from "../Images/MateExplorer.png";
import DayButton from "../Images/day-button.png";
import DarkButton from "../Images/night-button.png";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

// import VisibleItemList from '../containers/VisibleItemList'
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#1f1f1f",
    height: "55px",
  },
  toolBar: {
    minHeight: "55px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  navLinks: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {},
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  link: {
    display: "flex",
    flexDirection: "row",
    textDecoration: "none",
    // "&:hover, &:focus": {
    //     color: theme.palette.primary.main
    // }
    "& a": {
      textDecoration: "none",
      color: "#fff",
    },
  },
  logo: {
    marginTop: "10px",
    width: theme.spacing(24),
    height: theme.spacing(4),
  },
  hamburgerListLight: {
    "& a": {
      color: "#000"
    },
    "& ul": {
      display: "flex",
      flexDirection: "column",
    }
  },
  hamburgerListDark: {
    "& ul": {
      display: "flex",
      flexDirection: "column",
    }
  },
  dayNightBtn: {
    "& span": {
      justifyContent: "end !important",
      marginRight: theme.spacing(0.25)
    }
  }
}));
function ResponsiveDrawer(props: any) {
  const classes = useStyles();
  const { theme, changeTheme } = props;
  const logout = () => {
    localStorage.removeItem("state");
    window.location.href = "/lite/mate-explorer";
  };
  const dummyCategories = [
    {
      type: "text",
      title: "Home",
      path: "/",
      isRefresh: false

    },
    {
      type: "text",
      title: "Analytics",
      path: "/analytics",
      isRefresh: false
    },
    {
      type: "text",
      title: "Transactions",
      path: "/transactions",
      isRefresh: false

    },
    {
      type: "jsx",
      content: (
        <IconButton color="primary" size="small" edge="end" onClick={() => handleTheme()} >

          {theme === "light" ? <img src={DarkButton} style={{ width: "30%", height: "45%" }} /> : <img src={DayButton} style={{ marginTop: "0px", width: "30%", height: "45%" }} />}
        </IconButton>
      ),
      path: "",
      isRefresh: false
    },
    {
      type: "jsx",
      content: (
        <IconButton
          onClick={logout}
          className={classes.closeMenuButton}
        >
          <PowerSettingsNewIcon />
        </IconButton>
      ),
      path: "",
      isRefresh: false
    }
  ];
  const mobileCategories = [
    {
      type: "text",
      title: "Home",
      path: "/",
    },
    {
      type: "text",
      title: "Analytics",
      path: "/analytics"
    },
    {
      type: "text",
      title: "Transactions",
      path: "/transactions"
    }
  ]
  // const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const handleTheme = () => {
    if (theme === "dark") {
      changeTheme("light");
    } else {
      changeTheme("dark");
    }
  };

  const drawer = (
    <List className={classes.link}>
      {dummyCategories.map((item, index) =>
        item.type == "text" ?
          item.isRefresh ?
            <a href={item.path}>
              <ListItem button key={index}>
                <ListItemText>{item.title}</ListItemText>
              </ListItem>
            </a>
            :
            (
              <Link to={item.path}>
                <ListItem button key={index}>
                  <ListItemText>{item.title}</ListItemText>
                </ListItem>
              </Link>
            ) : (
            item.content
          )
      )}
    </List>
  );

  const drawerMobile = (
    <List className={classes.link}>
      {mobileCategories.map((item, index) =>
        <Link to={item.path}>
          <ListItem button key={index}>
            <ListItemText>{item.title}</ListItemText>
          </ListItem>
        </Link>
      )}
    </List>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Link to='/'>
              <img src={logo} className={classes.logo} />
            </Link>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              edge='end'
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Grid className={classes.navLinks}>{drawer}</Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden smUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={theme.direction === "rtl" ? "left" : "right"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Box className={classes.dayNightBtn} style={{
              display: "flex",
              flexDirection: "row"
            }}>
              <IconButton
                onClick={handleDrawerToggle}
                className={classes.closeMenuButton}
              >
                <CloseIcon />
              </IconButton>

              <IconButton disableRipple={true} color="primary" size="small" edge="end" onClick={() => handleTheme()} >
                {theme === "dark" ? <img src={DarkButton} style={{ width: "30%", height: "45%", right: 0 }} /> : <img src={DayButton} style={{ marginTop: "0px", width: "30%", height: "45%" }} />}
              </IconButton>
            </Box>
            <div className={theme === "dark" ? classes.hamburgerListLight : classes.hamburgerListDark}>
              {drawerMobile}
            </div>

            <Box className={classes.dayNightBtn} style={{
              position: "absolute",
              bottom: 0,
              right: 0
            }}>
              <IconButton
                onClick={logout}
                className={classes.closeMenuButton}
              >
                <PowerSettingsNewIcon />
              </IconButton>
            </Box>
          </Drawer>
        </Hidden>
        {/* <Hidden xsDown implementation="css">
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.toolbar} />
                        {drawer}
                    </Drawer>
                </Hidden> */}
      </nav>
      <div className={classes.content}>
        <div className={classes.toolbar} />
      </div>
    </div>
  );
}
ResponsiveDrawer.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.configState.theme,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  changeTheme: (themeType: string) => dispatch(changeTheme(themeType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);
