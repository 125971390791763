import React, { useEffect, useState } from "react";
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  Tooltip,
  Card,
  Box,
} from "@material-ui/core";
import { logSizeInKilobytes } from "../shared/helpers/getSizeInKB";
import QRCode from "react-qr-code";
import { Skeleton } from "@mui/material";
import moment from "moment";
import NumberFormat from "react-number-format";
import { OfflinePinTwoTone } from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { transactionTypeEnum } from "../shared/enum";
const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: "0px",
    "& MuiBox-root": {
      padding: "0px",
    },
  },
  grid: {
    padding: theme.spacing(3),
  },
  qrCodeGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  qrCode: {
    marginRight: -theme.spacing(2),
  },
}));

const EmailTracking = (props: any) => {
  const { data } = props;
  console.log("🚀 ~ EmailTracking ~ data:", data);

  const classes = useStyles();
  function capitalize(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <>
      {data.Type === transactionTypeEnum.InvoiceFinance ? (
        <Paper className={classes.root} elevation={2}>
          <Grid container justify="center" className={classes.grid}>
            <Grid direction="row" item xs={12}>
              <h2>Not Applicable</h2>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={9}>
                <List>
                 
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <>
          <Paper className={classes.root} elevation={2}>
            <Grid container justify="center" className={classes.grid}>
              <Grid direction="row" item xs={12}>
                <h2>Email Information</h2>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={9}>
                  <List>
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        <ListItemText>
                          <b>To</b>
                        </ListItemText>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <ListItemText>
                          <Typography>{data?.Tracking?.To}</Typography>
                        </ListItemText>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        <ListItemText>
                          <b>Subject</b>
                        </ListItemText>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <ListItemText>
                          {data?.Tracking?.Subject}
                          {/*  */}
                        </ListItemText>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        <ListItemText>
                          <b>Email Id</b>
                        </ListItemText>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <ListItemText>
                          <Typography>
                            {data?.Tracking?.MsgId}
                            {/* {invoiceState ? logSizeInKilobytes(invoiceState) : ""} */}
                          </Typography>
                        </ListItemText>
                      </Grid>
                    </Grid>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <br />
          <Paper elevation={2}>
            <Grid container justify="center" className={classes.grid}>
              <Grid direction="row" item xs={12}>
                <h2>Email Track</h2>
              </Grid>
              <Grid container>
                {data?.Tracking?.Events?.length > 0 &&
                  data?.Tracking?.Events?.map((item: any, index: any) => (
                    <Grid item xs={12}>
                      <Box
                        borderBottom={1}
                        key={index}
                        style={{
                          padding: "20px 10px",
                          display: "flex",
                          alignItems: "center",
                          marginBottom:
                            index !== data.length - 1 ? 4.5 : undefined,
                        }}
                      >
                        {/* <img alt={""} src={""} width={32} /> */}
                        <OfflinePinTwoTone style={{ color: green[500] }} />
                        <Box
                          style={{
                            marginLeft: "40px",
                            // rowGap: 1,
                            // columnGap: 4,
                            width: "100%",
                            display: "flex",
                            // flexWrap: 'wrap',
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography variant="h6">
                              {capitalize(item?.EventName)}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{ color: "text.disabled" }}
                            >
                              {moment(item?.Processed).format(
                                "DD MM YYYY hh:mm:ss A"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
};

export default EmailTracking;
