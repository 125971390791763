import { ActionType } from '../../shared/globalTypes';
import { SET_REFECH_METHOD, SET_TRANSACTION } from '../types';

export interface ITransactionState {
  transactions: [];
}

const initialState: ITransactionState = {
    transactions: [],
    
};

export const transactionReducer = (
  state = initialState,
  action: ActionType
) => {  
  switch (action.type) {
    case SET_TRANSACTION: {
      return {
        ...state,
        transactions: action.payload
      };
    }
    case SET_REFECH_METHOD:
      return{
        ...state,
        refetch: action.payload
      }
    default:
      return state;
  }
};