import { Button, ButtonGroup, Grid, makeStyles, MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import { blue, grey } from '@material-ui/core/colors';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSelector } from 'react-redux';
import { IAppState } from '../../redux/store/store';
import { FormControl, Select } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme: any) => ({
    root: {
        "& .MuiNativeSelect-select": {
            height: "25px",
            padding: "2px 21px !important",
            "&:hover": {
                border: "none !important",
            },
        }
    },
    darkTheme: {
        "& .MuiNativeSelect-select": {
            height: "25px",
            padding: "2px 21px !important",
            "&:hover": {
                border: "none !important",
            },
        },
        "& .apexcharts-tooltip-title": {
            color: "#000"
        },
        "& .apexcharts-tooltip-text-y-value": {
            color: "#000"
        },
        "& .apexcharts-tooltip-text-y-label": {
            color: "#000"
        }
    },
    button: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        "& .MuiSelect-root": {
            padding: "0px",
            border: "none !important",
        },
        "& .MuiSelect-root:hover": {
            padding: "0px",
            border: "none !important",
        }
    },
    dailySelectLight: {
        marginTop: theme.spacing(1),
        paddingTop: "2px !important",
        border: "none !important",
        "& select": {
            border: "1px solid #fff !important",
        },
        padding: "1px 10px !important",
    },
    dailySelectDark: {
        margin: theme.spacing(1),
        paddingTop: "2px !important",
        color: "#0a0a0a !important",
        border: "none !important",
        backgroundColor: "#9d9d9d !important",
        "& select": {
            border: "1px solid #9d9d9d !important",
        },
        padding: "1px 10px !important",
    }
}));
const blueTheme = createTheme({ palette: { primary: blue } })
const greyTheme = createTheme({ palette: { primary: grey } })

const CostChart = (props: any) => {
    const { linesList, initialTimeStampRange, currentMonth, setCurrentMonth, getMonthlyStatsMutation, loading } = props;

    const classes = useStyles();
    const theme = useSelector((state: IAppState) => state.configState.theme)
    const [lineState, setLineState] = useState<Array<{
        name: string;
        data: Array<any>
    }>>([])
    const [seriesState, setSeriesState] = useState<Array<{
        name: string;
        data: Array<any>
    }>>([])
    const [buttonsState, setButtonsState] = useState<any>({
        CombinedLineTemp: false,
        InvoiceLine: false,
        InvoicePaymentLine: false,
        InvoiceFinanceLine: false,
    })
    const [isHourly, setIsHourly] = useState(false)
    const [xZoom, setSetXZoom] = useState({
        min: 0,
        max: 0
    })
    const monthList = [
        { title: "Jan", value: 1 },
        { title: "Feb", value: 2 },
        { title: "Mar", value: 3 },
        { title: "Apr", value: 4 },
        { title: "May", value: 5 },
        { title: "Jun", value: 6 },
        { title: "Jul", value: 7 },
        { title: "Aug", value: 8 },
        { title: "Sep", value: 9 },
        { title: "Oct", value: 10 },
        { title: "Nov", value: 11 },
        { title: "Dec", value: 12 }
    ]
    let state = {
        series: seriesState,
        options: {
            chart: {
                type: 'area',
                stacked: false,
                height: 700,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                },
                animations: {
                    enabled: false
                },
                events: {
                    beforeZoom: (chartContext: any, { xaxis }: any) => {
                        // console.log(chartContext)
                        // console.log(chartContext.ctx.timeScale.tickInterval)
                        // console.log(xaxis)

                        if (
                            chartContext.ctx.timeScale.tickInterval == "months" ||
                            chartContext.ctx.timeScale.tickInterval == "months_days" ||
                            chartContext.ctx.timeScale.tickInterval == "months_fortnight" ||
                            chartContext.ctx.timeScale.tickInterval == "week_days" ||
                            chartContext.ctx.timeScale.tickInterval == "days" ||
                            chartContext.ctx.timeScale.tickInterval == "hours"
                        ) {
                            // This is allowed range
                            setSetXZoom({
                                ...xZoom,
                                min: xaxis.min,
                                max: xaxis.max
                            })
                            return {
                                xaxis: {
                                    min: xaxis.min,
                                    max: xaxis.max
                                }
                            }
                        }
                        else if (chartContext.ctx.timeScale.tickInterval == "minutes_fives") {
                            // This is the lower extreme
                            if (
                                xZoom.max > xaxis.max
                            ) {
                                return {
                                    xaxis: {
                                        min: chartContext.minX,
                                        max: chartContext.maxX
                                    }
                                }
                            } else {
                                return {
                                    xaxis: {
                                        min: xaxis.min,
                                        max: xaxis.max
                                    }
                                }
                            }
                        }
                        else {
                            // This is for ultra zooming out
                            return {
                                xaxis: {
                                    min: initialTimeStampRange.min,
                                    max: initialTimeStampRange.max
                                }
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            // markers: {
            //     size: 5,
            //     colors: undefined,
            //     strokeColors: '#fff',
            //     strokeWidth: 2,
            //     strokeOpacity: 0.9,
            //     strokeDashArray: 0,
            //     fillOpacity: 1,
            //     discrete: [],
            //     shape: "circle",
            //     radius: 2,
            //     offsetX: 0,
            //     offsetY: 0,
            //     onClick: undefined,
            //     onDblClick: undefined,
            //     showNullDataPoints: true,
            //     hover: {
            //       size: undefined,
            //       sizeOffset: 3
            //     }
            // },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            legend: {
                labels: {
                    colors: theme === "dark" ? "#000" : "#cbcaca",
                },
            },
            yaxis: {
                title: {
                    text: 'Cost ($)',
                    style: {
                        color: theme === "dark" ? "#000" : "#cbcaca",
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-yaxis-title',
                    }
                },
                labels: {
                    style: {
                        colors: theme === "dark" ? ["#000"] : ["#cbcaca"]
                    }
                }
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: 'MMM \'yy',
                        day: 'dd MMM',
                        hour: 'HH'
                    },
                    style: {
                        colors: theme === "dark" ? "#000" : "#cbcaca"
                    },
                    datetimeUTC: true
                },
                tickPlacement: "on"
            },
            tooltip: {
                shared: true,
                x: {
                    show: true,
                    format: 'dd MMM hh:mm',
                    // formatter: undefined,
                },
                y: {
                    formatter: function (val: any) {
                        return "$ " + val
                    }
                }
            },
            noData: {
                text: "...loading",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: theme === "dark" ? "#000" : "#fff",
                    fontSize: '14px',
                    fontFamily: undefined
                }
            }
        }
    };
    useEffect(() => {
        setButtonsState({
            ...buttonsState,
            CombinedLineTemp: false,
            InvoiceLine: true,
            InvoicePaymentLine: true,
            InvoiceFinanceLine:true
        })
    }, [])
    useEffect(() => {
        if (linesList) {
            setLineState([
                {
                    name: "All",
                    data: linesList.CombinedLineTemp
                },
                {
                    name: "Invoice",
                    data: linesList.InvoiceLine
                },
                {
                    name: "Invoice Payment",
                    data: linesList.InvoicePayment
                },
                {
                    name: "Finance",
                    data: linesList.InvoiceFinance
                }
            ]);
            setSeriesState([
                {
                    name: "Invoice",
                    data: linesList.InvoiceLine
                },
                {
                    name: "InvoicePayment",
                    data: linesList.InvoicePaymentLine
                },
                {
                    name: "Finance",
                    data: linesList.InvoiceFinanceLine
                }
            ])
            setButtonsState({
                ...buttonsState,
                CombinedLineTemp: false,
                InvoiceLine: true,
                InvoicePaymentLine: true,
                InvoiceFinanceLine: true
            })
        }
    }, [linesList])
    const types: any = {
        CombinedLineTemp: "All",
        InvoiceLine: "Invoice",
        InvoicePaymentLine: "Invoice Payment",
        InvoiceFinanceLine: "Finance"
    }
    const handleClick = (type: any) => {
        debugger
        if (type === "CombinedLineTemp") {
            if (!buttonsState.CombinedLineTemp) {
                setButtonsState({
                    ...buttonsState,
                    CombinedLineTemp: true,
                    InvoiceLine: false,
                    InvoicePaymentLine: false,
                    InvoiceFinanceLine: false
                })
                setSeriesState([
                    {
                        name: "All",
                        data: linesList.CombinedLineTemp
                    }
                ])
            }
        } else {
            let btnState: any = buttonsState;
            if (!btnState[type]) {
                setButtonsState({
                    ...buttonsState,
                    CombinedLineTemp: false,
                    [type]: true
                })
                let tempLinesList = []
                for (let index = 0; index < seriesState.length; index++) {
                    const series = seriesState[index];
                    if (series.name !== "All") {
                        tempLinesList.push(series)
                    }
                }
                tempLinesList.push({
                    name: types[type],
                    data: linesList[type]
                })
                setSeriesState(tempLinesList);
            } else {
                let currentState = seriesState;
                currentState = currentState.filter((item, index) => item.name !== types[type])
                if (currentState.length == 0) {
                    setSeriesState([
                        {
                            name: "All",
                            data: linesList.CombinedLineTemp
                        }
                    ])
                    setButtonsState({
                        ...buttonsState,
                        CombinedLineTemp: true,
                        InvoiceLine: false,
                        InvoicePaymentLine: false,
                        InvoiceFinanceLine: false
                    })
                } else {
                    setSeriesState(currentState)
                    setButtonsState({
                        ...buttonsState,
                        CombinedLineTemp: false,
                        [type]: !buttonsState[type]
                    })
                }
            }
        }
    }
    const resetFilters = () => {
        setButtonsState({
            ...buttonsState,
            CombinedLineTemp: true,
            InvoiceLine: false,
            InvoicePaymentLine: false,
            InvoiceFinanceLine: false,
        })
        setSeriesState([
            {
                name: "All",
                data: linesList.CombinedLineTemp
            }
        ])
    }

    const changeOption = (e: any) => {
        setCurrentMonth(e.target.value)
        getMonthlyStatsMutation({
            variables: {
                month: parseInt(e.target.value)
            }
        })
    }

    useEffect(() => {
        console.log("CostChart: ", loading)
    }, [loading])

    return (
        <div className={theme === "dark" ? classes.root : classes.darkTheme}>
            <Grid item>
                <MuiThemeProvider theme={blueTheme}>
                    <FormControl className={classes.formControl}>
                        <Select
                            native
                            className={`
                                ${theme == "dark"
                                    ? classes.dailySelectLight
                                    : classes.dailySelectDark}
                            `}
                            onChange={changeOption}
                            value={currentMonth}
                        >
                            {
                                monthList.map((item, index) => (
                                    <option value={item.value} selected={item.value == currentMonth}>
                                        {item.title}
                                    </option>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <Button onClick={() => handleClick("CombinedLineTemp")} variant={buttonsState.CombinedLineTemp ? "contained" : "outlined"} size="small" color="primary" className={classes.button}>
                        Overall
                    </Button>
                    <Button onClick={() => handleClick("InvoiceLine")} variant={buttonsState.InvoiceLine ? "contained" : "outlined"} size="small" color="primary" className={classes.button}>
                        Invoice
                    </Button>
                    <Button onClick={() => handleClick("InvoicePaymentLine")} variant={buttonsState.InvoicePaymentLine ? "contained" : "outlined"} size="small" color="primary" className={classes.button}>
                        Invoice Payment
                    </Button>
                    <Button onClick={() => handleClick("InvoiceFinanceLine")} variant={buttonsState.InvoiceFinanceLine ? "contained" : "outlined"} size="small" color="primary" className={classes.button}>
                        Finance
                    </Button>
                </MuiThemeProvider>
                <MuiThemeProvider theme={greyTheme}>
                    <Button onClick={resetFilters} color="primary" className={classes.button}>
                        <CancelIcon />
                    </Button>
                </MuiThemeProvider>
            </Grid>
            {
                loading ?
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "350px" }}>
                        <CircularProgress />
                    </Box>
                    :
                    <ReactApexChart options={state.options as any} series={state.series} type="area" height={350} />
            }
        </div>
    )
}

export default CostChart
