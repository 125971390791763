import {
  Backdrop,
  Badge,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InboxIcon from "@material-ui/icons/Inbox";
import pageTemplate from "../components/PageHoc";
import TransactionTypeTable from "../components/TransactionTypeTable";
import {
  columnNames,
  sideBarItemsWithTypes,
} from "../shared/config/TransactionsMasterViewConfig";
import { transactionTypeEnum } from "../shared/enum";
import Swal from "sweetalert2";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_EVERYTHING,
  GET_EVERYTHING_FILTERED,
} from "../graphql/query/homePage.query";
import { logSizeInKilobytes } from "../shared/helpers/getSizeInKB";
import { GET_TX_BY_TYPE } from "../graphql/mutation/TransactionMasterView.mutation";
import moment from "moment";
import { GET_NO_OF_TRANSACTION_BY_TYPE } from "../graphql/query/TransactionMasterView.query";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {},
  colLeft: {
    // padding: "1%"
    padding: theme.spacing(3),
  },
  colRight: {
    // padding: "1%"
    padding: theme.spacing(3),
  },
  activeType: {
    backgroundColor: "#dddddd8f",
  },
  heading: {
    paddingBottom: theme.spacing(1),
  },
}));

const TransactionsMasterView = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tableState, settableState] = useState({
    title: "Invoice",
    type: "",
    data: [
      {
        txnID: "3242424",
        type: "Invoice",
        timeStamp: "234242",
        txnHash: "fasfafsafasfdsafasfasf",
        size: "23",
      },
    ],
  });

  interface Invoice {
    TxnHash: string;
    Type: string;
    TimeStamp: string;
    Email: string;
    PreviousInvoiceHash: string;
    InvoiceId: string;
    MongoId: string;
    creationDate: string;
    date: string;
    CreationDate: string;
    Date: string;
    vendordetail: string;
    VendorEmail: string;
    VendorMobileNumber: string;
    FinanceId: string;
    ClientEmail: string;
    ClientMobileNumber: string;
  }

  const getTnxs = async () => {
    try {
      setLoading(true);
      const { data: invoiceData } = await axios.get<Invoice[]>(
        "https://hlf.invoicemate.net/invoice/get-all"
      );
      const { data: paymentData } = await axios.get<Invoice[]>(
        "https://hlf.invoicemate.net/payment/get-all"
      );
      const { data: financeDate } = await axios.get<Invoice[]>(
        "https://hlf.invoicemate.net/finance/get-all"
      );
      let datasort = invoiceData.concat(paymentData, financeDate);
      datasort.sort((x, y) => {
        return (
          new Date(y.TimeStamp).getTime() - new Date(x.TimeStamp).getTime()
        );
      });
      let data1 = datasort.map((tnx) => {
        
        return {
          txnID: tnx?.TxnHash || "",
          financeId: tnx?.FinanceId || "",
          type: tnx?.Type || "",
          timeStamp: tnx?.TimeStamp || "",
          txnHash: tnx?.TxnHash || "",
          size: "",
          invoiceId: tnx?.InvoiceId || "",
          creationDate: tnx?.Type === "Invoice" ? tnx?.CreationDate : tnx?.Date,
          mongoId: tnx?.MongoId || "",
          date: tnx?.TimeStamp || "",
          vendorDetail:tnx?.InvoiceId.includes("BIL") ? 
                tnx?.ClientEmail || tnx?.ClientMobileNumber || tnx?.Email  :tnx?.VendorEmail || tnx?.VendorMobileNumber || tnx?.Email ,
        };
      });
      debugger;
      settableState({
        ...tableState,
        data: data1,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Error in Getting Transactions",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    getTnxs();
  }, []);

  const [transactionCountByType, setTransactionCountByType] = useState<any>({
    Invoice: { __typename: "dataBits", number: 0 },
    InvoicePayment: { __typename: "dataBits", number: 0 },
  });

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [getTransactionByType, getTransactionByTypeResp] = useMutation(
    GET_TX_BY_TYPE,
    {
      onCompleted: (data) => {
        setLoading(false);

        if (data.getTxByType !== null) {
          let datasort = data.getTxByType.reverse();
          // datasort.sort((x:any, y:any) => {

          //     return new Date(y.TimeStamp).getTime() - new Date(x.TimeStamp).getTime();
          //   });
          let data1 = datasort.map((tnx: any) => {
            
            return {
              txnID: tnx?.txnHash || "",
              type: tnx?.type || "",
              financeId: tnx?.financeId || "",
              timeStamp: tnx?.creationDate || "",
              txnHash: tnx?.txnHash || "",
              size: "",
              invoiceId: tnx?.invoiceId || "",
              creationDate: tnx?.creationDate,
              mongoId: tnx?.mongoId || "",
              date: tnx?.creationDate || "",
              vendorDetail:tnx?.InvoiceId.includes("BIL") ? 
              tnx?.ClientEmail || tnx?.ClientMobileNumber || tnx?.Email|| tnx?.MobileNumber  :tnx?.VendorEmail || tnx?.VendorMobileNumber || tnx?.Email ||tnx?.MobileNumber ,
            };
          });
          settableState({
            ...tableState,
            data: data1,
          });
        }
      },
      onError: (resp) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: resp,
          showConfirmButton: false,
          timer: 1500,
        });
      },
    }
  );
  useEffect(() => {
    // getTransactionByType({
    //     variables: {
    //         type: tableState.type,
    //         offset: offset,
    //         limit: limit
    //     }
    // })
  }, []);

  const getNoOfTransactionByType = useQuery(GET_NO_OF_TRANSACTION_BY_TYPE, {
    onCompleted: () => {
      let temp = getNoOfTransactionByType.data.kpi.models;
      if (temp.invoices !== null && temp.payments !== null) {
        setTransactionCountByType(getNoOfTransactionByType.data.kpi.models);
      }
    },
    onError: (resp) => {
      Swal.fire({
        icon: "error",
        title: resp,
        showConfirmButton: false,
        timer: 1500,
      });
    },
  });

  const sideBarItemClick = (type: any) => {
    setLoading(true);
    settableState({
      ...tableState,
      type: type,
    });

    getTransactionByType({
      variables: {
        type: type,
        offset: offset,
        limit: limit,
      },
    });
  };

  return (
    <Grid container alignItems="flex-start">
      <Grid item xs={12} md={2} className={classes.colLeft}>
        <Typography className={classes.heading} variant="h5" color="initial">
          Transaction Types
        </Typography>
        <Paper className={classes.root} elevation={2}>
          <List component="nav" aria-label="main mailbox folders">
            {sideBarItemsWithTypes.map((item) => (
              <ListItem
                className={`${
                  item.type == tableState.type && classes.activeType
                }`}
                onClick={() => sideBarItemClick(item.type)}
                button
              >
                <ListItemText primary={item.type} />
                {/* {tableState.type} */}
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} md={10} className={classes.colRight}>
        <Typography className={classes.heading} variant="h5" color="initial">
          Details
        </Typography>
        <Paper elevation={2}>
          <TransactionTypeTable
            data={tableState.data}
            columns={columnNames}
            isLoading={loading}
            getTransactionByType={getTnxs}
            tableState={tableState}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            setLimit={setLimit}
            dataLength={tableState?.data?.length || 0}
            settableState={settableState}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default pageTemplate(TransactionsMasterView);
