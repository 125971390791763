import { gql } from "@apollo/client";

export const GET_INVOICE = gql`
  mutation getInvoice($id: String) {
      getInvoice(id: $id) {
        type
        invoiceId
        creationDate
        vendorEmail
        vendorName
        clientEmail
        clientName
        currency
        fundReception
        lines
        netAmt
        paid
        rejected
        voided
        ack
        draft
        txnHash
        cost
        mongoId
      }
    }
`;

export const GET_PAYMENT = gql`
  mutation getPayment($id: String) {
      getPayment(id: $id) {
        type
        invoiceId
        paymentID
        vendorId
        currencyCode
        paidAmount
        paymentType
        receiptUrl
        date
        paymentGateway
        txnHash
        cost
      }
    }
`;

// ======================================

export const GET_INVOICE_HISTORY = gql`
  mutation invoiceHistory($id: String) {
      invoiceHistory(id: $id) {
        type
        invoiceId
        creationDate
        vendorEmail
        vendorName
        clientEmail
        clientName
        currency
        fundReception
        lines
        netAmt
        paid
        rejected
        voided
        draft
        txnHash
        action
        timeStamp
        mongoId
      }
    }
`;

        // creationTxnId
        // ackTxnId
        // rejectedTxnId
        // voidedTxnId
        // paymentConfirmationTxnId
        // paidTxnId

export const GET_PAYMENT_HISTORY = gql`
  mutation paymentHistory($id: String) {
      paymentHistory(id: $id) {
        type
        invoiceId
        paymentID
        vendorId
        currencyCode
        paidAmount
        paymentType
        receiptUrl
        date
        paymentGateway
        txnHash
      }
    }
`;

export const GET_MONTH_STATICS = gql`
  mutation getMonthlyStats($month: Int) {
    getMonthlyStats(month: $month) {
      yearlyChart
      }
    }
`;