import { Paper, TableContainer } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import TransactionDetail from './pages/TransactionDetail';
import Home from './pages/home';
import { IAppState } from './redux/store/store';
import { darkTheme, defaultTheme } from './shared/theme/theme';
import TransactionList from './components/transactionList';
import './App.style.scss';
import Analytics from './components/analytics';
import TransactionsMasterView from './pages/TransactionsMasterView';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Login from './pages/Login';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
  paper: {
    height: "auto"
  }
})

function PrivateRoute({ children, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("state") == "logged_in" ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function LoginPrivateRoute({ children, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("state") == "logged_in" ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

function App() {
  const classes = useStyles();
  const theme = useSelector((state: IAppState) => state.configState.theme)

  return (
    <div className="App">
      <ThemeProvider theme={theme === "dark" ? defaultTheme : darkTheme}>
       
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Route>
            <Switch>
              <LoginPrivateRoute exact={true} path="/login">
                <Login />
              </LoginPrivateRoute>
              <PrivateRoute exact={true} path="/">
                <Home />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/analytics">
                <Analytics />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/transaction/:id/:type/:inv">
                <TransactionDetail />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/transactionList">
                <TransactionList />
              </PrivateRoute>
              <PrivateRoute exact={true} path="/transactions">
                <TransactionsMasterView />
              </PrivateRoute>
          

              <Route>Page not Found</Route>
            </Switch>
          </Route>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;