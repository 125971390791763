import { Paper, Typography } from "@material-ui/core";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import MainTableDetail from "./MainTableDetail";
import pageTemplate from "./PageHoc";

const TransactionList =() => {
    const transactions = useSelector((state :any) => state.transactions);
    console.log("after then search",transactions )
    const history = useHistory();
    const [type, setType] = useState("");
    React.useEffect(() => {
        if (transactions.transactions === undefined || transactions.transactions.length === 0) {
            history.push("/");
        }else{
            setType(transactions.transactions[0].type);
        }
      }, [transactions.transactions]);
    return(
        // <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} style={{padding:'4%'}}>
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h6" style={{padding:"1% 0 1% 0"}}>Search Results</Typography>
                <MainTableDetail getEverythingResp={transactions.transactions} />
            </Grid>
        </Grid>
        // </Box>
    )
}
export default pageTemplate(TransactionList);
