import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CustomCodeBlock from "./CustomCodeBlock";
import { useParams } from 'react-router-dom';
import TransactionSummary from "./TransactionSummary";
import { OperationVariables, QueryResult } from "@apollo/client";
import { Grid, Skeleton } from "@mui/material";
import TransactionDetailedData from "./TransactionDetailedData";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Validation from "./Validation/Validation";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { transactionTypeEnum } from "../shared/enum";
import HistoryBlocks from "./HistoryBlocks";
import PaymentHistoryBlocks from './PaymentHistoryBlocks';
import CompleteVendorDetails from "./CompleteVendorDetails";
import EmailTracking from "./EmailTracking";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  tabPanel: {
    padding: "0px",
  },
  visibilityIcon: {
    cursor: "pointer"
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#b15176"
    }
  }
}));

type Props = {
  data: any;
  type: string;
  loading: boolean;
  isVisible: boolean;
  handleVisibility: any;
  historyLoading: boolean;
  historyData: any;
  historyType: any;
};
interface ParamTypes {
  id: string;

}
export default function TransactionDetailTabs(props: Props) {
  let { id } = useParams<ParamTypes>();
  const {
    data,
    type,
    loading,
    isVisible,
    handleVisibility,
    historyLoading,
    historyData,
    historyType
  } = props;
  const classes = useStyles();
  const [state, setState] = useState({});
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
  });
  useEffect(() => {
    debugger
    if (data) {
      setState(data);

      console.log("TransactionDetailTabs state: ", historyData)
    }
  }, [data, historyData]);

  // """"""""""""""
  useEffect(() => {
    setValue(0);

  }, [id]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {

    setValue(newValue);
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item style={{ width: isMobile ? "100%" : "" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='simple tabs example'
            variant={isMobile ? "scrollable" : "standard"}
            indicatorColor="secondary"
            textColor="inherit"
            scrollButtons={isMobile}
            allowScrollButtonsMobile={isMobile}
            className={classes.tabs}
          >
            <Tab label='Details' {...a11yProps(0)} />
            <Tab label='JSON' {...a11yProps(1)} />
            <Tab label='Validation' {...a11yProps(2)} />
            <Tab label='Activity Tracking' {...a11yProps(5)} />
            {isMobile && <Tab label='History' {...a11yProps(3)} />}
            <Tab label='Vendor Detail' {...a11yProps(4)} />
          </Tabs>
        </Grid>
        <Grid item sm></Grid>
        <Grid item>
          <Box onClick={() => handleVisibility()}>
            {!isVisible ? <LockIcon className={classes.visibilityIcon} /> : <LockOpenIcon className={classes.visibilityIcon} />}
          </Box>
        </Grid>
      </Grid>

      <TabPanel value={value} index={0}>
        {loading ? (
          <>
            <Skeleton animation='wave' width='100%' height='300px' />
            <br />
            <Skeleton animation='wave' width='100%' height='300px' />
          </>
        ) : (
          <>
            <TransactionSummary data={state} type={type} />
            <br />
            <TransactionDetailedData data={state} type={type} isVisible={isVisible} />
          </>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CustomCodeBlock data={state} isVisible={isVisible} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Validation data={state} type={type} isVisible={isVisible} />
      </TabPanel>

      <TabPanel value={value} index={3}>
        
        <EmailTracking data={data} />

      </TabPanel>
      <TabPanel value={value} index={4}>
        {isMobile ? (<>{type === transactionTypeEnum.Invoice && <HistoryBlocks loading={historyLoading} data={historyData} type={historyType} />}
          {type === transactionTypeEnum.Payment && <PaymentHistoryBlocks loading={historyLoading} data={historyData} type={historyType} />}</>) : (<></>)}

      </TabPanel>
      <TabPanel value={value} index={isMobile ? 5 : 4}>
        <CompleteVendorDetails data={data} />

      </TabPanel>
    </>
  );
}
