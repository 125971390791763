import { SET_THEME } from "../types";

export const changeTheme = (themeType: string) => {
    console.log("action themeType: ", themeType)
    return (dispatch: any) => {
        dispatch({
            type: SET_THEME,
            payload: themeType
        });
    }
};