import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  createStyles,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Link, Redirect } from "react-router-dom";
import { yellow } from "@material-ui/core/colors";
import { InsertLink } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import moment from "moment";
import HistoryBlockItem from "./HistoryBlockItem";
import { logSizeInKilobytes } from "../shared/helpers/getSizeInKB";

const IssueNote = "Issue Note";
const IssueNotesReturn = "Issue Notes Return";
const EmergencyIssue = "Emergency Issue";
const GatePass = "Gate Pass";
const Stock = "Stock";
const Inventory = "Inventory";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    rootDiv: {
      // marginTop: `${theme.spacing(22)}px`,
      "&": {
        scrollbarColor: "#6b6b6b #b1b1b1",
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          backgroundColor: "#fafafa",
          height: 3,
          width: 5,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          borderRadius: 8,
          backgroundColor: "#6b6b6b",
          minHeight: 2,
          border: "1px solid #b1b1b1",
        },
        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          {
            backgroundColor: "#959595",
          },
        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          {
            backgroundColor: "#959595",
          },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: "#959595",
          },
        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          backgroundColor: "#b1b1b1",
        },
      },
    },
    root: {
      backgroundColor: "transparent",
      padding: "0px",
      maxHeight: "100vh",
      overflowY: "scroll",
      "& a": {
        color: "#3498db",
      },
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: theme.spacing(55),
      // minHeight: `${theme.spacing(40)}px`,
    },
    list: {
      display: "flex",
      flexDirection: "column",
      textDecoration: "none",
      height: "100%",
    },
    listItem: {},
    block: {
      width: "100%",
      height: "auto",
      textAlign: "center",
      padding: theme.spacing(1),
      // color: "#000",
      // background: "#fff",
      "& p": {
        margin: "0px",
      },
    },
    linkIcon: {
      marginLeft: theme.spacing(4),
    },
    step: {
      marginLeft: "0px",
      paddingLeft: "0px",
      // "& $completed": {
      //     color: "lightgreen"
      // },
      // "& $active": {
      //     color: "pink"
      // },
      // "& $disabled": {
      //     color: "red"
      // }
      // height: theme.spacing(50)
    },
    stepLabel: {
      "& .MuiTypography-root": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    active: {},
    completed: {},
    disabled: {},
    blockChild: {
      margin: theme.spacing(2),
      textAlign: "left",
      "&:nth-child(1)": {
        position: "relative",
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    grid: {
      padding: theme.spacing(2),
      textAlign: "left",
    },
    verifiedIcon: {
      position: "absolute",
      right: 0,
      color: "#ba67cf",
      "&:hover": {
        color: "#e597f9",
      },
    },
    financeIcon: {
      position: "absolute",
      right: 50,
      color: "#ba67cf",
      "&:hover": {
        color: "#e597f9",
      },
    },
    invoiceHeading: {
      fontSize: "24px",
      fontFamily: "Inter, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      lineHeight: "36px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      marginLeft: "25px",
      marginTop: "0px",
      marginBottom: "16px",
    },
  })
);

const HistoryBlocks = (props: any) => {
  const { data, loading, setLoading } = props;
  const classes = useStyles();
  const loaderBlocksList = [0, 1, 2];
  const [state, setState] = useState([]);
  const [offchainInvoiceDataState, setOffchainInvoiceDataState] = useState({
    paidTxnId: "",
    ackTxnId: "",
    voidedTxnId: "",
    paymentConfirmationTxnId: "",
    rejectedTxnId: "",
    creationTxnId: "",
  });

  useEffect(() => {
    if (data?.length > 0) {
      debugger;
      setState(data || []);
      getOffChainData();
    }
  }, [data]);

  useEffect(() => {
    console.log(offchainInvoiceDataState);
  }, [offchainInvoiceDataState]);

  const getOffChainData = () => {
    let url = "https://app.invoicemate.net/";
    // let url = "";
    let invocieApiUrl = `${url}lite/api/invoices/get/`;
    let token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MWUxYTQ1MzExNjY0NjNlYjE1YTA2ZjUiLCJlbWFpbCI6InRoYW5vc0BtYWlsaW5hdG9yLmNvbSIsIm5hbWUiOiJTaXIgVGhhbm9zIiwiaXNBZG1pbiI6ZmFsc2UsImlkIjoiNjFlMWE0NTMxMTY2NDYzZWIxNWEwNmY1IiwidXNlcm5hbWUiOiJTaXIiLCJpc0xvZ2luIjpmYWxzZSwiaWF0IjoxNjQyMzAyMjYwfQ.ZGUFFR1PIES_73ga8_Rmtuh75daN35B6oG7KDgBA_7s";
    url = invocieApiUrl + data[0]?.MongoId;
    fetch(url, {
      headers: {
        authorization: token,
      },
    })
      .then((data) => data.json())
      .then((res: any) => {
        setOffchainInvoiceDataState(res.invoice);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let history = useHistory();

  function capitalizeFirstLetter(string: string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  function unixTimestampToDate(unix_timestamp: any) {
    var date = new Date(parseInt(unix_timestamp));
    return date;
  }

  function getTnxId(block: any) {
    switch (block?.Action) {
      case "Paid":
        return offchainInvoiceDataState?.paidTxnId || "";

      case "Acknowledge":
        return offchainInvoiceDataState?.ackTxnId || "";

      case "Voided":
        return offchainInvoiceDataState?.voidedTxnId || "";

      case "Payment":
        return offchainInvoiceDataState?.paymentConfirmationTxnId || "";

      case "Payment Confirmation":
        return offchainInvoiceDataState?.paymentConfirmationTxnId || "";

      case "Rejected":
        return offchainInvoiceDataState?.rejectedTxnId || "";

      case "Creation":
        debugger;
        return offchainInvoiceDataState?.creationTxnId || "";

      default:
        return "";
        break;
    }
  }

  return (
    <div className={classes.rootDiv}>
      <h2 style={{ marginLeft: "25px", marginTop: "0px" }}>Invoice History</h2>
      <Stepper orientation="vertical" className={classes.root}>
        {!loading
          ? state?.map((block: any, index: any) => (
              <Step
                key={index}
                className={classes.step}
                expanded={true}
                active={true}
              >
                <StepLabel className={classes.stepLabel}>
                  {capitalizeFirstLetter(block?.Action)}{" "}
                  <span style={{ float: "right" }}>
                    {moment(block.TimeStamp).format("DD/MM/YYYY, h:mm:ss")}
                  </span>
                </StepLabel>
                <StepContent>
                  <Paper className={classes.block} elevation={6}>
                    <Box className={classes.blockChild}>
                      <Typography variant="subtitle1">
                        <b>Size:</b> {logSizeInKilobytes(block)}
                        <a
                          href={`https://explorer.invoicemate.net/?tab=transactions&transId=${getTnxId(
                            block
                          )}`}
                          // href={`https://whatsonchain.com/tx/${getTnxId(block)}`}
                          target="_blank"
                        >
                          <Tooltip title="Verify on Block Explorer" arrow>
                            <VerifiedUserIcon
                              className={classes.verifiedIcon}
                            />
                          </Tooltip>
                        </a>
                        {block.FinancingDetails &&
                          block.FinancingDetails.length > 0 && (
                            <Link
                              to={`/transaction/${
                                block?.FinancingDetails[
                                  block?.FinancingDetails.length - 1
                                ]
                              }/Finance/${block.InvoiceId}`}
                              // href={`https://whatsonchain.com/tx/${getTnxId(block)}`}
                              // target='_blank'
                            >
                              <Tooltip title="Finance Details" arrow>
                                <AccountBalanceIcon
                                  className={classes.financeIcon}
                                />
                              </Tooltip>
                            </Link>
                          )}
                      </Typography>
                    </Box>
                    <Divider />

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          <b>Data</b>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid
                          container
                          justify="flex-start"
                          alignItems="flex-start"
                          className={classes.grid}
                        >
                          <HistoryBlockItem data={block} />
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Paper>
                </StepContent>
              </Step>
            ))
          : loaderBlocksList.map((block: any, index: any) => (
              <Step expanded={true} active={true}>
                <StepLabel></StepLabel>
                <StepContent>
                  <Skeleton animation="wave" width="100%" height="150px" />
                </StepContent>
              </Step>
            ))}
      </Stepper>
    </div>
  );
};

export default HistoryBlocks;
