import red from "@material-ui/core/colors/red";
import { createTheme } from "@material-ui/core/styles";
import React from "react";

let primaryDefault = {
  main: "#5A2C66",
  light: "#7b5684",
  dark: "#3e1e47",
  contrastText: "#ffffff",
};

let secondaryDefault = {
  main: "#9E2654",
  light: "#b15176",
  dark: "#6e1a3a",
  contrastText: "#ffffff",
};

let primaryDark = {
  main: "#2f2c30",
  light: "#2f2c30",
  dark: "#2f2c30",
  // main: "#834194",
  // light: "#a37aad",
  // dark: "#582666",
};

let secondaryDark = {
  main: "#bb225d",
  light: "#cf668e",
  dark: "#751f40",
};

const theme = {
  palette: {
    primary: {
      main: primaryDefault.main,
      light: primaryDefault.light,
      dark: primaryDefault.dark,
      contrastText: primaryDefault.contrastText,
    },
    secondary: {
      main: secondaryDefault.main,
      light: secondaryDefault.light,
      dark: secondaryDefault.dark,
      contrastText: secondaryDefault.contrastText,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          scrollbarColor: "#6b6b6b #b1b1b1",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#fafafa",
            height: 3,
            width: 5,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 2,
            border: "1px solid #b1b1b1",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
          {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
          {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#b1b1b1",
          },
        },
      },
    },
    MuiStepIcon: {
      root: {
        "&$active": {
          color: secondaryDefault.main,
        },
      },
      active: {},
      completed: {},
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1.2rem",
      },
    },
  },
};

export const defaultTheme = createTheme({
  ...theme,
});

export const darkTheme = createTheme({
  ...theme,
  palette: {
    type: "dark",
    primary: {
      main: primaryDark.main,
      light: primaryDark.light,
      dark: primaryDark.dark,
    },
    secondary: {
      main: secondaryDark.main,
      light: secondaryDark.light,
      dark: secondaryDark.dark,
    },
  },
  overrides: {
    ...theme.overrides,
    MuiCssBaseline: {
      "@global": {
        body: {
          scrollbarColor: "#a1a1a1 #cecece",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#303030",
          },
          backgroundColor: "#121212"
        },
      },
    },
    MuiStepIcon: {
      root: {
        "&$active": {
          color: secondaryDark.main,
        },
      },
    },
    
    MuiPaper: {
      root: {
        backgroundColor: "#1d1d1d"
      }
    },
    
  },
});
