import { useQuery } from '@apollo/client';
import { Typography, Paper, makeStyles, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import CostKPI from './CostKPI';
import CostChart from './CostChart';
import { KPI_QUERY } from '../../graphql/query/homePage.query';
import moment from 'moment';

const useStyles = makeStyles((theme: any) => ({
    root: {
        paddingTop: theme.spacing(7)
    }
}))

const CostAnalytics = (props: any) => {
    const { kpiResp, getMonthlyStatsMutation } = props;
    const classes = useStyles();
    const [linesList, setLinesList] = useState({
        CombinedLineTemp: [] as Array<object>,
        InvoiceLine: [] as Array<object>,
        InvoicePaymentLine: [] as Array<object>,
        InvoiceFinanceLine: [] as Array<object>,
        timeStampList: [] as Array<any>
    })
    const [currentMonth, setCurrentMonth] = useState(parseInt(moment().format('MM')))
    const [costKpiDataState, setCostKpiDataState] = useState({
        totalCost: "",
        costToday: "",
        lastSevenDays: "",
        lastMonth: "",
        thisYear: ""
    });
    const [initialTimeStampRange, setInitialTimeStampRange] = useState({
        min: 0,
        max: 0
    })

    useEffect(() => {
        if (kpiResp.data) {
            console.log("==========>  CostAnalytics <==========")
            console.log("yearlyChart: ", kpiResp)

            let costKpiData = {
                totalCost: kpiResp.data.getMonthlyStats.totalCost,
                costToday: kpiResp.data.getMonthlyStats.dailyTxns.cost,
                lastSevenDays: kpiResp.data.getMonthlyStats.weeklyTxns.cost,
                lastMonth: kpiResp.data.getMonthlyStats.monthlyTxns.cost,
                thisYear: kpiResp.data.getMonthlyStats.yearlyTxns.cost
            }
            setCostKpiDataState(costKpiData)
            // console.log("costKpiData: ", costKpiData)

            let combinedLineTemp = [];
            let invoiceLineTemp = [];
            let invoicePaymentLineTemp = [];
            let invoiceFinanceLineTemp = [];

            // console.log("==========>  Monthly Loop <==========")
            // for (let mIndex = 1; mIndex < Object.keys(kpiResp.data.getMonthlyStats.yearlyChart).length; mIndex++) {
            const month = kpiResp.data.getMonthlyStats.yearlyChart[currentMonth]
            // console.log("month: ", month)

            // Monthly line
            // combinedLineTemp.push({
            //     x: new Date(`${new Date().getFullYear()}-${mIndex}`).getTime(),
            //     // y: Math.floor(Math.random() * 101)
            //     y: month.cost
            // })
            // emergencyIssueLineTemp.push({
            //     x: new Date(`${new Date().getFullYear()}-${mIndex}`).getTime(),
            //     // y: Math.floor(Math.random() * 101)
            //     y: month.models.EmergencyIssue.cost
            // })
            // gatePass.push({
            //     x: new Date(`${new Date().getFullYear()}-${mIndex}`).getTime(),
            //     // y: Math.floor(Math.random() * 101)
            //     y: month.models.GatePass.cost
            // })
            // inventory.push({
            //     x: new Date(`${new Date().getFullYear()}-${mIndex}`).getTime(),
            //     // y: Math.floor(Math.random() * 101)
            //     y: month.models.Inventory.cost
            // })
            // issueNote.push({
            //     x: new Date(`${new Date().getFullYear()}-${mIndex}`).getTime(),
            //     // y: Math.floor(Math.random() * 101)
            //     y: month.models.IssueNote.cost
            // })
            // issueNotesReturn.push({
            //     x: new Date(`${new Date().getFullYear()}-${mIndex}`).getTime(),
            //     // y: Math.floor(Math.random() * 101)
            //     y: month.models.IssueNotesReturn.cost
            // })
            // stock.push({
            //     x: new Date(`${new Date().getFullYear()}-${mIndex}`).getTime(),
            //     // y: Math.floor(Math.random() * 101)
            //     y: month.models.Stock.cost
            // })

            console.log("==========>  Daily Loop <==========")
            console.log("no. of days / month: ", Object.keys(month.dailyData).length)
            for (let dIndex = 1; dIndex <= Object.keys(month.dailyData).length; dIndex++) {
                const day = month.dailyData[dIndex];
                // console.log("day: ", day)
                console.log("day index: ", dIndex)

                // daily line
                // combinedLineTemp.push({
                //     x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex}`).getTime(),
                //     // y: Math.floor(Math.random() * 101)
                //     y: day.cost
                // })
                // emergencyIssueLineTemp.push({
                //     x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex}`).getTime(),
                //     // y: Math.floor(Math.random() * 101)
                //     y: day.models.EmergencyIssue.cost
                // })
                // gatePass.push({
                //     x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex}`).getTime(),
                //     // y: Math.floor(Math.random() * 101)
                //     y: day.models.GatePass.cost
                // })
                // inventory.push({
                //     x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex}`).getTime(),
                //     // y: Math.floor(Math.random() * 101)
                //     y: day.models.Inventory.cost
                // })
                // issueNote.push({
                //     x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex}`).getTime(),
                //     // y: Math.floor(Math.random() * 101)
                //     y: day.models.IssueNote.cost
                // })
                // issueNotesReturn.push({
                //     x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex}`).getTime(),
                //     // y: Math.floor(Math.random() * 101)
                //     y: day.models.IssueNotesReturn.cost
                // })
                // stock.push({
                //     x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex}`).getTime(),
                //     // y: Math.floor(Math.random() * 101)
                //     y: day.models.Stock.cost
                // })

                for (let hIndex = 0; hIndex < Object.keys(day.hourly).length; hIndex++) {
                    const hour = day.hourly[hIndex];
                    // console.log("hour index: ", hIndex)
                    // console.log("timestamp: ", `${new Date().getFullYear()}-${currentMonth}-${dIndex} ${hIndex + 1}:00`)

                    // hour line
                    combinedLineTemp.push({
                        x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex} ${hIndex + 1}:00`).getTime(),
                        // y: Math.floor(Math.random() * 101)
                        y: hour.cost
                    })
                    invoiceLineTemp.push({
                        x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex} ${hIndex + 1}:00`).getTime(),
                        // y: Math.floor(Math.random() * 101)
                        y: hour.models.Invoice.cost
                    })
                    invoicePaymentLineTemp.push({
                        x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex} ${hIndex + 1}:00`).getTime(),
                        // y: Math.floor(Math.random() * 101)
                        y: hour.models.InvoicePayment.cost
                    })
                    invoiceFinanceLineTemp.push({
                        x: new Date(`${new Date().getFullYear()}-${currentMonth}-${dIndex} ${hIndex + 1}:00`).getTime(),
                        // y: Math.floor(Math.random() * 101)
                        y: hour.models.Finance.cost
                    })
                }
            }
            // }

            setLinesList({
                ...linesList,
                CombinedLineTemp: combinedLineTemp,
                InvoiceLine: invoiceLineTemp,
                InvoicePaymentLine: invoicePaymentLineTemp,
                InvoiceFinanceLine: invoiceFinanceLineTemp,
            })
            setInitialTimeStampRange({
                ...initialTimeStampRange,
                min: combinedLineTemp[0].x,
                max: combinedLineTemp[combinedLineTemp.length - 1].x,
            })
        }
    }, [kpiResp.data])

    return (
        <>
            <Box className={classes.root}>
                <Typography variant="h6" style={{ padding: "1% 0 1% 0" }}>Transaction Cost Analytics</Typography>

                <CostKPI data={costKpiDataState} loading={kpiResp.loading} fullDetail={false} />
                <Typography variant="h6" style={{ padding: "3% 0 0% 0" }}>Transaction Cost Statistics</Typography>

                <Paper elevation={3} style={{ marginTop: "1%", padding: "2%" }}>
                    <CostChart
                        linesList={linesList}
                        initialTimeStampRange={initialTimeStampRange}
                        currentMonth={currentMonth}
                        setCurrentMonth={setCurrentMonth}
                        loading={kpiResp.loading}
                        getMonthlyStatsMutation={getMonthlyStatsMutation}
                    />
                </Paper>
            </Box>
        </>
    )
}

export default CostAnalytics
