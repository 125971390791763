export const sideBarItems = ["Inovice", "Payment"];
export const sideBarItemsWithTypes = [
  { type: "Invoice", title: "Inovice" },
  { type: "InvoicePayment", title: "Payment" },
  { type: "Finance", title: "Finance" },
];
export const columnNames = [
  {
    title: "Tx Id",
    field: "TxnID",
  },
  {
    title: "Timestamp",
    field: "TimeStamp",
  },
  {
    title: "Size (KB)",
    field: "Size",
  },
  // {
  //     title: "Type",
  //     field: "Type"
  // },
  // {
  //     title: "Hash",
  //     field: "TxnHash"
  // },
];
