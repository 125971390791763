import gql from "graphql-tag";

export const SearchMutation = gql`
  mutation search($key: String, $type: String, $subType: String) {
    search(key: $key, type: $type, subType: $subType) {
      ID
      size
      email
      mobileNumber
      type
      txnHash
      financeId
      invoiceAmount
      financeAmount
      remainingAmount
      financeRate
      requestedDuration
      approvedAmount
      disbursedAmount
      repaymentAmount
      action
      bankForFinancing
      requestedBy
      userId
      financeDueDate
      creditLimit
      email
      mobileNumber
      timeStamp
      processingFee
      financeCost
      financeRequest
      default
      approved
      paymentDisbursed
      repayment
      rejectedRemarks
      defaultRemarks
      approvedRemarks
      paymentDisbursedRemarks
      repaymentReemarks
      financingScore
      invoiceId
      mongoId
      creationDate
      vendorEmail
      vendorName
      clientEmail
      clientName
      currency
      fundReception
      lines
      netAmt
      paid
      rejected
      voided
      draft
      ack
      paymentID
      vendorId
      currencyCode
      paidAmount
      paymentType
      receiptUrl
      date
      paymentGateway
    }
  }
`;
