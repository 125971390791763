import { gql } from "@apollo/client";

export const NEW_INVOICE_SUBSCRIPTION = gql`
  subscription newInvoice {
    newInvoice {
      type
      invoiceId
      creationDate
      vendorEmail
      vendorName
      clientEmail
      clientName
      currency
      fundReception
      lines: JSON
      netAmt
      paid
      rejected
      voided
      draft
      txnHash
    }
  }
`;

export const NEW_PAYMENT_SUBSCRIPTION = gql`
  subscription newPayment {
    newPayment {
      type
      invoiceId
      paymentID
      vendorId
      currencyCode
      paidAmount
      paymentType
      receiptUrl
      date
      paymentGateway
      txnHash
    }
  }
`;