import React, { useEffect, useState } from "react";
// import Editor from "@monaco-editor/react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


import { Grid, makeStyles } from "@material-ui/core";
import crypto from 'crypto';
import { getRandom } from "../shared/helpers/getRandom";
import EmailTracking from "./EmailTracking";

type Props = {
  data: any;
  isVisible: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .monaco-editor": {
      paddingTop: theme.spacing(2),
    },
  },
  blur: {
    "-webkit-filter": "blur(3px)",
    "-moz-filter": "blur(3px)",
    "-o-filter": "blur(3px)",
    "-ms-filter": "blur(3px)",
    filter: "blur(3px)",
    width: "100%",
    height: "100%",
    backgroundColor: "#424242",
    pointerEvents: "none"
  }
}));

const CustomCodeBlock = (props: Props) => {
  const { data, isVisible } = props;
  const classes = useStyles();
  const [state, setState] = useState({});
  useEffect(() => {
    if (data) {
      setState(data);
    }
  }, [data]);



  const codeSnippet = JSON.stringify(state, null, 2);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        
      <SyntaxHighlighter language="json" style={docco}>
      {codeSnippet}
    </SyntaxHighlighter>
        
        
        
        
        
        {/* <Editor
        className={`${classes.root} ${!isVisible && classes.blur}`}
        height='800px'
        language='json'
        defaultValue={JSON.stringify(state, null, 2)}
        theme='vs-dark'
        options={{
          readOnly: true
        }}
      /> */}
      
      
      </Grid>


    </Grid>
  );
};

export default CustomCodeBlock;
