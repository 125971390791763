import {
  SET_REFECH_METHOD,
  SET_TRANSACTION,
  SET_TRANSACTION_DATA,
  SET_TRANSACTION_NUMBER,
} from "../types";

export const setTransaction = (transaction: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_TRANSACTION,
      payload: transaction,
    });
  };
};

export const setRefechMethodForKPIs = (refetch: () => {}) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_REFECH_METHOD,
      payload: refetch,
    });
  };
};

export const setChartNumber = (
  overAllDailyNumberArr: any,
  InvoiceArr: any,
  InvoicePaymentArr: any,
) => {
  const overAllDailyNum = overAllDailyNumberArr.map((x: any) => {
    return x.data;
  });
  const InvoiceNum = InvoiceArr.map((x: any) => {
    return x.data;
  });
  const InvoicePaymentNum = InvoicePaymentArr.map((x: any) => {
    return x.data;
  });  
 
  const Number = {
    Invoice: [
      InvoiceNum,
    ],
    InvoicePayment: [
      InvoicePaymentNum,
    ],
    All: [ overAllDailyNum],
  };

  ;
  return (dispatch: any) => {
    dispatch({
      type: SET_TRANSACTION_NUMBER,
      payload: Number,
    });
  };
};

export const setChartData = (
  overAllDailyDataArr: any,
  InvoiceDataArr: any,
  InvoicePaymentDataArr: any,
) => {
  const overAllDailyData = overAllDailyDataArr.map((x: any) => {
    return x.data;
  });
  const invoiceData = InvoiceDataArr.map((x: any) => {
    return x.data;
  });
  const invoicePaymentData = InvoicePaymentDataArr.map((x: any) => {
    return x.data;
  });
  
  const Data = {
    Invoice: [
      invoiceData,
    ],
    InvoicePayment: [
      invoicePaymentData,
    ],
    All: [overAllDailyData],
  };
  return (dispatch: any) => {
    dispatch({
      type: SET_TRANSACTION_DATA,
      payload: Data,
    });
  };
};
