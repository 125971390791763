
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { chartReducer, IChartState } from '../reducers/Chart.reducer';
import { configReducer, IConfigState } from '../reducers/configReducer';
import { ITransactionState, transactionReducer } from '../reducers/Transation.reducer';

export interface IAppState {
  configState: IConfigState,
  transactions:ITransactionState,
  chart: IChartState,
}

const rootReducer = combineReducers<IAppState>({
  configState: configReducer,
  transactions: transactionReducer,
  chart : chartReducer,
});

export default function configureStore(): Store<IAppState, any> {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
  return store;
}