import { gql } from "@apollo/client";

export const GET_EVERYTHING = gql`
  query getEverything {
    getEverything {
      ID
      size
      type
      txnHash
      invoiceId
      creationDate
      vendorEmail
      vendorName
      clientEmail
      clientName
      currency
      fundReception
      lines
      netAmt
      paid
      rejected
      voided
      draft
      paymentID
      vendorId
      currencyCode
      paidAmount
      paymentType
      receiptUrl
      date
      paymentGateway
      mongoId
    }
  }
`;

export const GET_EVERYTHING_WITH_PAGINATION = gql`
  query getEverything($offset: Int, $limit: Int) {
    getEverything(offset: $offset, limit: $limit) {
      ID
      size
      type
      txnHash
      invoiceId
      creationDate
      vendorEmail
      vendorName
      clientEmail
      clientName
      currency
      fundReception
      lines
      netAmt
      paid
      rejected
      voided
      draft
      paymentID
      vendorId
      currencyCode
      paidAmount
      paymentType
      receiptUrl
      date
      paymentGateway
      mongoId
    }
  }
`;

export const GET_TOTAL_NO_OF_TXNS = gql`
  query kpi {
    kpi {
      totalTxns
    }
  }
`;

export const GET_EVERYTHING_FILTERED = gql`
  query getEverything {
    getEverything {
      ID
      size
      type
      txnHash
      invoiceId
      creationDate
      vendorEmail
      vendorName
      clientEmail
      clientName
      currency
      fundReception
      lines
      netAmt
      paid
      rejected
      voided
      draft
      paymentID
      vendorId
      currencyCode
      paidAmount
      paymentType
      receiptUrl
      date
      paymentGateway
    }
  }
`;

export const KPI_QUERY = gql`
  query kpi {
    kpi {
      totalTxns
      totalData
      totalCost
      yearlyChart
      yearlyTxns {
        number
        data
        cost
      }
      monthlyTxns {
        number
        data
        cost
      }
      weeklyTxns {
        number
        data
        cost
      }
      dailyTxns {
        number
        data
        cost
      }
      models {
        Invoice {
          number
          data
          cost
        }
        InvoicePayment {
          number
          data
          cost
        }
      }
    }
  }
`;
export const KPIS = gql`
  query {
    kpi {
      totalTxns
      totalData
      totalCost
      yearlyChart
      yearlyTxns {
        number
        data
        cost
      }
      monthlyTxns {
        number
        data
        cost
      }
      weeklyTxns {
        number
        data
        cost
      }
      dailyTxns {
        number
        data
        cost
      }
      models {
        Invoice {
          number
          data
          cost
        }
        InvoicePayment {
          number
          data
          cost
        }
        Finance {
          number
          data
          cost
        }
      }
    }
  }
`;
