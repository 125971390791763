import React, { useEffect } from "react";
import {
  faDiceD6,
  faExchangeAlt,
  faServer,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  ListItemText,
  Paper,
  List,
  makeStyles,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { type } from "os";
import ValidationTile from "./ValidationTile";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme: any) => ({
  "@keyframes rotateFull": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  root: {
    "& .monaco-editor": {
      paddingTop: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    textAlign: "center",
  },
  validationIconFaExchange: {
    backgroundColor: "#bdbdbd",
  },
  validationIcon: {
    width: "30px",
    height: "30px",
    margin: "auto",
    borderRadius: "16px",
  },
  altExchage: {
    fontSize: "1.25rem",
    margin: "auto",
    height: "100%",
    color: theme.palette.primary.main,
    animation: "1.2s ease-out 0s 1 rotateFull",
  },
  vertical: {
    borderRight: "1px solid #cdc8c8",
    height: "30px",
    position: "relative",
    width: "50%",
    marginTop: "2px",
  },
  blur: {
    "-webkit-filter": "blur(3px)",
    "-moz-filter": "blur(3px)",
    "-o-filter": "blur(3px)",
    "-ms-filter": "blur(3px)",
    filter: "blur(3px)",
    width: "100%",
    height: "100%",
    backgroundColor: "#424242",
    pointerEvents: "none",
  },
  iconColor: {
    color: theme.palette.primary.main,
  },
  childPaper: {
    textAlign: "center",
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  },
}));

function ValidationChildFinance(props: any) {
  const { leftData, rightSideData, loading } = props;

  useEffect(() => {
    console.log("leftData: ", leftData);
    console.log("rightSideData: ", rightSideData);
    debugger;
  }, [rightSideData, leftData]);
  const classes = useStyles();


  function formatNumberWithSeparator(number:string) {
    debugger
    // Convert the number to a string
    const numberString = number ? number?.toString() : "";
  
    // Use regular expressions to add commas as thousand separators
    const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    return formattedNumber;
  }
  return (
    <Paper elevation={1}>
      <Grid container>
        {/* Headers */}
        <Grid container>
          <Grid item xs={12} sm={12} md={5}>
            <Paper className={classes.paper} elevation={0}>
              <ListItemText>
                <h4>
                  <FontAwesomeIcon
                    // className={`${classes.iconColor}`}
                    size="2x"
                    icon={faDiceD6}
                  />
                </h4>
                <b>ON-CHAIN</b>
              </ListItemText>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            style={{ margin: "auto", textAlign: "center" }}
          >
            <div
              className={`${classes.validationIcon} ${classes.validationIconFaExchange}`}
            >
              <FontAwesomeIcon
                className={`${classes.altExchage}`}
                size="1x"
                icon={faExchangeAlt}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Paper className={classes.paper} elevation={0}>
              <ListItemText>
                <h4>
                  <FontAwesomeIcon
                    // className={`${classes.iconColor}`}
                    size="2x"
                    icon={faServer}
                  />
                </h4>
                <b>OFF-CHAIN</b>
              </ListItemText>
            </Paper>
          </Grid>
        </Grid>
        {/* Loading */}
        {loading ? (
          <>
            {[0, 1, 2, 3, 4, 5].map((item: any) => (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  style={{ paddingTop: "16px", paddingBottom: "16px" }}
                >
                  <Skeleton variant="rect" width={"100%"} height={100} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  style={{ margin: "auto", textAlign: "center" }}
                >
                  <CheckCircleIcon style={{ color: "green" }} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  style={{ paddingTop: "16px", paddingBottom: "16px" }}
                >
                  <Skeleton variant="rect" width={"100%"} height={100} />
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {/* Invoice Id */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title={"Invoice ID"}
                    value={leftData?.InvoiceId}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceId == leftData?.InvoiceId ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title={"Invoice ID"}
                    value={rightSideData?.invoiceId}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/*Finance Due Date */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Finance Due Date"
                    value={
                      leftData?.FinanceDueDate
                        ? moment(leftData?.FinanceDueDate).format("DD/MM/YYYY")
                        : "-"
                    }
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {moment(
                  rightSideData?.invoiceFinanceObj?.repaymentDate || ""
                ).format("DD/MM/YYYY") ==
                moment(leftData?.FinanceDueDate).format("DD/MM/YYYY") ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Finance Due Date"
                    value={
                      rightSideData?.invoiceFinanceObj?.repaymentDate
                        ? moment(
                            rightSideData?.invoiceFinanceObj?.repaymentDate ||
                              ""
                          ).format("DD/MM/YYYY")
                        : "-"
                    }
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Request By */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <Paper className={classes.childPaper} elevation={6}>
                  <ListItemText style={{ textAlign: "center" }}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Request By
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.RequestBy}
                    </Typography>
                  </ListItemText>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.requestBy ==
                leftData?.RequestBy ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Request By
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.invoiceFinanceObj?.requestBy}
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Email */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <Paper className={classes.childPaper} elevation={6}>
                  <ListItemText style={{ textAlign: "center" }}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Email
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.Email}
                    </Typography>
                  </ListItemText>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.email == leftData?.Email ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Email
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.invoiceFinanceObj?.email}
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Mobile */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <Paper className={classes.childPaper} elevation={6}>
                  <ListItemText style={{ textAlign: "center" }}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Mobile Number
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.MobileNumber}
                    </Typography>
                  </ListItemText>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.mobileNumber ==
                leftData?.MobileNumber ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Mobile Number
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      {rightSideData?.invoiceFinanceObj?.mobileNumber}
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Bank Details */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <Paper className={classes.childPaper} elevation={6}>
                  <ListItemText style={{ textAlign: "center" }}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Bank Name
                    </Typography>

                    <Typography style={{ wordWrap: "break-word" }}>
                      {leftData?.BankForFinancing?.bankName}
                    </Typography>
                  </ListItemText>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.bankForFinancing?.bankName ==
                leftData?.BankForFinancing?.bankName ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Bank Name
                    </Typography>

                    <Typography style={{ wordWrap: "break-word" }}>
                      {
                        rightSideData?.invoiceFinanceObj?.bankForFinancing
                          ?.bankName
                      }
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Invoice Amount */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Invoice Amount
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                      <NumberFormat
                        defaultValue={"0.00"}
                        value={leftData?.InvoiceAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        // prefix={leftData?.Currency?.code + " "}    
                      />
                      
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.netAmtFC?.toString() ==
                leftData?.InvoiceAmount?.toString() ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Invoice Amount
                    </Typography>
                    <Typography style={{ wordWrap: "break-word" }}>
                    <NumberFormat
                        defaultValue={"0.00"}
                        value={rightSideData?.netAmtFC}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        // prefix={leftData?.Currency?.code + " "}    
                      />
                     
                    </Typography>
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Finance */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Finance Amount"
                    value={<NumberFormat
                      defaultValue={"0.00"}
                      value={leftData?.FinanceAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // prefix={leftData?.Currency?.code + " "}    
                    />}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.requestAmount?.toString() ==
                leftData?.FinanceAmount?.toString() ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Finance Amount"
                    value={<NumberFormat
                      defaultValue={"0.00"}
                      value={rightSideData?.invoiceFinanceObj?.requestAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // prefix={leftData?.Currency?.code + " "}    
                    />}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Approved Amount */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Approved Amount"
                    value={leftData?.ApprovedAmount === "Not Assigned" ? "Not Assigned" : formatNumberWithSeparator(leftData?.ApprovedAmount)}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.approvedAmount ===
                (leftData?.ApprovedAmount === "Not Assigned"
                  ? undefined
                  : leftData?.ApprovedAmount) ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Approved Amount"
                    value={
                      rightSideData?.invoiceFinanceObj?.approvedAmount
                        ? formatNumberWithSeparator(rightSideData?.invoiceFinanceObj?.approvedAmount)
                        : "Not Assigned"
                    }
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Disbursed */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Disbursed Amount"
                    value={leftData?.DisbursedAmount === "Not Assigned" ? "Not Assigned" : formatNumberWithSeparator(leftData?.DisbursedAmount)}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.disbursedAmount ===
                (leftData?.DisbursedAmount === "Not Assigned"
                  ? undefined
                  : leftData?.DisbursedAmount) ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Disbursed Amount"
                    value={
                      rightSideData?.invoiceFinanceObj?.disbursedAmount
                        ? formatNumberWithSeparator(rightSideData?.invoiceFinanceObj?.disbursedAmount)
                        : "Not Assigned"
                    }
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Repayment */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Repayment Amount"
                    value={leftData?.RepaymentAmount === "Not Assigned" ? "Not Assigned" : formatNumberWithSeparator(leftData?.RepaymentAmount)}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.repaymentAmount ==
                (leftData?.RepaymentAmount === "Not Assigned"
                  ? undefined
                  : leftData?.RepaymentAmount) ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Repayment Amount"
                    value={
                      rightSideData?.invoiceFinanceObj?.repaymentAmount
                        ? formatNumberWithSeparator(rightSideData?.invoiceFinanceObj?.repaymentAmount)
                        : "Not Assigned"
                    }
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* FinanceCost */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Finance Cost"
                    value={leftData?.FinanceCost}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.financeCost ==
                (leftData?.FinanceCost == "Not Assigned"
                  ? undefined
                  : leftData?.FinanceCost) ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Finance Cost"
                    value={
                      rightSideData?.invoiceFinanceObj?.financeCost
                        ? rightSideData?.invoiceFinanceObj?.financeCost
                        : "Not Assigned"
                    }
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Credit Limit */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Credit Limit
                    </Typography>
                    <NumberFormat
                      defaultValue={"0.00"}
                      value={leftData?.CreditLimit}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // prefix={leftData?.Currency?.code + " "}
                    />
                  </Paper>
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.financingScore?.maxLimit ==
                leftData?.CreditLimit ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Credit Limit
                    </Typography>
                    <NumberFormat
                      defaultValue={"0.00"}
                      value={
                        rightSideData?.invoiceFinanceObj?.financingScore
                          ?.maxLimit
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      // prefix={rightSideData?.currency?.code + " "}
                    />
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid>

            {/* Currency */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Currency"
                    value={leftData?.Currency}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.currency?.code == leftData?.Currency ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Currency"
                    value={rightSideData?.currency?.code}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>
            {/* Remaining */}

            {/* <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Remaining Limit
                    </Typography>
                    <NumberFormat
                      defaultValue={"0.00"}
                      value={leftData?.RemainingAmount.length ? leftData?.RemainingAmount[0]?.Amount : ""}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    // prefix={leftData?.Currency?.code + " "}
                    />
                  </Paper>
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {rightSideData?.invoiceFinanceObj?.financingScore?.remainingAmount.toString() == (leftData?.RemainingAmount.length ? leftData?.RemainingAmount[0]?.Amount : "") ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Remaining Limit
                    </Typography>
                    <NumberFormat
                      defaultValue={"0.00"}
                      value={rightSideData?.invoiceFinanceObj?.financingScore?.remainingAmount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    // prefix={rightSideData?.currency?.code + " "}
                    />
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid> */}

            {/* Creation Date */}
            <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Creation Date"
                    value={moment(leftData?.CreationDate).format("DD/MM/YYYY")}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {moment(rightSideData?.financeRequestDate).format("DD/MM/YYYY") ==
                moment(leftData?.CreationDate).format("DD/MM/YYYY") ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText>
                  <ValidationTile
                    title="Creation Date"
                    value={moment(rightSideData?.financeRequestDate).format(
                      "DD/MM/YYYY"
                    )}
                    showData={true}
                  />
                </ListItemText>
              </Grid>
            </Grid>

            {/* Lines Details */}
            {/* <Grid container>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Lines Details
                    </Typography>
                    <BlockLinesAccordion state={leftData?.Lines} />
                  </Paper>
                </ListItemText>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                style={{ margin: "auto", textAlign: "center" }}
              >
                {JSON.stringify(rightSideData?.lines) ==
                  JSON.stringify(leftData?.Lines) ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CancelIcon style={{ color: "red" }} />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <ListItemText style={{ textAlign: "center" }}>
                  <Paper className={classes.childPaper} elevation={6}>
                    <Typography
                      style={{ wordWrap: "break-word", fontWeight: 600 }}
                    >
                      Lines Details
                    </Typography>
                    <BlockLinesAccordion state={rightSideData?.lines} />
                  </Paper>
                </ListItemText>
              </Grid>
            </Grid> */}
          </>
        )}
      </Grid>
    </Paper>
  );
}

export default ValidationChildFinance;

function BlockLinesAccordion({ state }: any) {
  const classes = useStyles();

  return (
    <>
      {state &&
        state.map((line: any, index: any) => (
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{line.item}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item xs={12} md={6} lg={6}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <b>Unit Price</b>
                      </ListItemText>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <Typography noWrap>
                          <NumberFormat
                            defaultValue={"0.00"}
                            value={line?.unitPrice}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Typography>
                      </ListItemText>
                    </List>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <b>Quantity</b>
                      </ListItemText>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <Typography noWrap>{line.quantity}</Typography>
                      </ListItemText>
                    </List>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <b>Discount</b>
                      </ListItemText>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <Typography noWrap>
                          <NumberFormat
                            defaultValue={"0.00"}
                            value={line?.discount}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Typography>
                      </ListItemText>
                    </List>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <b>Amount</b>
                      </ListItemText>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <Typography noWrap>
                          <NumberFormat
                            defaultValue={"0.00"}
                            value={line?.amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Typography>
                      </ListItemText>
                    </List>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <b>Additional Details</b>
                      </ListItemText>
                    </List>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <List>
                      <ListItemText style={{ textAlign: "left" }}>
                        <Typography>{line.additionalDetails}</Typography>
                      </ListItemText>
                    </List>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
    </>
  );
}
