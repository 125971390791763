import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, Paper, Tab, Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import KPI from "../features/Card/card";
import { GET_MONTHLY_STAS } from "../graphql/mutation/home.mutation";
import { KPI_QUERY } from "../graphql/query/homePage.query";
import { MONTHLY_TRANSACTION } from "../graphql/query/KPI.query";
import ApexChart from "./Charts/AnalyticalChart";
import BubbleChart from "./Charts/BubbleChart";
import TransactionsChart from "./Charts/TransactionChart";
import TransactionsChartMonthWise from "./Charts/TransactionChartByDropdown";
import CostAnalytics from "./CostComponent/CostAnalytics";
import KPIsTable from "./KPIsTable/KPIsTable";
import pageTemplate from "./PageHoc";
import Tabs from "@material-ui/core/Tabs";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Analytics = () => {
  const [getMonthlyStatsMutation, getMonthlyStatsMutationResp] = useMutation(GET_MONTHLY_STAS, {
    onCompleted: (resp) => {
      // console.log(resp)
    },
    onError: (resp: any) => {
      Swal.fire({
        icon: "error",
        title: resp,
        showConfirmButton: false,
        timer: 1500,
      });
    },
  });
  useEffect(() => {
    getMonthlyStatsMutation({
      variables: {
        month: parseInt(moment().format('MM'))
      }
    })
  }, [])

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ padding: "1% 5%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          <Tab label='Transaction' {...a11yProps(0)} />
          <Tab label='Cost' {...a11yProps(1)} />
        </Tabs>
      </Grid>
      <Grid item xs={12} sx={{ padding: "1% 5%" }}>
        <TabPanel value={value} index={0}>
          <Typography variant="h6" style={{ padding: "1% 0 1% 0" }}>Transaction Analytics</Typography>

          {/* <Paper elevation={3} style={{ padding: "1%" }}> */}
          <KPI />
          {/* </Paper> */}
          <Typography variant="h6" style={{ padding: "3% 0 0% 0" }}>Transaction Statistics</Typography>

          <Paper elevation={3} style={{ marginTop: "1%", padding: "2%" }}>
            {/* <ApexChart /> */}
            {/* <TransactionsChart /> */}
            <TransactionsChartMonthWise />
          </Paper>
          {/* <Paper elevation={3} style={{ marginTop: "1%", padding: "2%" }}>
              <BubbleChart bubbleKpi={monthlytransactions} />
            </Paper> */}

          {/* <KPIsTable /> */}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <CostAnalytics kpiResp={getMonthlyStatsMutationResp} getMonthlyStatsMutation={getMonthlyStatsMutation} />
        </TabPanel>
      </Grid>
    </Grid>
  )
}
export default pageTemplate(Analytics);