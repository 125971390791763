import { Box, Button, Grid, Paper, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import pageTemplate from "../components/PageHoc";
import TransactionDetailTabs from "../components/TransactionDetailTabs";
import HistoryBlocks from "../components/HistoryBlocks";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import {
  GET_INVOICE,
  GET_PAYMENT,
  GET_INVOICE_HISTORY,
  GET_PAYMENT_HISTORY,
} from "../graphql/mutation/transactionDetailPage.mutation";
import Swal from "sweetalert2";
import { transactionTypeEnum } from "../shared/enum";
import {
  NEW_INVOICE_SUBSCRIPTION,
  NEW_PAYMENT_SUBSCRIPTION,
} from "../graphql/subscriptions/transactionDetailPage.subscription";
import Notification from "../components/Notification";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useTheme, useMediaQuery } from "@material-ui/core";
import PaymentHistoryBlocks from "../components/PaymentHistoryBlocks";
import axios from "axios";
import FinanceHistoryBlocks from "../components/FinanceHistoryBlocks";
interface ParamTypes {
  id: string;
  type: string;
  inv: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    "& .header": {
      // position: "relative",
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  copyIcons: {
    margin: theme.spacing(1),
    left: theme.spacing(0.5),
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  transactionHash: {
    fontWeight: 400,
  },
  leftSide: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  rightSide: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  visibilityIcon: {
    cursor: "pointer",
  },
  header: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

const TransactionDetail = () => {
  let { id, type, inv } = useParams<ParamTypes>();
  const [txnID, setTxnId] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isVisible, setIsVisible] = useState(false);
  const [invoiceData, setInvoiceData] = useState({ InvoiceId: "12" });
  const [invoiceHistoryData, setInvoiceHistoryData] = useState({});
  const [invoiceFinanceHistoryData, setInvoiceFinanceHistoryData] = useState(
    {}
  );
  //
  const [paymentData, setPaymentData] = useState({ PaymentId: "12" });
  const [paymentHistoryData, setPaymentHistoryData] = useState({});
  //
  const [financeData, setFinanceData] = useState({ InvoiceId: "12" });
  const [finanaceHistoryData, setFinanaceHistoryData] = useState({});

  const [getInvoiceMutation, getInvoiceMutationResp] = useMutation(
    GET_INVOICE,
    {
      onCompleted: (resp) => {
        console.log(resp);

        // console.log(resp)
      },
      onError: (resp: any) => {
        Swal.fire({
          icon: "error",
          title: resp,
          showConfirmButton: false,
          timer: 1500,
        });
      },
    }
  );
  const [getPaymentMutation, getPaymentMutationResp] = useMutation(
    GET_PAYMENT,
    {
      onCompleted: (resp) => {
        const obj = {
          action: "Creation",
          clientEmail: "",
          clientName: null,
          creationDate: "Mon, 23 Jan 2023 12:00:42 GMT",
          currency: {
            type: "Fiat",
            code: "AFN",
            symbol: "AFN",
            _id: "61f2712a13e62f035b71d732",
          },
          draft: "false",
          fundReception: {
            type: "Cash",
            accountNumber: "",
            accountName: "",
            bankName: "",
            iBAN: "",
          },
          invoiceId: "INV-9",
          lines: [
            {
              additionalDetails: "",
              amount: "1000",
              discount: "0",
              isDiscountPercent: true,
              isTaxPercentage: true,
              item: "bat",
              quantity: "1",
              quantityUnit: "",
              tax: "0",
              unitPrice: "1,000",
              _id: "63ce76eac22b723435830c47",
              mongoId: "63ce76eac22b723435830c45",
              netAmt: "1000",
              paid: "false",
              rejected: "false",
              timeStamp: "1674475244957",
              txnHash:
                "6543e5ea84be5dfe7eeed1558cb4731b00cc504ed2b45135a2f1b291e57457a8",
              type: "Invoice",
              vendorEmail: "mebosa2573@ukbob.com",
              vendorName: "essa rooman",
              voided: "false",
            },
          ],
          mongoId: "63ce76eac22b723435830c45",
          netAmt: "1000",
          paid: "false",
          rejected: "false",
          timeStamp: "1674475244957",
          txnHash:
            "6543e5ea84be5dfe7eeed1558cb4731b00cc504ed2b45135a2f1b291e57457a8",
          type: "Invoice",
          vendorEmail: "mebosa2573@ukbob.com",
          vendorName: "essa rooman",
          voided: "false",
        };

        // console.log(resp)
      },
      onError: (resp: any) => {
        Swal.fire({
          icon: "error",
          title: resp,
          showConfirmButton: false,
          timer: 1500,
        });
      },
    }
  );

  const [getInvoiceHistoryMutation, getInvoiceHistoryMutationResp] =
    useMutation(GET_INVOICE_HISTORY, {
      onCompleted: (resp) => {
        console.log(resp);
      },
      onError: (resp: any) => {
        Swal.fire({
          icon: "error",
          title: resp,
          showConfirmButton: false,
          timer: 1500,
        });
      },
    });

  useEffect(() => {
    console.log(getInvoiceHistoryMutationResp.data);
  }, [getInvoiceHistoryMutationResp.data]);

  const [getPaymentHistoryMutation, getPaymentHistoryMutationResp] =
    useMutation(GET_PAYMENT_HISTORY, {
      onCompleted: (resp) => {
        // console.log(resp)
      },
      onError: (resp: any) => {
        Swal.fire({
          icon: "error",
          title: resp,
          showConfirmButton: false,
          timer: 1500,
        });
      },
    });

  const getInvoice = async (invoice: string) => {
    try {
      const number = parseInt(invoice.replace(/\D/g, ""));
      const { data } = await axios.get<{ InvoiceId: "" }>(
        `https://hlf.invoicemate.net/invoice/get/${id}`
      );
      setInvoiceData(data);
      console.log(data);
    } catch (error) {}
  };

  const getInvoiceHistory = async (invoice: string) => {
    try {
      const number = parseInt(invoice.replace(/\D/g, ""));

      const { data } = await axios.get<[]>(
        `https://hlf.invoicemate.net/invoice/history/${id}`
      );
      const uniqueArray: any = Array.from(
        new Set(data.map((item: any) => item.Action))
      ).map((status) => {
        return data.find((item: any) => item.Action === status);
      });
      // console.log("unique", uniqueArray);

      setInvoiceHistoryData(uniqueArray);
    } catch (error) {}

    //
  };

  const getPayment = async (invoice: string) => {
    try {
      const number = parseInt(invoice.replace(/\D/g, ""));
      const { data } = await axios.get<{ PaymentId: "" }>(
        `https://hlf.invoicemate.net/payment/get/${id}`
      );
      setPaymentData(data);
      console.log(data);
    } catch (error) {}
  };

  const getInvoiceFinance = async (invoice: string) => {
    try {
      const number = parseInt(invoice.replace(/\D/g, ""));
      const { data } = await axios.get<{ InvoiceId: "" }>(
        `https://hlf.invoicemate.net/finance/get/${id}`
      );
      setFinanceData(data);
      console.log(data);
    } catch (error) {}
  };
  const getInvoiceFinanceHistory = async (invoice: string) => {
    try {
      const number = parseInt(invoice.replace(/\D/g, ""));

      const { data } = await axios.get<{}>(
        `https://hlf.invoicemate.net/finance/history/${id}`
      );
      setFinanaceHistoryData(data);
    } catch (error) {}
  };

  const getPaymentHistory = async (invoice: string) => {
    try {
      const number = parseInt(invoice.replace(/\D/g, ""));

      const { data } = await axios.get<{}>(
        `https://hlf.invoicemate.net/payment/history/${id}`
      );
      setPaymentHistoryData(data);
    } catch (error) {}

    //
  };
  // ** Call Again when Param id and type is chnages
  useEffect(() => {
    debugger;
    switch (type) {
      case transactionTypeEnum.Invoice:
        getInvoiceHistory(id);
        getInvoice(id);
        break;
      case transactionTypeEnum.Payment:
        getPaymentHistory(id);
        getPayment(id);
        break;
      case transactionTypeEnum.InvoiceFinance:
        getInvoiceFinanceHistory(id);
        getInvoiceFinance(id);

        break;
    }
  }, [id, type]);

  useEffect(() => {
    switch (type) {
      case transactionTypeEnum.Invoice:
        getInvoiceHistory(id);
        getInvoice(id);
        break;
      case transactionTypeEnum.Payment:
        getPaymentHistory(id);
        getPayment(id);

        break;
    }
  }, []);
  const classes = useStyles();
  const newInvoiceSubscription = useSubscription(NEW_INVOICE_SUBSCRIPTION, {
    onSubscriptionData: () => {
      if (newInvoiceSubscription.data?.newIssueNote.IssueNoteID === id) {
        getInvoiceHistoryMutation({
          variables: {
            id: id,
          },
        });
      }
      setNotify({
        isOpen: true,
        message: "New Issue Note Added",
        type: "success",
      });
    },
  });
  const newPaymentSubscription = useSubscription(NEW_PAYMENT_SUBSCRIPTION, {
    onSubscriptionData: () => {
      if (newInvoiceSubscription.data?.newIssueNote.IssueNoteID === id) {
        getPaymentHistoryMutation({
          variables: {
            id: id,
          },
        });
      }
      setNotify({
        isOpen: true,
        message: "New Issue Note Added",
        type: "success",
      });
    },
  });

  const [idCopyState, setIdCopyState] = useState({
    text: "Copy Id",
    copied: false,
  });
  const [linkCopyState, setLinkCopyState] = useState({
    text: "Copy link",
    copied: false,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const handleIdCopy = () => {
    setIdCopyState({ copied: true, text: "Copied" });
    setLinkCopyState({ copied: false, text: "Copy link" });
  };
  const handleLinkCopy = () => {
    setIdCopyState({ copied: false, text: "Copy Id" });
    setLinkCopyState({ copied: true, text: "Copied" });
  };

  const getTypeTranslated = (type: string, field: string) => {
    switch (type) {
      case transactionTypeEnum.Invoice:
        return getInvoiceMutationResp.data?.getIssueNoteState[field];
      case transactionTypeEnum.Payment:
        return getPaymentMutationResp.data?.getIssueNoteState[field];
      default:
        return "";
    }
  };

  const handleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      <Grid container className={classes.root}>
        <Grid className="header" direction="row" xs={12} md={8}>
          <Box className={classes.header}>
            <h1>
              {type === transactionTypeEnum.Invoice
                ? "Invoice"
                : type === transactionTypeEnum.InvoiceFinance
                ? "Finance"
                : "Invoice Payment"}{" "}
              Transaction
              {/* <Tooltip title={idCopyState.text} arrow>
                                <span className={classes.copyIcons}>
                                    <CopyToClipboard text={
                                        getTypeTranslated(type, "TxnID")
                                    }
                                        onCopy={handleIdCopy}>
                                        <FileCopyIcon />
                                    </CopyToClipboard>
                                </span>
                            </Tooltip> */}
              <Tooltip title={linkCopyState.text} arrow>
                <span className={classes.copyIcons}>
                  <CopyToClipboard
                    text={window.location.href}
                    onCopy={handleLinkCopy}
                  >
                    <LinkIcon />
                  </CopyToClipboard>
                </span>
              </Tooltip>
            </h1>

            <Grid container alignItems="center">
              <Grid item>
                {type === transactionTypeEnum.Invoice &&
                  !getInvoiceMutationResp.loading && (
                    <h2 className={classes.transactionHash}>
                      Invoice Id: {invoiceData?.InvoiceId}
                    </h2>
                  )}
                {type === transactionTypeEnum.Payment &&
                  !getPaymentMutationResp.loading && (
                    <h2 className={classes.transactionHash}>
                      Payment Id: {paymentData?.PaymentId}
                    </h2>
                  )}
                {type === transactionTypeEnum.InvoiceFinance &&
                  !getPaymentMutationResp.loading && (
                    <h2 className={classes.transactionHash}>
                      Finance Id: {financeData?.InvoiceId}
                    </h2>
                  )}
              </Grid>
              <Grid item sm></Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid direction="row" item xs={12} md={8} className={classes.leftSide}>
          {type === transactionTypeEnum.Invoice && (
            <TransactionDetailTabs
              loading={getInvoiceMutationResp.loading}
              data={invoiceData}
              type={type}
              isVisible={isVisible}
              handleVisibility={handleVisibility}
              historyLoading={getInvoiceHistoryMutationResp.loading}
              historyData={invoiceHistoryData}
              historyType={type}
            />
          )}
          {type === transactionTypeEnum.Payment && (
            <TransactionDetailTabs
              loading={getPaymentMutationResp.loading}
              data={paymentData}
              type={type}
              isVisible={isVisible}
              handleVisibility={handleVisibility}
              historyLoading={getPaymentHistoryMutationResp.loading}
              historyData={paymentHistoryData}
              historyType={type}
            />
          )}
          {type === transactionTypeEnum.InvoiceFinance && (
            <TransactionDetailTabs
              loading={getPaymentMutationResp.loading}
              data={financeData}
              type={type}
              isVisible={isVisible}
              handleVisibility={handleVisibility}
              historyLoading={getPaymentHistoryMutationResp.loading}
              historyData={finanaceHistoryData}
              historyType={type}
            />
          )}
        </Grid>

        {!isMobile && (
          <Grid
            direction="column"
            item
            xs={12}
            md={4}
            className={classes.rightSide}
            style={{ paddingTop: "16px" }}
          >
            {type === transactionTypeEnum.Invoice && (
              <HistoryBlocks
                loading={getInvoiceHistoryMutationResp.loading}
                data={invoiceHistoryData}
                type={type}
              />
            )}
            {type === transactionTypeEnum.InvoiceFinance && (
              <FinanceHistoryBlocks
                loading={getInvoiceHistoryMutationResp.loading}
                data={finanaceHistoryData}
                type={type}
              />
            )}
            {type === transactionTypeEnum.Payment && (
              <PaymentHistoryBlocks
                loading={getPaymentHistoryMutationResp.loading}
                data={paymentHistoryData}
                type={type}
              />
            )}
          </Grid>
        )}
      </Grid>

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default pageTemplate(TransactionDetail);
