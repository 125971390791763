import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { changeTheme } from "../redux/actions/configActions";
import DayButton from "../Images/day-button.png";
import DarkButton from "../Images/night-button.png";
import { makeStyles, Paper } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import { LOGIN } from "../graphql/mutation/login.mutation";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "../Images/MateExplorer.png";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {/* {'Copyright © '}
            Your Website {' '}
            {new Date().getFullYear()}
            {'.'} */}
      Mate Explorer
    </Typography>
  );
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "100vh",
    position: "absolute",
    width: "100vw",
    color: "#d5d5d5",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& .MuiContainer-root": {
      marginTop: -theme.spacing(10),
    },
  },
  darkRoot: {
    height: "100vh",
    position: "absolute",
    width: "100vw",
    color: "#d5d5d5",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#121212",
    alignItems: "center",
    "& input:hover": {
      height: "inherit",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#d5d5d5"
      borderColor: "#d5d5d5",
    },
    "& .MuiFormLabel-root": {
      color: "#d5d5d5",
    },
    "& .MuiInputBase-input": {
      color: "#d5d5d5",
    },
    "& .MuiContainer-root": {
      marginTop: -theme.spacing(10),
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#444444",
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "1px solid #fff",
    },
  },
  logo: {
    marginTop: "10px",
    width: theme.spacing(32),
    height: theme.spacing(6),
  },
}));

const Login = (props: any) => {
  const { theme, changeTheme } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [loginMutation, loginMutationResp] = useMutation(LOGIN, {
    onCompleted: (resp) => {
      window.location.href = "/";
      localStorage.setItem("state", "logged_in");
    },
    onError: (resp: any) => {
      // setError(resp)
      Swal.fire({
        icon: "error",
        title: resp,
        showConfirmButton: false,
        timer: 1500,
      });
    },
  });
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (state.username !== "" && state.password !== "") {
      loginMutation({
        variables: {
          uname: state.username,
          password: state.password,
        },
      });
    }
  };

  const handleTheme = () => {
    if (theme === "dark") {
      changeTheme("light");
    } else {
      changeTheme("dark");
    }
  };

  const handleChange = (e: any) =>
    setState({ ...state, [e.target.name]: e.target.value });

  return (
    <Paper className={theme == "dark" ? classes.root : classes.darkRoot}>
      <Container component="main" maxWidth="xs">
        {/* <Box sx={{
                    position: "absolute",
                    top: 0,
                    right: 0
                }}>
                    <IconButton color="primary" size="small" edge="end" onClick={() => handleTheme()} >
                        {theme === "light" ? <img src={DarkButton} style={{ width: "30%", height: "45%" }} /> : <img src={DayButton} style={{ marginTop: "0px", width: "30%", height: "45%" }} />}
                    </IconButton>
                </Box> */}
        <CssBaseline />
        <Paper style={{ padding: "16px" }} elevation={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={logo} className={classes.logo} />
            <br />

            <Box component="form" onSubmit={handleSubmit} noValidate>
              {/* {error && <Alert severity="error">{error}</Alert>} */}

              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                type="text"
                autoComplete="username"
                value={state.username}
                onChange={handleChange}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={state.password}
                onChange={handleChange}
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loginMutationResp.loading}
                style={{
                  backgroundColor: "#5A2C66",
                }}
              >
                {loginMutationResp.loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress
                      style={{ height: "20px", width: "20px" }}
                    />
                  </Box>
                ) : (
                  "Sign In"
                )}
              </Button>
            </Box>
          </Box>
        </Paper>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.configState.theme,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  changeTheme: (themeType: string) => dispatch(changeTheme(themeType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
