import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
    root: {
        textAlign: "center",
        padding: theme.spacing(1),
        margin: theme.spacing(2)
    },
    blur: {
        "-webkit-filter": "blur(3px)",
        "-moz-filter": "blur(3px)",
        "-o-filter": "blur(3px)",
        "-ms-filter": "blur(3px)",
        filter: "blur(3px)",
        width: "100%",
        height: "100%",
        backgroundColor: "#424242",
        pointerEvents: "none"
    }
}));

const ValidationTile = (props: any) => {
    const { title, value, showData } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.root} elevation={3}>
            <Typography style={{ wordWrap: "break-word" }}>{title}</Typography>
            {value ? <Typography style={{ wordWrap: "break-word" }}>{value}</Typography> : <Typography style={{ wordWrap: "break-word" }}><p></p></Typography>}
        </Paper>
    )
}

export default ValidationTile
