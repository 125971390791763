import gql from "graphql-tag";

export const GET_NO_OF_TRANSACTION_BY_TYPE = gql`
  query getNoOfTransactionByType {
    kpi {
      models {
        Invoice {
          number
        }
        InvoicePayment {
          number
        }
        Finance {
          number
        }
      }
    }
  }
`;
